import { LicenseInfo } from '@mui/x-license-pro';

/**
 * NB: API must be updated when it expires (hash bakes in datetime).
 * To update, check if the MUI key is updated in the "Engineering Only" vault (web only)
 * If not, reach out to IT and request for the new key to be added to the vault.
 */
export function setMuiXProLicenseKey() {
  LicenseInfo.setLicenseKey(
    '81484333f3349a0d97a1c71317609f69Tz0xMDM3MjQsRT0xNzY1MDQyNTQ2MDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1RMy0yMDI0LEtWPTI='
  );
}
