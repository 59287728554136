/* eslint-disable */
import { OperationContext } from 'urql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Any: { input: any; output: any };
  JSONObject: { input: any; output: any };
  Time: { input: string; output: string };
};

export type AddUserToTeam = {
  teamID: Scalars['ID']['input'];
  userID: Scalars['ID']['input'];
};

export enum AggregationType {
  COUNT = 'COUNT',
  COUNT_SAMPLES = 'COUNT_SAMPLES',
  HISTOGRAM = 'HISTOGRAM',
  LAST = 'LAST',
  MAX = 'MAX',
  MEAN = 'MEAN',
  MEDIAN = 'MEDIAN',
  MIN = 'MIN',
  P10 = 'P10',
  P20 = 'P20',
  P25 = 'P25',
  P30 = 'P30',
  P40 = 'P40',
  P50 = 'P50',
  P60 = 'P60',
  P70 = 'P70',
  P75 = 'P75',
  P80 = 'P80',
  P90 = 'P90',
  P95 = 'P95',
  P99 = 'P99',
  P999 = 'P999',
  P9999 = 'P9999',
  STDEV = 'STDEV',
  SUM = 'SUM',
  SUMSQ = 'SUMSQ',
  TYPE_INVALID = 'TYPE_INVALID',
}

export type AlertEvent = {
  alertID: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  details: AlertEventDetails;
  entityGroupId: Scalars['String']['output'];
  eventId: Scalars['String']['output'];
  eventType: AlertEventType;
  monitorId: Scalars['String']['output'];
  monitorSlug: Scalars['String']['output'];
  recordedAt: Scalars['String']['output'];
  signal: Array<Label>;
};

/** AlertEventConnection is a paginated container of a list of alert events. */
export type AlertEventConnection = {
  alertEvents: Array<AlertEvent>;
  /** Pagination metadata to fetch additional pages of data. */
  pageInfo: PageInfo;
};

export type AlertEventDetails =
  | MonitorMuted
  | MonitorResolvedAlertEvent
  | MonitorResolvedNotificationFailedToSend
  | MonitorResolvedNotificationSent
  | MonitorTriggeredAlertEvent
  | MonitorTriggeredNotificationFailedToSend
  | MonitorTriggeredNotificationSent
  | MonitorUnmuted;

export enum AlertEventType {
  MONITOR_MUTED = 'MONITOR_MUTED',
  MONITOR_RESOLVED = 'MONITOR_RESOLVED',
  MONITOR_RESOLVED_NOTIFICATION_FAILED_TO_SEND = 'MONITOR_RESOLVED_NOTIFICATION_FAILED_TO_SEND',
  MONITOR_RESOLVED_NOTIFICATION_SENT = 'MONITOR_RESOLVED_NOTIFICATION_SENT',
  MONITOR_TRIGGERED = 'MONITOR_TRIGGERED',
  MONITOR_TRIGGERED_NOTIFICATION_FAILED_TO_SEND = 'MONITOR_TRIGGERED_NOTIFICATION_FAILED_TO_SEND',
  MONITOR_TRIGGERED_NOTIFICATION_SENT = 'MONITOR_TRIGGERED_NOTIFICATION_SENT',
  MONITOR_UNMUTED = 'MONITOR_UNMUTED',
  UNKNOWN = 'UNKNOWN',
}

export type AlertReceiver = {
  email: Maybe<Array<AlertReceiverEmailConfig>>;
  id: Scalars['ID']['output'];
  managedBy: ManagedBy;
  name: Scalars['String']['output'];
  opsgenie: Maybe<Array<AlertReceiverOpsGenieConfig>>;
  pagerduty: Maybe<Array<AlertReceiverPagerdutyConfig>>;
  slack: Maybe<Array<AlertReceiverSlackConfig>>;
  slug: Scalars['ID']['output'];
  source: EditSource;
  victorOps: Maybe<Array<AlertReceiverVictorOpsConfig>>;
  webhook: Maybe<Array<AlertReceiverWebhookConfig>>;
};

export type AlertReceiverEmailConfig = {
  baseConfig: Maybe<AlertReceiverNotifierBaseConfig>;
  html: Scalars['String']['output'];
  text: Scalars['String']['output'];
  to: Scalars['String']['output'];
};

export type AlertReceiverHTTPConfig = {
  basicAuth: Maybe<AlertReceiverHTTPConfigBasicAuth>;
  bearerToken: Scalars['String']['output'];
  proxyURL: Scalars['String']['output'];
  tlsConfig: Maybe<AlertReceiverHTTPConfigTLSConfig>;
};

export type AlertReceiverHTTPConfigBasicAuth = {
  password: Scalars['String']['output'];
  username: Scalars['String']['output'];
};

export type AlertReceiverHTTPConfigTLSConfig = {
  insecureSkipVerify: Scalars['Boolean']['output'];
};

export type AlertReceiverNotifierBaseConfig = {
  sendResolved: Scalars['Boolean']['output'];
};

export type AlertReceiverOpsGenieConfig = {
  apiKey: Scalars['String']['output'];
  apiURL: Scalars['String']['output'];
  baseConfig: Maybe<AlertReceiverNotifierBaseConfig>;
  description: Scalars['String']['output'];
  details: Scalars['String']['output'];
  httpConfig: Maybe<AlertReceiverHTTPConfig>;
  message: Scalars['String']['output'];
  note: Scalars['String']['output'];
  priority: Scalars['String']['output'];
  responders: Array<AlertReceiverOpsGenieConfigResponder>;
  sendResolved: Scalars['Boolean']['output'];
  source: Scalars['String']['output'];
  tags: Scalars['String']['output'];
};

export type AlertReceiverOpsGenieConfigResponder = {
  responderType: ResponderType;
  responderValue: Scalars['String']['output'];
  responseType: ResponseType;
};

export type AlertReceiverPagerdutyConfig = {
  baseConfig: Maybe<AlertReceiverNotifierBaseConfig>;
  class: Scalars['String']['output'];
  client: Scalars['String']['output'];
  clientURL: Scalars['String']['output'];
  component: Scalars['String']['output'];
  description: Scalars['String']['output'];
  details: Scalars['String']['output'];
  group: Scalars['String']['output'];
  httpConfig: Maybe<AlertReceiverHTTPConfig>;
  images: Array<AlertReceiverPagerdutyConfigImage>;
  links: Array<AlertReceiverPagerdutyConfigLink>;
  serviceKey: Scalars['String']['output'];
  severity: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type AlertReceiverPagerdutyConfigImage = {
  alt: Scalars['String']['output'];
  href: Scalars['String']['output'];
  src: Scalars['String']['output'];
};

export type AlertReceiverPagerdutyConfigLink = {
  href: Scalars['String']['output'];
  text: Scalars['String']['output'];
};

export type AlertReceiverSlackConfig = {
  actions: Array<AlertReceiverSlackConfigAction>;
  apiURL: Scalars['String']['output'];
  baseConfig: Maybe<AlertReceiverNotifierBaseConfig>;
  callbackID: Scalars['String']['output'];
  channel: Scalars['String']['output'];
  color: Scalars['String']['output'];
  fallback: Scalars['String']['output'];
  fields: Array<AlertReceiverSlackConfigField>;
  footer: Scalars['String']['output'];
  httpConfig: Maybe<AlertReceiverHTTPConfig>;
  iconEmoji: Scalars['String']['output'];
  iconURL: Scalars['String']['output'];
  imageURL: Scalars['String']['output'];
  linkNames: Scalars['Boolean']['output'];
  mrkdwnIn: Array<Maybe<Scalars['String']['output']>>;
  pretext: Scalars['String']['output'];
  shortFields: Scalars['Boolean']['output'];
  text: Scalars['String']['output'];
  thumbURL: Scalars['String']['output'];
  title: Scalars['String']['output'];
  titleLink: Scalars['String']['output'];
  username: Scalars['String']['output'];
};

export type AlertReceiverSlackConfigAction = {
  confirm: Maybe<AlertReceiverSlackConfigConfirmField>;
  name: Scalars['String']['output'];
  style: Scalars['String']['output'];
  text: Scalars['String']['output'];
  type: Scalars['String']['output'];
  url: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type AlertReceiverSlackConfigConfirmField = {
  dismissText: Scalars['String']['output'];
  okText: Scalars['String']['output'];
  text: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type AlertReceiverSlackConfigField = {
  short: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type AlertReceiverVictorOpsConfig = {
  apiKey: Scalars['String']['output'];
  apiURL: Scalars['String']['output'];
  baseConfig: Maybe<AlertReceiverNotifierBaseConfig>;
  customFields: Scalars['String']['output'];
  entityDisplayName: Scalars['String']['output'];
  httpConfig: Maybe<AlertReceiverHTTPConfig>;
  messageType: Scalars['String']['output'];
  monitoringTool: Scalars['String']['output'];
  routingKey: Scalars['String']['output'];
  stateMessage: Scalars['String']['output'];
};

export type AlertReceiverWebhookConfig = {
  baseConfig: Maybe<AlertReceiverNotifierBaseConfig>;
  httpConfig: Maybe<AlertReceiverHTTPConfig>;
  url: Scalars['String']['output'];
};

export type AlertReceivers = {
  receivers: Array<AlertReceiver>;
};

export type AlertRouteMatcher = {
  name: Scalars['String']['output'];
  type: AlertRouteMatcherType;
  value: Scalars['String']['output'];
};

export enum AlertRouteMatcherType {
  EXACT_MATCHER_TYPE = 'EXACT_MATCHER_TYPE',
  REGEXP_MATCHER_TYPE = 'REGEXP_MATCHER_TYPE',
}

export type AlertRuleLabel = {
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export enum AlertState {
  CRITICAL = 'CRITICAL',
  DOWNTIMED = 'DOWNTIMED',
  FIRING = 'FIRING',
  INACTIVE = 'INACTIVE',
  PASS = 'PASS',
  PENDING = 'PENDING',
  UNKNOWN = 'UNKNOWN',
  WARNING = 'WARNING',
}

export type AlertStatus = {
  annotations: Maybe<Array<Annotation>>;
  endsAt: Maybe<Scalars['String']['output']>;
  labels: Maybe<Array<Label>>;
  receivers: Maybe<Array<Scalars['String']['output']>>;
  startedAt: Maybe<Scalars['String']['output']>;
  status: AlertState;
  updatedAt: Maybe<Scalars['String']['output']>;
};

export type AllDashboardsConnection = {
  dashboards: Array<IDashboard>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type AllDashboardsFilter = {
  collectionRefs?: InputMaybe<Array<CollectionRef>>;
  collectionSlugs?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Exclude grafana dashboards that have been migrated to a native dashboard */
  excludeMigratedGrafana?: InputMaybe<Scalars['Boolean']['input']>;
  favoritesOnly?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * If true, search for dashboards that are owned by the filtered collections as well as
   * those linked to the filtered collections.
   */
  includeLinkedCollections?: InputMaybe<Scalars['Boolean']['input']>;
  labels?: InputMaybe<Array<LabelInput>>;
  nameContains?: InputMaybe<Scalars['String']['input']>;
  names?: InputMaybe<Array<Scalars['String']['input']>>;
  /** If set, only dashboards that are in the customer namespace will be returned. */
  onlyCustomerManaged?: InputMaybe<Scalars['Boolean']['input']>;
  slugs?: InputMaybe<Array<Scalars['String']['input']>>;
  teamSlugs?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type Annotation = {
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type AnnotationInput = {
  name: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type Attributes = {
  panelType: Scalars['String']['output'];
  unit: Maybe<Scalars['String']['output']>;
};

export type BetweenTimeFilter = {
  maxTimestamp: Scalars['String']['output'];
  minTimestamp: Scalars['String']['output'];
};

export type BetweenTimeFilterInput = {
  maxTimestamp: Scalars['String']['input'];
  minTimestamp: Scalars['String']['input'];
};

export type BoolFilter = {
  value: Scalars['Boolean']['output'];
};

export type BoolFilterInput = {
  value: Scalars['Boolean']['input'];
};

export type BoolValue = {
  value: Scalars['Boolean']['output'];
};

export type BulkUpdateTeam = {
  addedUserIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removedUserIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  teamID: Scalars['ID']['input'];
};

export type BulkUpdateTeamsForUser = {
  addedTeamIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removedTeamIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  userID: Scalars['ID']['input'];
};

export type CloseToTimeFilter = {
  timestamp: Scalars['String']['output'];
};

export type CloseToTimeFilterInput = {
  timestamp: Scalars['String']['input'];
};

export type Collection = ICollection & {
  config: ExportedConfig;
  createdAt: Scalars['String']['output'];
  description: Scalars['String']['output'];
  grafanaDashboards: GrafanaDashboardsConnection;
  homepage: Maybe<CollectionHomepage>;
  managedBy: ManagedBy;
  monitors: MonitorsConnection;
  name: Scalars['String']['output'];
  nullableTeam: Maybe<Team>;
  policy: Maybe<NotificationPolicy>;
  slug: Scalars['String']['output'];
  source: EditSource;
  type: CollectionType;
  updatedAt: Scalars['String']['output'];
};

export type CollectiongrafanaDashboardsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  pageSize: InputMaybe<Scalars['Int']['input']>;
};

export type CollectionmonitorsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  pageSize: InputMaybe<Scalars['Int']['input']>;
};

export type CollectionDashboardConnection = {
  dashboards: Array<Dashboard>;
  pageInfo: PageInfo;
};

/** CollectionDashboardLink is a type that represents a link to a dashboard in a collection. */
export type CollectionDashboardLink = {
  dashboard: IDashboard;
};

export type CollectionHomepage = {
  links: Maybe<Array<Link>>;
};

/** CollectionLink is a type that represents a link to an entity in a collection. */
export type CollectionLink = {
  collection: Collection;
  createdAt: Scalars['String']['output'];
  dashboardLink: Maybe<CollectionDashboardLink>;
  monitorLink: Maybe<CollectionMonitorLink>;
  slug: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
};

export type CollectionLinkDashboardRefInput = {
  slug: Scalars['String']['input'];
};

/** CollectionLinkEntityReference is a type that specifies a reference to an entity in a collection link. */
export type CollectionLinkEntityReferenceInput = {
  /** The slug of entity being referenced */
  slug: Scalars['String']['input'];
};

/**
 * CollectionLinkInput is an input type that specifies a collection link to create or update.
 * A collection link must reference either a monitor or a dashboard.
 * If a slug is not provided, a new collection link will be created.
 */
export type CollectionLinkInput = {
  collectionRef: CollectionRef;
  dashboardRef?: InputMaybe<CollectionLinkDashboardRefInput>;
  monitorRef?: InputMaybe<CollectionLinkMonitorRefInput>;
};

/** CollectionLinkListFilterInput is an input type that specifies filter criteria for listing collection links. */
export type CollectionLinkListFilterInput = {
  collectionRefs?: InputMaybe<Array<CollectionRef>>;
  dashboardRefs?: InputMaybe<Array<CollectionLinkEntityReferenceInput>>;
  monitorRefs?: InputMaybe<Array<CollectionLinkEntityReferenceInput>>;
  types?: InputMaybe<Array<CollectionLinkType>>;
};

export type CollectionLinkMonitorRefInput = {
  impactsServiceHealth: Scalars['Boolean']['input'];
  slug: Scalars['String']['input'];
};

/** CollectionLinkType is an enum that represents the type of entity that a collection link references. */
export enum CollectionLinkType {
  DASHBOARD = 'DASHBOARD',
  MONITOR = 'MONITOR',
}

/** CollectionLinksConnection is a type that represents a paginated list of collection links. */
export type CollectionLinksConnection = {
  /** A list of collection links. */
  collectionLinks: Array<CollectionLink>;
  /** Pagination metadata to fetch additional pages of data. */
  pageInfo: PageInfo;
  /**
   * Total count of collection links.
   * This count includes all collection links and therefore may be larger than the length of the services field.
   */
  totalCount: Scalars['Int']['output'];
};

/** CollectionMonitorLink is a type that represents a link to a monitor in a collection. */
export type CollectionMonitorLink = {
  impactsServiceHealth: Scalars['Boolean']['output'];
  monitor: Monitor;
};

/** CollectionRef is used to querying for collections or items in collections. */
export type CollectionRef = {
  slug: Scalars['String']['input'];
  type: CollectionType;
};

export enum CollectionType {
  SERVICE = 'SERVICE',
  SIMPLE = 'SIMPLE',
}

/** CollectionsConnection is a paginated container of a list of collections. */
export type CollectionsConnection = {
  /** Collections for this page. The pageInfo field contains metadata to fetch additional pages. */
  collections: Array<Collection>;
  /** Pagination metadata to fetch additional pages of data. */
  pageInfo: PageInfo;
  /**
   * Total count of collections.
   * This count includes all collections and therefore may be larger than the length of the collections field.
   */
  totalCount: Scalars['Int']['output'];
};

export type CollectionsFilters = {
  /** If set, will also return inactive collections. If not provided that will also include inactive collections. */
  includeInactive?: InputMaybe<Scalars['Boolean']['input']>;
  /** If set, only collections whose name contains the specified string will be returned. */
  nameContains?: InputMaybe<Scalars['String']['input']>;
  /** If set, only collections who have the default notification policy set to the specified slug will be returned." */
  notificationPolicySlugs?: InputMaybe<Array<Scalars['String']['input']>>;
  /** If set, only collections that are in the customer namespace will be returned. */
  onlyCustomerManaged?: InputMaybe<Scalars['Boolean']['input']>;
  /** If set, only collections who are unowned will be returned. */
  requireUnowned?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter returned collections by a set of team slugs. */
  teamSlugs?: InputMaybe<Array<Scalars['String']['input']>>;
  /** If set, return collections matching the specified types */
  types?: InputMaybe<Array<InputMaybe<CollectionType>>>;
};

export type Component = {
  /**
   * Event JSONs to render on panels and more details views.
   *
   * This field is only available if the ComponentPresentationType is "EVENTS"
   */
  eventJSONs: Maybe<Array<Scalars['JSONObject']['output']>>;
  /**
   *  Input Dashboard JSON along with the additional fields used to generate the component.
   * Can be used to edit the component
   */
  inputJSON: Maybe<Scalars['JSONObject']['output']>;
  interval: Maybe<Scalars['String']['output']>;
  managedBy: ManagedByType;
  /**
   * A Chronosphere dashboard json that provides more info on this component.
   *
   * There will be a $service template variable on this dashboard.
   */
  moreDetailsJSON: Maybe<Scalars['JSONObject']['output']>;
  name: Scalars['String']['output'];
  panelJSONs: Maybe<Array<Scalars['JSONObject']['output']>>;
  /** @deprecated This field now always returns an empty list. Use panelJSONs instead */
  panels: Array<Panel>;
  presentationType: ComponentPresentationType;
  serviceTemplateVar: Maybe<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
  templates: Array<Template>;
  type: ComponentType;
};

export type ComponentDashboardPresentationConfig = {
  inputJSON: Maybe<Scalars['JSONObject']['output']>;
  interval: Maybe<Scalars['String']['output']>;
  moreDetailsJSON: Maybe<Scalars['JSONObject']['output']>;
  panelCount: Scalars['Int']['output'];
  panelJSONs: Maybe<Array<Scalars['JSONObject']['output']>>;
  /** @deprecated This field is now always empty. Use panelJSONs instead */
  panels: Maybe<Array<Panel>>;
  serviceTemplateVar: Maybe<Scalars['String']['output']>;
  templates: Array<Template>;
};

export type ComponentDiscovery = {
  event: Maybe<EventDiscovery>;
  metric: Maybe<MetricDiscovery>;
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  trace: Maybe<TraceDiscovery>;
};

export type ComponentDiscoveryConfig = {
  discovery: ComponentDiscovery;
  discoveryOverride: Maybe<ComponentDiscoveryOverride>;
  managedBy: ManagedByType;
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
};

export type ComponentDiscoveryOverride = {
  discoverySlug: Scalars['String']['output'];
  metric: Maybe<MetricDiscoveryOverride>;
  status: Maybe<ComponentDiscoveryOverrideStatus>;
};

export type ComponentDiscoveryOverrideInput = {
  metric?: InputMaybe<MetricDiscoveryOverrideInput>;
  slug: Scalars['String']['input'];
  status?: InputMaybe<ComponentDiscoveryOverrideStatus>;
};

export enum ComponentDiscoveryOverrideStatus {
  DISABLED = 'DISABLED',
  ENABLED = 'ENABLED',
}

export type ComponentEventPresentationConfig = {
  eventJSONs: Maybe<Array<Scalars['JSONObject']['output']>>;
};

export type ComponentPresentationConfig = {
  dashboard: Maybe<ComponentDashboardPresentationConfig>;
  description: Maybe<Scalars['String']['output']>;
  eligibility: PresentationEligibility;
  events: Maybe<ComponentEventPresentationConfig>;
  managedBy: ManagedByType;
  name: Scalars['String']['output'];
  override: Maybe<ComponentPresentationOverride>;
  presentationType: ComponentPresentationType;
  slug: Scalars['String']['output'];
};

export type ComponentPresentationDiffInput = {
  eligibility: EligibilityCriteriaInput;
  slug: Scalars['String']['input'];
  status: ComponentPresentationOverrideStatus;
};

export type ComponentPresentationOverride = {
  componentPresentationSlug: Scalars['String']['output'];
  status: ComponentPresentationOverrideStatus;
};

export type ComponentPresentationOverrideInput = {
  componentPresentationSlug: Scalars['String']['input'];
  status: ComponentPresentationOverrideStatus;
};

export enum ComponentPresentationOverrideStatus {
  DISABLED = 'DISABLED',
  ENABLED = 'ENABLED',
}

export enum ComponentPresentationType {
  EVENTS = 'EVENTS',
  PANELS = 'PANELS',
  UNKNOWN = 'UNKNOWN',
}

export enum ComponentType {
  COMPUTE = 'COMPUTE',
  RPC = 'RPC',
  UNKNOWN = 'UNKNOWN',
}

export enum ConditionLevel {
  CRITICAL = 'CRITICAL',
  UNKNOWN = 'UNKNOWN',
  WARN = 'WARN',
}

export type ConditionOverride = {
  conditions: Maybe<Array<SeverityCondition>>;
  matchers: Maybe<Array<AlertRouteMatcher>>;
};

export type CountFilter = {
  isMaxExclusive: Maybe<Scalars['Boolean']['output']>;
  isMinExclusive: Maybe<Scalars['Boolean']['output']>;
  max: Scalars['Int']['output'];
  min: Scalars['Int']['output'];
};

export type CountFilterInput = {
  isMaxExclusive?: InputMaybe<Scalars['Boolean']['input']>;
  isMinExclusive?: InputMaybe<Scalars['Boolean']['input']>;
  max: Scalars['Int']['input'];
  min: Scalars['Int']['input'];
};

export type CreateCollection = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  /** Optional slug of notification policy used by this collection */
  policySlug?: InputMaybe<Scalars['String']['input']>;
  /** Optional slug for the collection. If omitted, this will be assigned based on name. */
  slug?: InputMaybe<Scalars['String']['input']>;
  team_slug?: InputMaybe<Scalars['String']['input']>;
};

export type CreateCustomComponentInput = {
  dashboard: Scalars['JSONObject']['input'];
  dryRun?: InputMaybe<Scalars['Boolean']['input']>;
  interval?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  serviceTemplateVar?: InputMaybe<Scalars['String']['input']>;
};

export type CreateDatasetInput = {
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
  slug: Scalars['String']['input'];
  traceConfiguration?: InputMaybe<TraceDatasetConfigurationInput>;
  type: DatasetType;
};

export type CreateDiscoveryInput = {
  /** Discovery config for events. */
  events?: InputMaybe<EventDiscoveryInput>;
  /** Discovery config for metrics. */
  metrics?: InputMaybe<MetricsDiscoveryInput>;
  name: Scalars['String']['input'];
};

export type CreateLinkTemplateInput = {
  name: Scalars['String']['input'];
  slug?: InputMaybe<Scalars['ID']['input']>;
  urlTemplate: Scalars['String']['input'];
};

export type CreatePersonalAccessTokenInput = {
  expiration: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CreatePersonalAccessTokenResponse = {
  metadata: PersonalAccessToken;
  token: Scalars['String']['output'];
};

export type CreatePresentationInput = {
  dashboard?: InputMaybe<PresentationDashboardInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  eligibility: PresentationEligibilityInput;
  events?: InputMaybe<PresentationEventsInput>;
  name: Scalars['String']['input'];
};

export type CreateSavedTraceSearchInput = {
  comparison?: InputMaybe<TraceSearchInput>;
  name: Scalars['String']['input'];
  search: TraceSearchInput;
  slug?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateSearchHistoryInput = {
  search: NewSearchHistory;
};

export type CreateServiceAccountInput = {
  labels?: InputMaybe<Array<LabelInput>>;
  name: Scalars['String']['input'];
  permission?: InputMaybe<PermissionAction>;
};

export type CreateServiceAccountResponse = {
  serviceAccount: ServiceAccount;
  token: Scalars['String']['output'];
};

export type CreateSignupInviteInput = {
  email: Scalars['String']['input'];
};

export type CreateTraceBehaviorInput = {
  baseHeadSampleRate?: InputMaybe<Scalars['Float']['input']>;
  baseTailSampleRate?: InputMaybe<Scalars['Float']['input']>;
  baselineBehaviorOptions?: InputMaybe<TraceBehaviorBaselineOptionsInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  errorSampleOptions?: InputMaybe<TraceBehaviorErrorSampleOptionsInput>;
  fastSampleOptions?: InputMaybe<TraceBehaviorFastSampleOptionsInput>;
  largeTraceSampleOptions?: InputMaybe<TraceBehaviorLargeTraceSampleOptionsInput>;
  name: Scalars['String']['input'];
  slowSampleOptions?: InputMaybe<TraceBehaviorSlowSampleOptionsInput>;
  slug?: InputMaybe<Scalars['ID']['input']>;
  smallTraceSampleOptions?: InputMaybe<TraceBehaviorSmallTraceSampleOptionsInput>;
  type?: InputMaybe<TraceBehaviorType>;
};

export type CreateTraceMetricsRuleInput = {
  groupBys?: InputMaybe<Array<TraceMetricsGroupByInput>>;
  histogramBucketsSeconds: Array<Scalars['Float']['input']>;
  metricLabels: Array<LabelInput>;
  metricName: Scalars['String']['input'];
  name: Scalars['String']['input'];
  slug?: InputMaybe<Scalars['ID']['input']>;
  traceFilter: TraceSearchFilterInput;
};

export type CreateTraceSearchHistoryInput = {
  comparison?: InputMaybe<TraceSearchInput>;
  search: TraceSearchInput;
};

export type CustomComponentOutput = {
  component: Component;
  discovery: ComponentDiscoveryConfig;
};

export type DarkModeInput = {
  darkMode: Scalars['Boolean']['input'];
};

export type Dashboard = IDashboard & {
  collection: Maybe<Collection>;
  collectionLinks: Maybe<Array<CollectionLink>>;
  config: Scalars['JSONObject']['output'];
  createdAt: Scalars['String']['output'];
  /** Deprecated, use config instead. */
  dashboardJSON: Scalars['JSONObject']['output'];
  exportConfig: ExportedConfig;
  isFavorite: Scalars['Boolean']['output'];
  /**
   * Whether this dashboard was migrated from a grafana dashboard.
   *
   * This means there's a grafana version of this dashboard that was originally
   * migrated to this dashboard, and they should share the same slug.
   */
  isMigrated: Scalars['Boolean']['output'];
  labels: Maybe<Array<Label>>;
  managedBy: ManagedBy;
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  source: EditSource;
  updatedAt: Scalars['String']['output'];
  versions: EntityVersionsConnection;
};

export type DashboardLabel = {
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type DashboardsConnection = {
  dashboards: Array<Dashboard>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type Dataset = {
  configuration: DatasetConfiguration;
  createdAt: Scalars['String']['output'];
  description: Scalars['String']['output'];
  enhancements: Maybe<DatasetEnhancement>;
  managedBy: Maybe<ManagedBy>;
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  source: Maybe<EditSource>;
  type: DatasetType;
  updatedAt: Scalars['String']['output'];
};

export type DatasetConfiguration = TraceDatasetConfiguration;

export type DatasetConnection = {
  datasets: Array<Dataset>;
  pageInfo: PageInfo;
};

export type DatasetEnhancement = TraceDatasetEnhancement;

export type DatasetEnhancementsInput = {
  traceInput?: InputMaybe<TraceDatasetEnhancementsInput>;
  type: DatasetType;
};

export enum DatasetType {
  DATASET_TYPE_INVALID = 'DATASET_TYPE_INVALID',
  TRACES = 'TRACES',
}

export type DatasetsListFilterInput = {
  names?: InputMaybe<Array<Scalars['String']['input']>>;
  slugs?: InputMaybe<Array<Scalars['String']['input']>>;
  type?: InputMaybe<DatasetType>;
};

export enum DatasourceType {
  GRAPHITE = 'GRAPHITE',
  LOGGING = 'LOGGING',
  PROMETHEUS = 'PROMETHEUS',
}

/**
 * DebugLog encapsulates a debug log session captured as part of execution. It provides more information
 * to help us debug complex flows, but may be too detailed to put into GCP logs.
 */
export type DebugLog = {
  /** List of debug log entries. Entries should be chronological. */
  logs: Array<DebugLogEntry>;
  /**
   * Arbitrary data about this set of log entries. Can put info like isDryRun, startTime, endTime,
   * user who initiated the request, etc. This is an object of type map<string, string>
   */
  metadata: Maybe<Scalars['JSONObject']['output']>;
  /**
   * A name for this set of logs. Pick a name meaningful to the execution or workflow that this log
   * represents. If these logs are on a debug page, the name would be the DisplayName that links
   * to this set of logs.
   */
  name: Scalars['String']['output'];
};

/** DebugLogEntry is a single entry at a point in time in a debug log. */
export type DebugLogEntry = {
  /**
   * Key/Value fields containing more information about this log message. Keys should be simple
   * ascii strings, but values maybe anything, including serialized JSON as a string. This is an
   * object of type map<string, string>
   */
  fields: Maybe<Scalars['JSONObject']['output']>;
  /**
   * Log message. Avoid putting variable strings (Sprintf) in the log message to facilitate search
   * and aggregation. Put variable values in fields instead.
   */
  message: Scalars['String']['output'];
  /**
   * Path to trace where in an execution a log entry comes from. users of the debug log library
   * should update the path to semantically identify its place in the execution flow.
   */
  path: Scalars['String']['output'];
  /** Time when the log happened. */
  timestamp: Scalars['Time']['output'];
};

export type DebugLogFilter = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DefaultResourcePool = {
  /** @deprecated DefaultPool allocation is deprecated and no longer must be specified. When unspecified, allocation is derived as the remaining amount from the sum across pools. */
  allocation: Maybe<ResourcePoolAllocation>;
  priorities: Maybe<ResourcePoolHighLowPriorities>;
};

export type DefaultSampleRate = {
  enabled: Scalars['Boolean']['output'];
  sampleRate: Scalars['Float']['output'];
};

export type DeleteAlertNotificationPolicy = {
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type DeleteAlertReceiver = {
  /** Deprecated, use slug when possible. */
  id?: InputMaybe<Scalars['ID']['input']>;
  slug?: InputMaybe<Scalars['ID']['input']>;
};

export type DeleteCollection = {
  /** Required slug for the collection to delete. */
  slug: Scalars['ID']['input'];
};

/** Input for deleting a collection homepage if it exists. */
export type DeleteCollectionHomepageInput = {
  collectionSlug: Scalars['String']['input'];
};

/** DeleteCollectionLinksInput is an input type that specifies the identity of a collection link to delete. */
export type DeleteCollectionLinksInput = {
  slugs: Array<Scalars['String']['input']>;
};

export type DeleteCustomComponentInput = {
  slug: Scalars['String']['input'];
};

export type DeleteDashboard = {
  slug: Scalars['String']['input'];
};

export type DeleteDiscoveryInput = {
  slug: Scalars['String']['input'];
};

export type DeleteDropRule = {
  slug: Scalars['String']['input'];
};

export type DeleteGrafanaDashboard = {
  slug: Scalars['String']['input'];
};

export type DeleteIngestionPolicy = {
  id: Scalars['ID']['input'];
};

export type DeleteLinkTemplateInput = {
  slug: Scalars['ID']['input'];
};

export type DeleteMappingRule = {
  slug: Scalars['String']['input'];
};

export type DeleteMonitor = {
  slug: Scalars['String']['input'];
};

export type DeletePersonalAccessTokensInput = {
  slugs: Array<Scalars['ID']['input']>;
};

export type DeletePresentationInput = {
  slug: Scalars['String']['input'];
};

export type DeleteRecordingRule = {
  id: Scalars['ID']['input'];
};

export type DeleteRollupRule = {
  slug: Scalars['String']['input'];
};

export type DeleteSLOInput = {
  slug: Scalars['String']['input'];
};

export type DeleteSavedTraceSearchInput = {
  slug: Scalars['ID']['input'];
};

export type DeleteSearchHistoryInput = {
  id: Scalars['String']['input'];
};

export type DeleteServiceAccountInput = {
  id: Scalars['String']['input'];
};

export type DeleteServiceAccountsInput = {
  ids: Array<Scalars['String']['input']>;
};

export type DeleteTeam = {
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Input for deleting a team homepage if it exists. */
export type DeleteTeamHomepageInput = {
  teamSlug: Scalars['String']['input'];
};

export type DeleteTraceBehaviorInput = {
  slug: Scalars['ID']['input'];
};

export type DeleteTraceMetricsRuleInput = {
  slug: Scalars['String']['input'];
};

export type DeleteUserInput = {
  /** Deactivate the user first before deleting, even if they were not deactivated */
  forceDeactivate?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
};

export type DerivedLabelFilter = {
  name: Scalars['String']['output'];
  valueGlob: Scalars['String']['output'];
};

export type DerivedLabelFilterInput = {
  /** The name of the field in a Prom expression that is being used to filter results */
  name: Scalars['String']['input'];
  /** A glob match of possible values for the field */
  valueGlob: Scalars['String']['input'];
};

export type DiscoveredComponent = {
  slug: Scalars['String']['output'];
  status: DiscoveryStatus;
};

export type DiscoveredService = {
  /** @deprecated replaced by discoveredComponents */
  components: Array<DiscoveredComponent>;
  discoveredComponents: Array<DiscoveredComponent>;
  name: Scalars['String']['output'];
  presentationComponents: Array<PresentationComponent>;
  slug: Scalars['String']['output'];
  status: DiscoveryStatus;
};

export type DiscoveredServicepresentationComponentsArgs = {
  presentationOverrides: InputMaybe<Array<ComponentPresentationDiffInput>>;
};

export type DiscoveryConfigsInput = {
  debugLogFilter?: InputMaybe<DebugLogFilter>;
  discoveryOverrides?: InputMaybe<Array<ComponentDiscoveryOverrideInput>>;
  /** @deprecated replaced with discoveryOverrides */
  overrides?: InputMaybe<Array<ComponentDiscoveryOverrideInput>>;
  rules?: InputMaybe<Array<ObjectMergeRuleInput>>;
  /**
   * The discovery pipeline caches the results of the most recent run
   * as well a few of the results of the most recent dry runs.
   * The discovery pipeline only reads from the cache for dry runs.
   * Setting this field to true will force a dry run to run the entire
   * discovery pipeline even if there is a cached result already.
   * If this is not a dry run, this field does nothing.
   */
  skipCache?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum DiscoveryStatus {
  Added = 'Added',
  Deleted = 'Deleted',
  Modified = 'Modified',
  Unchanged = 'Unchanged',
  Unknown = 'Unknown',
}

export type Downtime = {
  comment: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  endsAt: Scalars['String']['output'];
  expired: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  matchers: Array<LabelMatcher>;
  startsAt: Scalars['String']['output'];
};

export type DropRule = {
  conditionalRateBasedDrop: Maybe<DropRuleConditionalRateBasedDrop>;
  config: ExportedConfig;
  createdAt: Scalars['String']['output'];
  dropNanValue: Maybe<Scalars['Boolean']['output']>;
  filters: Array<Label>;
  managedBy: ManagedBy;
  mode: DropRuleMode;
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  source: EditSource;
  updatedAt: Scalars['String']['output'];
  valueBasedDrop: Maybe<DropRuleValueBasedDrop>;
};

export type DropRuleConditionalRateBasedDrop = {
  activatedDropDurationSecs: Scalars['Int']['output'];
  enabled: Scalars['Boolean']['output'];
  rateLimitThreshold: Scalars['Float']['output'];
};

export enum DropRuleMode {
  DISABLED = 'DISABLED',
  ENABLED = 'ENABLED',
}

export type DropRuleValueBasedDrop = {
  enabled: Scalars['Boolean']['output'];
  targetDropValue: Scalars['Float']['output'];
};

export type DropValue = {
  enabled: Scalars['Boolean']['output'];
  targetDropValue: Scalars['Int']['output'];
};

export type DurationFilter = {
  maxSeconds: Scalars['Float']['output'];
  minSeconds: Scalars['Float']['output'];
};

export type DurationFilterInput = {
  maxSeconds: Scalars['Float']['input'];
  minSeconds: Scalars['Float']['input'];
};

export enum EditSource {
  EDITABLE = 'EDITABLE',
  UNEDITABLE_K8S_OWNED = 'UNEDITABLE_K8S_OWNED',
  UNEDITABLE_SLO_GENERATED = 'UNEDITABLE_SLO_GENERATED',
  UNEDITABLE_TF_OWNED = 'UNEDITABLE_TF_OWNED',
}

export type EligibilityCriteriaInput = {
  allowedServices?: InputMaybe<Array<Scalars['String']['input']>>;
  ignoredServices?: InputMaybe<Array<Scalars['String']['input']>>;
  requiredDiscoveries?: InputMaybe<Array<Scalars['String']['input']>>;
  withoutDiscoveries?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type EligibleServicesConnection = {
  /** Pagination metadata to fetch additional pages of data. */
  pageInfo: PageInfo;
  /** Services for this page. The pageInfo field contains metadata to fetch additional pages. */
  services: Array<Service>;
};

export type EligibleServicesInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  eligibility: EligibilityCriteriaInput;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type EntityVersion = {
  config: ExportedConfig;
  createdAt: Scalars['String']['output'];
  entity: EntityVersionEntity;
  id: Scalars['String']['output'];
  sourceClient: EntityVersionSource;
  user: Maybe<User>;
};

export type EntityVersionEntity = Dashboard | Monitor;

export type EntityVersionFilters = {
  /** The slug of the entity whose versions to fetch. */
  slug: Scalars['String']['input'];
  /**
   * Optional sources to filter on
   * If multiple sources are provided, the results are "OR'd" together. If used wth other
   * filters, then the filtered results are "AND'd" together.
   */
  sources?: InputMaybe<Array<EntityVersionSource>>;
  /** Optional time range to filter versions within */
  timeRange?: InputMaybe<NewTimeRange>;
  /** The type of the entity whose versions to fetch. */
  type: EntityVersionType;
  /** Sort order for versions, will default to descending */
  versionOrder?: InputMaybe<EntityVersionOrder>;
};

export enum EntityVersionOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum EntityVersionSource {
  API = 'API',
  CHRONOCTL = 'CHRONOCTL',
  TERRAFORM = 'TERRAFORM',
  UI = 'UI',
  UNKNOWN = 'UNKNOWN',
}

export enum EntityVersionType {
  DASHBOARD = 'DASHBOARD',
  MONITOR = 'MONITOR',
}

export type EntityVersionsConnection = {
  nodes: Array<EntityVersion>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type EventDiscovery = {
  serviceNameLabel: Scalars['String']['output'];
};

export type EventDiscoveryInput = {
  /** Change events label name to use to discover service names. Values of this label will be used as candidates for service names. */
  serviceNameLabel: Scalars['String']['input'];
};

export type EventLog = {
  applicationName: Scalars['String']['output'];
  dataJson: Scalars['String']['output'];
  eventCode: Scalars['String']['output'];
  eventDescription: Scalars['String']['output'];
  eventTime: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  methodName: Scalars['String']['output'];
  resourceName: Scalars['String']['output'];
  userEmail: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type EventLogListItem = {
  eventCode: Scalars['String']['output'];
  eventDescription: Scalars['String']['output'];
  eventTime: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  userEmail: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type EventLogs = {
  items: Maybe<Array<EventLogListItem>>;
  nextPageToken: Scalars['Int']['output'];
};

/** ExportConfig exposes the raw of configuration of certain entity types */
export type ExportConfigQuery = {
  collection: ExportedConfig;
  dashboard: ExportedConfig;
  dropRule: ExportedConfig;
  mappingRule: ExportedConfig;
  monitor: ExportedConfig;
  resourcePools: ExportedConfig;
  rollupRule: ExportedConfig;
  team: ExportedConfig;
};

/** ExportConfig exposes the raw of configuration of certain entity types */
export type ExportConfigQuerycollectionArgs = {
  collection: CreateCollection;
};

/** ExportConfig exposes the raw of configuration of certain entity types */
export type ExportConfigQuerydashboardArgs = {
  dashboard: SaveDashboardInput;
};

/** ExportConfig exposes the raw of configuration of certain entity types */
export type ExportConfigQuerydropRuleArgs = {
  dropRule: NewDropRule;
};

/** ExportConfig exposes the raw of configuration of certain entity types */
export type ExportConfigQuerymappingRuleArgs = {
  mappingRule: NewMappingRule;
};

/** ExportConfig exposes the raw of configuration of certain entity types */
export type ExportConfigQuerymonitorArgs = {
  monitor: NewMonitor;
};

/** ExportConfig exposes the raw of configuration of certain entity types */
export type ExportConfigQueryresourcePoolsArgs = {
  resourcePools: NewResourcePools;
};

/** ExportConfig exposes the raw of configuration of certain entity types */
export type ExportConfigQueryrollupRuleArgs = {
  rollupRule: NewRollupRule;
};

/** ExportConfig exposes the raw of configuration of certain entity types */
export type ExportConfigQueryteamArgs = {
  team: NewTeam;
};

export enum ExportFormat {
  CHRONOCTL = 'CHRONOCTL',
  TERRAFORM = 'TERRAFORM',
  UNKNOWN = 'UNKNOWN',
}

export type ExportedConfig = {
  api: Scalars['String']['output'];
  chronoctl: Scalars['String']['output'];
  dryRunError: Maybe<Scalars['String']['output']>;
  terraform: Scalars['String']['output'];
};

export enum FavoriteEntityType {
  DASHBOARD = 'DASHBOARD',
  GRAFANA_DASHBOARD = 'GRAFANA_DASHBOARD',
  MONITOR = 'MONITOR',
}

export type FeatureFlag = {
  key: Scalars['String']['output'];
  usingDefaultValue: Scalars['Boolean']['output'];
  value: FeatureFlagValue;
};

export type FeatureFlagRequest = {
  /** The value type that the flag is expected to return. */
  flagType: FeatureFlagType;
  /** The key by which the flag is defined in LaunchDarkly. */
  key: Scalars['String']['input'];
};

export enum FeatureFlagType {
  BOOL = 'BOOL',
  INT = 'INT',
  STRING = 'STRING',
}

export type FeatureFlagValue = BoolValue | IntValue | StringValue;

export type GatewayConfigs = {
  collectionsAccess: Scalars['String']['output'];
  enableAlertsV2Docs: Scalars['Boolean']['output'];
  enableExternalServiceConfig: Scalars['Boolean']['output'];
  enableMetricsDDX: Scalars['Boolean']['output'];
  returnedDatapointsLimit: Scalars['Int']['output'];
  returnedSeriesLimit: Scalars['Int']['output'];
  serviceUIEnabled: Scalars['Boolean']['output'];
  sloUIEnabled: Scalars['Boolean']['output'];
  tracingUIEnabled: Scalars['Boolean']['output'];
};

export type GrafanaDashboard = IDashboard & {
  collection: Maybe<Collection>;
  collectionLinks: Maybe<Array<CollectionLink>>;
  config: Scalars['JSONObject']['output'];
  /** Deprecated, use config instead. */
  dashboardJSON: Scalars['String']['output'];
  isFavorite: Scalars['Boolean']['output'];
  /**
   * Whether this dashboard was migrated to a native dashboard.
   *
   * This means there's a native version of this dashboard with the same slug
   * that was originally migrated form this dashboard.
   */
  isMigrated: Scalars['Boolean']['output'];
  labels: Maybe<Array<Label>>;
  managedBy: ManagedBy;
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  source: EditSource;
};

export type GrafanaDashboardFilter = {
  collectionRefs?: InputMaybe<Array<CollectionRef>>;
  collectionSlugs?: InputMaybe<Array<Scalars['String']['input']>>;
  /** If set and set to true, filters to only favorites */
  favoritesOnly?: InputMaybe<Scalars['Boolean']['input']>;
  labels?: InputMaybe<Array<LabelInput>>;
  teamSlugs?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type GrafanaDashboardSettings = {
  /**
   * Collection where this dashboard belongs. Saving with a different collection slug will move
   * the dashboard from the old collection to the new collection.
   */
  collectionSlug?: InputMaybe<Scalars['String']['input']>;
  /**
   * Type of the collection containing the dashboard. Optional for backwards compatibility. Defaults to SIMPLE if not
   * specified.
   */
  collectionType?: InputMaybe<CollectionType>;
  /** Labels for dashboards. These correspond to "tags" in grafana. The only valid key is "grafanaTag". */
  labels?: InputMaybe<Array<LabelInput>>;
  /** Name of the dashboard. This maps to a grafana dashboard title. */
  name: Scalars['String']['input'];
  /**
   * Slug is a unique identifier for dashboards. Saving a dashboard without a slug will
   * create a new dashboard and generate a new slug. This field maps to a grafana dashboard uid.
   *
   * If a dashboard with this slug already exists, this will overwrite the dashboard.
   */
  slug?: InputMaybe<Scalars['String']['input']>;
};

/** GrafanaDashboardsConnection is a paginated container of a list of grafana dashboards. */
export type GrafanaDashboardsConnection = {
  /** Dashboards for this page. The pageInfo field contains metadata to fetch additional pages. */
  dashboards: Array<GrafanaDashboard>;
  /** Pagination metadata to fetch additional pages of data. */
  pageInfo: PageInfo;
  /**
   * Total count of grafana dashboards.
   * This count includes all grafana dashboards and therefore may be larger than the length of the dashboards field.
   */
  totalCount: Scalars['Int']['output'];
};

export type GraphiteLabelPolicy = {
  replace: Maybe<Array<Maybe<GraphiteLabelPolicyReplace>>>;
};

export type GraphiteLabelPolicyReplace = {
  name: Scalars['String']['output'];
  newValue: Scalars['String']['output'];
};

export type ICollection = {
  description: Scalars['String']['output'];
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  type: CollectionType;
};

export type IDashboard = {
  collection: Maybe<Collection>;
  collectionLinks: Maybe<Array<CollectionLink>>;
  config: Scalars['JSONObject']['output'];
  isFavorite: Scalars['Boolean']['output'];
  /**
   * Whether this dashboard was migrated to/from a grafana dashboard.
   *
   * This means there's a grafana and native versions of this dashboard.
   */
  isMigrated: Scalars['Boolean']['output'];
  managedBy: ManagedBy;
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  source: EditSource;
};

export type ImportGrafanaDashboard = {
  /**
   * Grafana dashboard json. Any differences in name, uid (slug) or tags (labels)
   * in the dashboard json will be overridden by corresponding fields in the settings.
   */
  dashboardJSON: Scalars['String']['input'];
  settings: GrafanaDashboardSettings;
};

export type IngestionPolicies = {
  items: Maybe<Array<IngestionPolicy>>;
};

export type IngestionPolicy = {
  active: Scalars['Boolean']['output'];
  conditionalDrop: Scalars['Boolean']['output'];
  createdAt: Scalars['String']['output'];
  dropDuration: Maybe<Scalars['String']['output']>;
  dropDurationMins: Maybe<Scalars['Int']['output']>;
  dropIndex: Scalars['Float']['output'];
  dropNanValue: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  managedBy: ManagedBy;
  /** Deprecated, use slug instead. */
  name: Scalars['String']['output'];
  newName: Scalars['String']['output'];
  query: Maybe<Array<Scalars['String']['output']>>;
  rateLimitThreshold: Maybe<Scalars['Float']['output']>;
  slug: Scalars['String']['output'];
  source: EditSource;
  /** Deprecated, use newName instead. */
  title: Scalars['String']['output'];
  updatedAt: Maybe<Scalars['String']['output']>;
  valueBasedDropPolicy: Maybe<DropValue>;
};

export type InputDropRuleConditionalRateBasedDrop = {
  activatedDropDurationSecs: Scalars['Int']['input'];
  enabled: Scalars['Boolean']['input'];
  rateLimitThreshold: Scalars['Float']['input'];
};

export type InputDropRuleValueBasedDrop = {
  enabled: Scalars['Boolean']['input'];
  targetDropValue: Scalars['Float']['input'];
};

export type InputGraphiteLabelPolicy = {
  replace?: InputMaybe<Array<InputMaybe<InputGraphiteLabelPolicyReplace>>>;
};

export type InputGraphiteLabelPolicyReplace = {
  name: Scalars['String']['input'];
  newValue: Scalars['String']['input'];
};

export type InputLabelPolicy = {
  discard?: InputMaybe<Array<Scalars['String']['input']>>;
  keep?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type InputMappingRuleAggregationPolicy = {
  aggregation: AggregationType;
  dropTimestamp: Scalars['Boolean']['input'];
  interval?: InputMaybe<Scalars['String']['input']>;
  storagePolicy?: InputMaybe<InputStoragePolicy>;
};

export type InputRollupRuleLabelReplace = {
  dstLabelName?: InputMaybe<Scalars['String']['input']>;
  keepLabel?: InputMaybe<Scalars['Boolean']['input']>;
  labelValueRegex?: InputMaybe<Scalars['String']['input']>;
  newLabelValue: Scalars['String']['input'];
  srcLabelName: Scalars['String']['input'];
};

export type InputStoragePolicy = {
  resolution: Scalars['String']['input'];
  retention: Scalars['String']['input'];
};

export type IntValue = {
  value: Scalars['Int']['output'];
};

export type JaegerRemotePerOperationSamplingStrategies = {
  defaultLowerBoundTracesPerSecond: Scalars['Float']['output'];
  defaultSamplingRate: Scalars['Float']['output'];
  defaultUpperBoundTracesPerSecond: Scalars['Float']['output'];
  perOperationSamplingStrategies: Maybe<Array<JaegerRemotePerOperationSamplingStrategy>>;
};

export type JaegerRemotePerOperationSamplingStrategy = {
  operation: Scalars['String']['output'];
  probabilisticStrategy: JaegerRemoteSamplingProbabilisticStrategy;
};

export type JaegerRemoteSamplingProbabilisticStrategy = {
  samplingRate: Scalars['Float']['output'];
};

export type JaegerRemoteSamplingRateLimitingSamplingStrategy = {
  maxTracesPerSecond: Scalars['Int']['output'];
};

export type Label = {
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type LabelConfig = {
  events: Maybe<LabelMapping>;
  name: Scalars['String']['output'];
  tracing: Maybe<LabelMapping>;
};

export type LabelFilter = {
  name: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type LabelInput = {
  name: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type LabelMapping = {
  nameMapping: Maybe<Scalars['String']['output']>;
  valueMapping: Maybe<Array<ValueMapping>>;
};

export type LabelMatcher = {
  name: Scalars['String']['output'];
  type: LabelMatcherType;
  value: Scalars['String']['output'];
};

export enum LabelMatcherType {
  EXACT_MATCHER_TYPE = 'EXACT_MATCHER_TYPE',
  NOT_EXACT_MATCHER_TYPE = 'NOT_EXACT_MATCHER_TYPE',
  NOT_REGEXP_MATCHER_TYPE = 'NOT_REGEXP_MATCHER_TYPE',
  REGEXP_MATCHER_TYPE = 'REGEXP_MATCHER_TYPE',
}

export type LabelNames = {
  matcher: Scalars['String']['output'];
  /** @deprecated This array will only ever be of size 1. use matcher instead. */
  matchers: Array<Scalars['String']['output']>;
};

export type LabelPolicy = {
  discard: Maybe<Array<Scalars['String']['output']>>;
  keep: Maybe<Array<Scalars['String']['output']>>;
};

export type LabelValues = {
  labelName: Scalars['String']['output'];
  matcher: Scalars['String']['output'];
  /** @deprecated This array will only ever be of size 1. use matcher instead. */
  matchers: Array<Scalars['String']['output']>;
};

export type LabelValuesInput = {
  labelName?: InputMaybe<Scalars['String']['input']>;
  matcher: Scalars['String']['input'];
};

/** Lightweight way for customer to save a link to a homepage. */
export type Link = {
  name: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type LinkInput = {
  name: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export type LinkTemplate = {
  createdAt: Scalars['Time']['output'];
  name: Scalars['String']['output'];
  slug: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
  urlTemplate: Scalars['String']['output'];
};

export type ManagedBy = {
  editable: Scalars['Boolean']['output'];
  source: ManagedBySource;
};

export enum ManagedBySource {
  CHRONOCTL = 'CHRONOCTL',
  CHRONOSPHERE = 'CHRONOSPHERE',
  KUBERNETES = 'KUBERNETES',
  SYNC_PROMETHEUS = 'SYNC_PROMETHEUS',
  TERRAFORM = 'TERRAFORM',
  UI = 'UI',
  UNKNOWN = 'UNKNOWN',
}

export enum ManagedByType {
  CHRONOSPHERE = 'CHRONOSPHERE',
  TENANT = 'TENANT',
  UNKNOWN = 'UNKNOWN',
}

export type MappingRule = {
  aggregationPolicy: Maybe<MappingRuleAggregationPolicy>;
  config: ExportedConfig;
  createdAt: Scalars['String']['output'];
  drop: Scalars['Boolean']['output'];
  filters: Array<Label>;
  managedBy: ManagedBy;
  mode: MappingRuleMode;
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  source: EditSource;
  updatedAt: Scalars['String']['output'];
};

export type MappingRuleAggregationPolicy = {
  aggregation: AggregationType;
  dropTimestamp: Scalars['Boolean']['output'];
  interval: Maybe<Scalars['String']['output']>;
  storagePolicy: Maybe<StoragePolicy>;
};

export enum MappingRuleMode {
  ENABLED = 'ENABLED',
  PREVIEW = 'PREVIEW',
}

export enum Match {
  INVALID = 'INVALID',
  RULE = 'RULE',
}

export type MatchingMonitorRoutesAndConditions = {
  conditions: SeriesConditions;
  routes: NotificationRoutes;
  signal: Array<AlertRuleLabel>;
};

export type MetricDDXRequest = {
  addFilters?: InputMaybe<Array<InputMaybe<NewLabelMatcher>>>;
  endTime: Scalars['String']['input'];
  query: Scalars['String']['input'];
  startTime: Scalars['String']['input'];
};

export type MetricDDXResult = {
  allLabelNames: Maybe<Array<Scalars['String']['output']>>;
  queryByLabelName: Scalars['String']['output'];
  selectors: Maybe<Array<Scalars['String']['output']>>;
};

export type MetricDiscovery = {
  expr: Scalars['String']['output'];
  objectLabel: Scalars['String']['output'];
  objectMappingLabel: Scalars['String']['output'];
  objectMappingLabelTransform: Maybe<Transform>;
  sloMetadata: Maybe<Array<SLOMetadata>>;
  target: Maybe<MetricDiscoveryTarget>;
  uniqueLabel: Scalars['String']['output'];
};

export type MetricDiscoveryOverride = {
  objectLabel: Scalars['String']['output'];
  objectMappingLabel: Scalars['String']['output'];
  objectMappingLabelTransform: Maybe<Transform>;
  uniqueLabel: Scalars['String']['output'];
};

export type MetricDiscoveryOverrideInput = {
  objectLabel: Scalars['String']['input'];
  objectMappingLabel: Scalars['String']['input'];
  objectMappingLabelTransform?: InputMaybe<TransformInput>;
  uniqueLabel: Scalars['String']['input'];
};

export type MetricDiscoveryTarget = {
  filters: Array<DerivedLabelFilter>;
};

export type MetricDiscoveryTargetInput = {
  filters: Array<DerivedLabelFilterInput>;
};

export type MetricSettings = {
  retentionPolicies: Array<RetentionPolicy>;
};

export enum MetricType {
  COUNTER = 'COUNTER',
  CUMULATIVE_COUNTER = 'CUMULATIVE_COUNTER',
  CUMULATIVE_EXPONENTIAL_HISTOGRAM = 'CUMULATIVE_EXPONENTIAL_HISTOGRAM',
  DELTA = 'DELTA',
  DELTA_COUNTER = 'DELTA_COUNTER',
  DELTA_EXPONENTIAL_HISTOGRAM = 'DELTA_EXPONENTIAL_HISTOGRAM',
  DISTRIBUTION = 'DISTRIBUTION',
  GAUGE = 'GAUGE',
  MEASUREMENT = 'MEASUREMENT',
  METRIC_TYPE_INVALID = 'METRIC_TYPE_INVALID',
}

export type MetricsDiscoveryInput = {
  /** A Prom expression which generates results that directly or indirectly can enumerate services. */
  expr: Scalars['String']['input'];
  /** The name of a label in the results from the expression that directly enumerates service names. */
  objectLabel?: InputMaybe<Scalars['String']['input']>;
  /**
   * The name of a label in the results from the expression whose values can be used to map to a service name
   * that was discovered by the "global configuration".
   */
  objectMappingLabel?: InputMaybe<Scalars['String']['input']>;
  /**
   * An optional transformation that is applied to values of `objectMappingLabel` before the values are used to search
   * the global configuration for proper service names.
   */
  objectMappingLabelTransform?: InputMaybe<TransformInput>;
  /** Configures which subset of the expression results the discovery should apply to. */
  target: MetricDiscoveryTargetInput;
  /** The name of a label in the results from the expression whose values uniquely correspond to services. */
  uniqueLabel?: InputMaybe<Scalars['String']['input']>;
};

export type MigrateGrafanaDashboardInput = {
  nativeDashboardConfig: Scalars['JSONObject']['input'];
  /** The grafana dashboard slug. The native dashboard created will have the same slug. */
  slug: Scalars['String']['input'];
};

export type Monitor = {
  annotations: Maybe<Array<Annotation>>;
  /** Deprecated. Use collection */
  bucket: Maybe<Collection>;
  /** Collection that owns the monitor. */
  collection: Collection;
  collectionLinks: Maybe<Array<CollectionLink>>;
  config: ExportedConfig;
  createdAt: Scalars['String']['output'];
  exportFormat: ExportFormat;
  isFavorite: Scalars['Boolean']['output'];
  labels: Maybe<Array<Label>>;
  managedBy: ManagedBy;
  matchingRoutesAndConditions: Maybe<MatchingMonitorRoutesAndConditions>;
  name: Scalars['String']['output'];
  policy: Maybe<MonitorNotificationPolicy>;
  slug: Scalars['String']['output'];
  source: EditSource;
  spec: MonitorSpec;
  state: MonitorState;
  updatedAt: Scalars['String']['output'];
  versions: EntityVersionsConnection;
};

export type MonitorCondition = {
  op: ThresholdOp;
  resolveSustain: Scalars['String']['output'];
  sustain: Scalars['String']['output'];
  value: Scalars['Float']['output'];
};

export type MonitorInformation = {
  /** The specified severity. Valid options include: ['', 'FIRING', 'CRITICAL']. */
  severity: Scalars['String']['input'];
  /** The selected monitor signal if one is selected. */
  signal: Array<NewAlertRuleLabel>;
  /** The monitor slug. */
  slug: Scalars['String']['input'];
};

export type MonitorMuted = {
  mutingRulesObj: MutingRuleConnection;
  severity: ConditionLevel;
};

/**
 * MonitorNotificationPolicy is a connection between a monitor and the notification policy it uses.
 * Monitors may use either their collections' default policies, or explicitly reference a policy by slug.
 */
export type MonitorNotificationPolicy = {
  /**
   * Indicates if the monitor explicitly references the notification policy.
   * This is false when a monitor is using its collection's default policy.
   */
  explicitReference: Scalars['Boolean']['output'];
  policy: NotificationPolicy;
};

export type MonitorQuery = {
  queryDatasource: DatasourceType;
  queryExpression: Scalars['String']['output'];
};

export type MonitorResolvedAlertEvent = {
  severity: ConditionLevel;
};

export type MonitorResolvedNotificationFailedToSend = {
  error: Scalars['String']['output'];
  notifier: Notifier;
  severity: ConditionLevel;
};

export type MonitorResolvedNotificationSent = {
  notifier: Notifier;
  severity: ConditionLevel;
};

export type MonitorSeries = {
  status: AlertStatus;
};

export type MonitorSignal = {
  labels: Maybe<Array<Label>>;
  series: Maybe<Array<MonitorSeries>>;
  state: AlertState;
};

export type MonitorSignalConfig = {
  labels: Maybe<Array<Label>>;
  policyOverrideMatchers: Maybe<Array<AlertRouteMatcher>>;
  signalNotifiers: Maybe<Array<NotifiersBySeverity>>;
};

export type MonitorSpec = {
  interval: Scalars['String']['output'];
  query: MonitorQuery;
  schedule: Maybe<Schedule>;
  seriesConditions: SeriesConditions;
  signalGrouping: SignalGrouping;
};

export type MonitorState = {
  signals: Maybe<Array<MonitorSignal>>;
  state: AlertState;
};

export type MonitorStats = {
  numActive: Scalars['Int']['output'];
  numMuted: Scalars['Int']['output'];
  numPassing: Scalars['Int']['output'];
};

export type MonitorSummary = {
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
};

export type MonitorTriggeredAlertEvent = {
  severity: ConditionLevel;
};

export type MonitorTriggeredNotificationFailedToSend = {
  error: Scalars['String']['output'];
  notifier: Notifier;
  severity: ConditionLevel;
};

export type MonitorTriggeredNotificationSent = {
  notifier: Notifier;
  severity: ConditionLevel;
};

export type MonitorUnmuted = {
  mutingRulesObj: MutingRuleConnection;
  severity: ConditionLevel;
};

/** MonitorsConnection is a paginated container of a list of monitors. */
export type MonitorsConnection = {
  /** Monitors for this page. The pageInfo field contains metadata to fetch additional pages. */
  monitors: Array<Monitor>;
  /** Pagination metadata to fetch additional pages of data. */
  pageInfo: PageInfo;
  /**
   * Total count of monitors.
   * This count includes all monitors and therefore may be larger than the length of the monitors field.
   */
  totalCount: Scalars['Int']['output'];
};

export type MonitorsV2Filters = {
  /** Filter duplicate results. if true response times may be faster but some monitors may be returned multiple times. */
  allowDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
  collectionRefs?: InputMaybe<Array<CollectionRef>>;
  collectionSlugs?: InputMaybe<Array<Scalars['String']['input']>>;
  /** If set to true, filters to only monitors marked as favorite by the calling user. */
  favoritesOnly?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * If true, search for dashboards that are owned by the filtered collections as well as
   * those linked to the filtered collections.
   */
  includeLinkedCollections?: InputMaybe<Scalars['Boolean']['input']>;
  /** Specify which AlertStates to include in the response. If none are specified, all states are included. */
  includeStates?: InputMaybe<Array<AlertState>>;
  /** If set, only monitors whose name contains the specified string will be returned. */
  nameContains?: InputMaybe<Scalars['String']['input']>;
  /** If set, only monitors that use the provided notification policies will be returned. */
  notificationPolicySlugs?: InputMaybe<Array<Scalars['String']['input']>>;
  /** If set, only monitors that are in the customer namespace will be returned. */
  onlyCustomerManaged?: InputMaybe<Scalars['Boolean']['input']>;
  teamSlugs?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type Mutation = {
  bulkUpdateTeam: TeamBulkUpdate;
  bulkUpdateTeamsForUser: TeamsForUserBulkUpdate;
  createAlertNotificationPolicy: NotificationPolicy;
  createAlertReceiver: AlertReceiver;
  createCollection: Collection;
  /** creating a custom component */
  createCustomComponent: Maybe<CustomComponentOutput>;
  createDataset: Dataset;
  createDiscovery: ComponentDiscoveryConfig;
  createDropRule: DropRule;
  createIngestionPolicy: IngestionPolicy;
  createLinkTemplate: LinkTemplate;
  createMappingRule: MappingRule;
  createMonitor: Monitor;
  createMutingRule: MutingRule;
  createPersonalAccessToken: CreatePersonalAccessTokenResponse;
  createPresentation: ComponentPresentationConfig;
  createRecordingRule: RecordingRule;
  createRollupRule: RollupRule;
  createSLO: SLO;
  createSavedTraceSearch: SavedTraceSearch;
  createServiceAccount: CreateServiceAccountResponse;
  createShortenedMessage: ShortenedMessage;
  createSignupInvite: SignupInvite;
  createTeam: Team;
  createTraceBehavior: TraceBehavior;
  createTraceMetricsRule: TraceMetricsRule;
  /** Create record of the authenticated calling user having performed a given trace search */
  createTraceSearchHistory: TraceSearchHistory;
  /** Create record of a search performed by the authenticated caller user */
  createUserSearchHistory: UserSearchHistory;
  /**
   * Deleting a notification policy will fail if there are monitors that still use the policy either
   * by directly setting it or is used as the default policy of a collection
   */
  deleteAlertNotificationPolicy: Scalars['Boolean']['output'];
  deleteAlertReceiver: Scalars['Boolean']['output'];
  deleteCollection: Scalars['Boolean']['output'];
  deleteCollectionHomepage: Collection;
  /** Deletes a collection link. */
  deleteCollectionLinks: Scalars['Boolean']['output'];
  /** delete custom component */
  deleteCustomComponent: Scalars['Boolean']['output'];
  deleteDashboard: Scalars['Boolean']['output'];
  deleteDiscovery: Scalars['Boolean']['output'];
  deleteDropRule: Scalars['Boolean']['output'];
  /** Delete a dashboard by slug. This operation is not reversible. */
  deleteGrafanaDashboard: Scalars['Boolean']['output'];
  deleteIngestionPolicy: Scalars['Boolean']['output'];
  deleteLinkTemplate: Scalars['Boolean']['output'];
  deleteMappingRule: Scalars['Boolean']['output'];
  deleteMonitor: Scalars['Boolean']['output'];
  deletePersonalAccessTokens: Scalars['Boolean']['output'];
  deletePresentation: Scalars['Boolean']['output'];
  deleteRecordingRule: Scalars['Boolean']['output'];
  deleteRollupRule: Scalars['Boolean']['output'];
  deleteSLO: Scalars['Boolean']['output'];
  deleteSavedTraceSearch: Scalars['Boolean']['output'];
  deleteServiceAccount: Scalars['Boolean']['output'];
  deleteServiceAccounts: Scalars['Boolean']['output'];
  deleteTeam: Scalars['Boolean']['output'];
  deleteTeamHomepage: Team;
  deleteTraceBehavior: Scalars['Boolean']['output'];
  deleteTraceMetricsRule: Scalars['Boolean']['output'];
  /**
   * Delete a user. Used for cleaning up test environments.
   *
   * User must be deactivated before they can be deleted.
   * Set forceDeactivation to true in order to deactivate then delete the user.
   */
  deleteUser: Scalars['Boolean']['output'];
  /** Delete the record of a search history */
  deleteUserSearchHistory: Scalars['Boolean']['output'];
  /**
   * Import a dashboard, including its settings and json blob. We consider the settings
   * source of truth for dashboard name and labels, so if those differ from the json blob,
   * they will be overridden.
   */
  importGrafanaDashboard: GrafanaDashboard;
  /**
   * Migrate a grafana dashboard to a native dashboard.
   *
   * The native dashboard will be placed in the same collection and share the same slug as the grafana dashboard.
   *
   * Once a dashboard is migrated, only the native dashboard will be returned from the "allDashboards" list query.
   */
  migrateGrafanaDashboard: Dashboard;
  /** Update alert receiver is now deprecated in favor of patch */
  patchAlertReceiver: AlertReceiver;
  resendSignupInvite: Scalars['Boolean']['output'];
  revokeAllPersonalAccessTokens: Scalars['Boolean']['output'];
  /** run the discovery pipeline */
  runServiceDiscovery: Maybe<ServiceDiscoveryOutput>;
  saveCollectionHomepage: Collection;
  /** Set the home page dashboard for a collection */
  saveDashboard: Dashboard;
  /**
   * Save dashboard settings. If slug is not set or is set but dashboard does not exist,
   * this creates a new dashboard, (including a blank one in grafana) which generates a new slug.
   *
   * If the slug is set, then this is an upsert to the dashboard. Name or label changes will
   * update the grafana dashboard's title & tags respectively.
   */
  saveGrafanaDashboardSettings: GrafanaDashboard;
  saveTeamHomepage: Team;
  /** @deprecated Use setUITheme instead. */
  saveUserSettings: Scalars['Boolean']['output'];
  /** @deprecated Use setUITheme instead. */
  setDarkMode: Scalars['Boolean']['output'];
  setUITheme: Scalars['Boolean']['output'];
  setUserSettingsValue: Scalars['Boolean']['output'];
  submitChangeRequest: Scalars['Boolean']['output'];
  updateAlertNotificationPolicy: NotificationPolicy;
  updateCollection: Collection;
  /** updating a custom component */
  updateCustomComponent: Maybe<CustomComponentOutput>;
  updateDataset: Dataset;
  updateDiscovery: ComponentDiscoveryConfig;
  /** update the discovery overrides and merge rules for a tenant */
  updateDiscoveryConfigs: Scalars['Boolean']['output'];
  updateDropRule: DropRule;
  updateFavorite: Scalars['Boolean']['output'];
  /** update the global filters for a tenant */
  updateGlobalFilters: Array<Template>;
  updateIngestionPolicy: IngestionPolicy;
  updateLinkTemplate: LinkTemplate;
  updateMappingRule: MappingRule;
  updateMonitor: Monitor;
  /**
   * updateMutingRule restricts updates to only mutated:
   *   1. Name
   *   2. Comment
   *   3. EndsAt
   */
  updateMutingRule: MutingRule;
  updatePresentation: ComponentPresentationConfig;
  updatePresentationConfigs: Scalars['Boolean']['output'];
  updateRecordingRule: RecordingRule;
  updateRollupRule: RollupRule;
  updateSLO: SLO;
  updateTeam: Team;
  updateTraceBehavior: TraceBehavior;
  updateTraceBehaviorConfig: TraceBehaviorConfig;
  updateTraceMetricsRule: TraceMetricsRule;
  updateTraceTopTags: TraceTopTags;
  /** Update a search history by search history ID */
  updateUserSearchHistory: UserSearchHistory;
  updatedSavedTraceSearch: SavedTraceSearch;
  /** Creates or updates a list of collection links. */
  upsertCollectionLinks: Array<CollectionLink>;
};

export type MutationbulkUpdateTeamArgs = {
  input: BulkUpdateTeam;
};

export type MutationbulkUpdateTeamsForUserArgs = {
  input: BulkUpdateTeamsForUser;
};

export type MutationcreateAlertNotificationPolicyArgs = {
  input: InputMaybe<NewAlertNotificationPolicy>;
};

export type MutationcreateAlertReceiverArgs = {
  input: NewAlertReceiver;
};

export type MutationcreateCollectionArgs = {
  input: CreateCollection;
};

export type MutationcreateCustomComponentArgs = {
  input: CreateCustomComponentInput;
};

export type MutationcreateDatasetArgs = {
  input: CreateDatasetInput;
};

export type MutationcreateDiscoveryArgs = {
  input: CreateDiscoveryInput;
};

export type MutationcreateDropRuleArgs = {
  input: NewDropRule;
};

export type MutationcreateIngestionPolicyArgs = {
  input: NewIngestionPolicy;
};

export type MutationcreateLinkTemplateArgs = {
  input: CreateLinkTemplateInput;
};

export type MutationcreateMappingRuleArgs = {
  input: NewMappingRule;
};

export type MutationcreateMonitorArgs = {
  input: InputMaybe<NewMonitor>;
};

export type MutationcreateMutingRuleArgs = {
  input: NewMutingRule;
};

export type MutationcreatePersonalAccessTokenArgs = {
  input: CreatePersonalAccessTokenInput;
};

export type MutationcreatePresentationArgs = {
  input: CreatePresentationInput;
};

export type MutationcreateRecordingRuleArgs = {
  input: NewRecordingRule;
};

export type MutationcreateRollupRuleArgs = {
  input: NewRollupRule;
};

export type MutationcreateSLOArgs = {
  input: UpdateSLOInput;
};

export type MutationcreateSavedTraceSearchArgs = {
  input: CreateSavedTraceSearchInput;
};

export type MutationcreateServiceAccountArgs = {
  input: CreateServiceAccountInput;
};

export type MutationcreateShortenedMessageArgs = {
  input: ShortenMessageRequest;
};

export type MutationcreateSignupInviteArgs = {
  input: CreateSignupInviteInput;
};

export type MutationcreateTeamArgs = {
  input: NewTeam;
};

export type MutationcreateTraceBehaviorArgs = {
  input: CreateTraceBehaviorInput;
};

export type MutationcreateTraceMetricsRuleArgs = {
  input: CreateTraceMetricsRuleInput;
};

export type MutationcreateTraceSearchHistoryArgs = {
  input: CreateTraceSearchHistoryInput;
};

export type MutationcreateUserSearchHistoryArgs = {
  input: CreateSearchHistoryInput;
};

export type MutationdeleteAlertNotificationPolicyArgs = {
  input: InputMaybe<DeleteAlertNotificationPolicy>;
};

export type MutationdeleteAlertReceiverArgs = {
  input: DeleteAlertReceiver;
};

export type MutationdeleteCollectionArgs = {
  input: DeleteCollection;
};

export type MutationdeleteCollectionHomepageArgs = {
  input: DeleteCollectionHomepageInput;
};

export type MutationdeleteCollectionLinksArgs = {
  input: DeleteCollectionLinksInput;
};

export type MutationdeleteCustomComponentArgs = {
  input: DeleteCustomComponentInput;
};

export type MutationdeleteDashboardArgs = {
  input: DeleteDashboard;
};

export type MutationdeleteDiscoveryArgs = {
  input: DeleteDiscoveryInput;
};

export type MutationdeleteDropRuleArgs = {
  input: DeleteDropRule;
};

export type MutationdeleteGrafanaDashboardArgs = {
  input: DeleteGrafanaDashboard;
};

export type MutationdeleteIngestionPolicyArgs = {
  input: DeleteIngestionPolicy;
};

export type MutationdeleteLinkTemplateArgs = {
  input: DeleteLinkTemplateInput;
};

export type MutationdeleteMappingRuleArgs = {
  input: DeleteMappingRule;
};

export type MutationdeleteMonitorArgs = {
  input: InputMaybe<DeleteMonitor>;
};

export type MutationdeletePersonalAccessTokensArgs = {
  input: DeletePersonalAccessTokensInput;
};

export type MutationdeletePresentationArgs = {
  input: DeletePresentationInput;
};

export type MutationdeleteRecordingRuleArgs = {
  input: DeleteRecordingRule;
};

export type MutationdeleteRollupRuleArgs = {
  input: DeleteRollupRule;
};

export type MutationdeleteSLOArgs = {
  input: DeleteSLOInput;
};

export type MutationdeleteSavedTraceSearchArgs = {
  Input: DeleteSavedTraceSearchInput;
};

export type MutationdeleteServiceAccountArgs = {
  input: DeleteServiceAccountInput;
};

export type MutationdeleteServiceAccountsArgs = {
  input: DeleteServiceAccountsInput;
};

export type MutationdeleteTeamArgs = {
  input: DeleteTeam;
};

export type MutationdeleteTeamHomepageArgs = {
  input: DeleteTeamHomepageInput;
};

export type MutationdeleteTraceBehaviorArgs = {
  input: DeleteTraceBehaviorInput;
};

export type MutationdeleteTraceMetricsRuleArgs = {
  input: DeleteTraceMetricsRuleInput;
};

export type MutationdeleteUserArgs = {
  input: DeleteUserInput;
};

export type MutationdeleteUserSearchHistoryArgs = {
  input: DeleteSearchHistoryInput;
};

export type MutationimportGrafanaDashboardArgs = {
  input: ImportGrafanaDashboard;
};

export type MutationmigrateGrafanaDashboardArgs = {
  input: MigrateGrafanaDashboardInput;
};

export type MutationpatchAlertReceiverArgs = {
  input: UpdateAlertReceiver;
};

export type MutationresendSignupInviteArgs = {
  input: ResendSignupInviteInput;
};

export type MutationrunServiceDiscoveryArgs = {
  debugLogFilter: InputMaybe<DebugLogFilter>;
};

export type MutationsaveCollectionHomepageArgs = {
  input: SaveCollectionHomepageInput;
};

export type MutationsaveDashboardArgs = {
  input: SaveDashboardInput;
};

export type MutationsaveGrafanaDashboardSettingsArgs = {
  input: GrafanaDashboardSettings;
};

export type MutationsaveTeamHomepageArgs = {
  input: SaveTeamHomepageInput;
};

export type MutationsaveUserSettingsArgs = {
  input: UserSettingsInput;
};

export type MutationsetDarkModeArgs = {
  input: DarkModeInput;
};

export type MutationsetUIThemeArgs = {
  input: UIThemeInput;
};

export type MutationsetUserSettingsValueArgs = {
  input: UserSettingsValueInput;
};

export type MutationsubmitChangeRequestArgs = {
  input: SubmitChangeRequestInput;
};

export type MutationupdateAlertNotificationPolicyArgs = {
  input: InputMaybe<NewAlertNotificationPolicy>;
};

export type MutationupdateCollectionArgs = {
  input: UpdateCollection;
};

export type MutationupdateCustomComponentArgs = {
  input: UpdateCustomComponentInput;
};

export type MutationupdateDatasetArgs = {
  input: UpdateDatasetInput;
};

export type MutationupdateDiscoveryArgs = {
  input: UpdateDiscoveryInput;
};

export type MutationupdateDiscoveryConfigsArgs = {
  input: DiscoveryConfigsInput;
};

export type MutationupdateDropRuleArgs = {
  input: UpdateDropRule;
};

export type MutationupdateFavoriteArgs = {
  input: UpdateFavoriteInput;
};

export type MutationupdateGlobalFiltersArgs = {
  input: UpdateGlobalFiltersInput;
};

export type MutationupdateIngestionPolicyArgs = {
  input: UpdateIngestionPolicy;
};

export type MutationupdateLinkTemplateArgs = {
  input: UpdateLinkTemplateInput;
};

export type MutationupdateMappingRuleArgs = {
  input: UpdateMappingRule;
};

export type MutationupdateMonitorArgs = {
  input: InputMaybe<UpdatedMonitor>;
};

export type MutationupdateMutingRuleArgs = {
  input: NewMutingRule;
};

export type MutationupdatePresentationArgs = {
  input: UpdatePresentationInput;
};

export type MutationupdatePresentationConfigsArgs = {
  input: PresentationConfigsInput;
};

export type MutationupdateRecordingRuleArgs = {
  input: UpdateRecordingRule;
};

export type MutationupdateRollupRuleArgs = {
  input: UpdateRollupRule;
};

export type MutationupdateSLOArgs = {
  input: UpdateSLOInput;
};

export type MutationupdateTeamArgs = {
  input: UpdateTeam;
};

export type MutationupdateTraceBehaviorArgs = {
  input: UpdateTraceBehaviorInput;
};

export type MutationupdateTraceBehaviorConfigArgs = {
  input: UpdateTraceBehaviorConfigInput;
};

export type MutationupdateTraceMetricsRuleArgs = {
  input: UpdateTraceMetricsRuleInput;
};

export type MutationupdateTraceTopTagsArgs = {
  input: UpdateTraceTopTagsInput;
};

export type MutationupdateUserSearchHistoryArgs = {
  id: Scalars['String']['input'];
  input: UpdateSearchHistoryInput;
};

export type MutationupdatedSavedTraceSearchArgs = {
  input: UpdateSavedTraceSearchInput;
};

export type MutationupsertCollectionLinksArgs = {
  input: UpsertCollectionLinksInput;
};

export type MutingRule = {
  comment: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  endsAt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  matchers: Array<LabelMatcher>;
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  startsAt: Scalars['String']['output'];
  state: MutingRuleState;
  updatedAt: Scalars['String']['output'];
  updatedBy: Scalars['String']['output'];
};

export enum MutingRuleColumn {
  ENDS_AT = 'ENDS_AT',
  STARTS_AT = 'STARTS_AT',
}

/** MutingRuleConnection is a paginated container of a list of muting rules. */
export type MutingRuleConnection = {
  mutingRules: Array<MutingRule>;
  /** Pagination metadata to fetch additional pages of data. */
  pageInfo: PageInfo;
  /** Total count of muting rules. */
  totalCount: Scalars['Int']['output'];
};

export type MutingRuleOrdering = {
  column: MutingRuleColumn;
  order: Ordering;
};

export type MutingRulePageFilters = {
  /**
   * Specifies which states (pending, active, expired) muting rules to include in the response.
   * If none are specfied, all states are included in the response.
   */
  includeStates: Array<MutingRuleState>;
  /** If set, only muting rules whose name contains the specified string will be returned. */
  nameContains?: InputMaybe<Scalars['String']['input']>;
  /** Specifies the order in which muting rules should be sorted in. Only one ordering column is supported. */
  order: Array<MutingRuleOrdering>;
};

export enum MutingRuleState {
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED',
  PENDING = 'PENDING',
}

/**
 * MutingRulesForMonitor returns all the active and expired muting rules that are applicable
 * to a monitor. If there are any active muting rules, exipred muting rules are ommited from
 * the response.
 */
export type MutingRulesForMonitor = {
  activeMutingRules: Array<MutingRule>;
  expiredMutingRules: Array<MutingRule>;
};

export type NewAlertNotificationPolicy = {
  name?: InputMaybe<Scalars['String']['input']>;
  routes?: InputMaybe<NewNotificationRoutes>;
  rules?: InputMaybe<Array<NewNotificationRule>>;
  slug?: InputMaybe<Scalars['String']['input']>;
  teamSlug?: InputMaybe<Scalars['String']['input']>;
};

export type NewAlertReceiver = {
  email?: InputMaybe<Array<NewAlertReceiverEmailConfig>>;
  name: Scalars['String']['input'];
  opsgenie?: InputMaybe<Array<NewAlertReceiverOpsGenieConfig>>;
  pagerduty?: InputMaybe<Array<NewAlertReceiverPagerdutyConfig>>;
  slack?: InputMaybe<Array<NewAlertReceiverSlackConfig>>;
  victorOps?: InputMaybe<Array<NewAlertReceiverVictorOpsConfig>>;
  webhook?: InputMaybe<Array<NewAlertReceiverWebhookConfig>>;
};

export type NewAlertReceiverEmailConfig = {
  baseConfig?: InputMaybe<NewAlertReceiverNotifierBaseConfig>;
  html?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  to: Scalars['String']['input'];
};

export type NewAlertReceiverHTTPConfig = {
  basicAuth?: InputMaybe<NewAlertReceiverHTTPConfigBasicAuth>;
  bearerToken?: InputMaybe<Scalars['String']['input']>;
  proxyURL?: InputMaybe<Scalars['String']['input']>;
  tlsConfig?: InputMaybe<NewAlertReceiverHTTPConfigTLSConfig>;
};

export type NewAlertReceiverHTTPConfigBasicAuth = {
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type NewAlertReceiverHTTPConfigTLSConfig = {
  insecureSkipVerify: Scalars['Boolean']['input'];
};

export type NewAlertReceiverNotifierBaseConfig = {
  sendResolved: Scalars['Boolean']['input'];
};

export type NewAlertReceiverOpsGenieConfig = {
  apiKey: Scalars['String']['input'];
  apiURL: Scalars['String']['input'];
  baseConfig?: InputMaybe<NewAlertReceiverNotifierBaseConfig>;
  description?: InputMaybe<Scalars['String']['input']>;
  details?: InputMaybe<Scalars['String']['input']>;
  httpConfig?: InputMaybe<NewAlertReceiverHTTPConfig>;
  message?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<Scalars['String']['input']>;
  responders?: InputMaybe<Array<NewAlertReceiverOpsGenieConfigResponder>>;
  source?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Scalars['String']['input']>;
};

export type NewAlertReceiverOpsGenieConfigResponder = {
  responderType: ResponderType;
  responderValue: Scalars['String']['input'];
  responseType: ResponseType;
};

export type NewAlertReceiverPagerdutyConfig = {
  baseConfig?: InputMaybe<NewAlertReceiverNotifierBaseConfig>;
  class?: InputMaybe<Scalars['String']['input']>;
  client?: InputMaybe<Scalars['String']['input']>;
  clientURL?: InputMaybe<Scalars['String']['input']>;
  component?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  details?: InputMaybe<Scalars['String']['input']>;
  group?: InputMaybe<Scalars['String']['input']>;
  httpConfig?: InputMaybe<NewAlertReceiverHTTPConfig>;
  images?: InputMaybe<Array<NewAlertReceiverPagerdutyConfigImage>>;
  links?: InputMaybe<Array<NewAlertReceiverPagerdutyConfigLink>>;
  serviceKey?: InputMaybe<Scalars['String']['input']>;
  severity?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type NewAlertReceiverPagerdutyConfigImage = {
  alt: Scalars['String']['input'];
  href: Scalars['String']['input'];
  src: Scalars['String']['input'];
};

export type NewAlertReceiverPagerdutyConfigLink = {
  href: Scalars['String']['input'];
  text: Scalars['String']['input'];
};

export type NewAlertReceiverSlackConfig = {
  actions?: InputMaybe<Array<NewAlertReceiverSlackConfigAction>>;
  apiURL?: InputMaybe<Scalars['String']['input']>;
  baseConfig?: InputMaybe<NewAlertReceiverNotifierBaseConfig>;
  callbackID?: InputMaybe<Scalars['String']['input']>;
  channel: Scalars['String']['input'];
  color?: InputMaybe<Scalars['String']['input']>;
  fallback?: InputMaybe<Scalars['String']['input']>;
  fields?: InputMaybe<Array<NewAlertReceiverSlackConfigField>>;
  footer?: InputMaybe<Scalars['String']['input']>;
  httpConfig?: InputMaybe<NewAlertReceiverHTTPConfig>;
  iconEmoji?: InputMaybe<Scalars['String']['input']>;
  iconURL?: InputMaybe<Scalars['String']['input']>;
  imageURL?: InputMaybe<Scalars['String']['input']>;
  linkNames?: InputMaybe<Scalars['Boolean']['input']>;
  mrkdwnIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  pretext?: InputMaybe<Scalars['String']['input']>;
  shortFields?: InputMaybe<Scalars['Boolean']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  thumbURL?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  titleLink?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type NewAlertReceiverSlackConfigAction = {
  confirm?: InputMaybe<NewAlertReceiverSlackConfigConfirmField>;
  name: Scalars['String']['input'];
  style?: InputMaybe<Scalars['String']['input']>;
  text: Scalars['String']['input'];
  type: Scalars['String']['input'];
  url: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type NewAlertReceiverSlackConfigConfirmField = {
  dismissText: Scalars['String']['input'];
  okText: Scalars['String']['input'];
  text: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type NewAlertReceiverSlackConfigField = {
  short: Scalars['Boolean']['input'];
  title: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type NewAlertReceiverVictorOpsConfig = {
  apiKey: Scalars['String']['input'];
  apiURL: Scalars['String']['input'];
  baseConfig?: InputMaybe<NewAlertReceiverNotifierBaseConfig>;
  customFields?: InputMaybe<Scalars['String']['input']>;
  entityDisplayName?: InputMaybe<Scalars['String']['input']>;
  httpConfig?: InputMaybe<NewAlertReceiverHTTPConfig>;
  messageType?: InputMaybe<Scalars['String']['input']>;
  monitoringTool?: InputMaybe<Scalars['String']['input']>;
  routingKey?: InputMaybe<Scalars['String']['input']>;
  stateMessage?: InputMaybe<Scalars['String']['input']>;
};

export type NewAlertReceiverWebhookConfig = {
  baseConfig?: InputMaybe<NewAlertReceiverNotifierBaseConfig>;
  httpConfig?: InputMaybe<NewAlertReceiverHTTPConfig>;
  url: Scalars['String']['input'];
};

export type NewAlertRouteMatcher = {
  name: Scalars['String']['input'];
  type: AlertRouteMatcherType;
  value: Scalars['String']['input'];
};

export type NewAlertRuleAnnotation = {
  name: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type NewAlertRuleLabel = {
  name: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type NewConditionOverride = {
  conditions: Array<NewSeverityCondition>;
  matchers?: InputMaybe<Array<NewAlertRouteMatcher>>;
};

export type NewDefaultResourcePool = {
  /** @deprecated DefaultPool allocation is deprecated and no longer must be specified. When unspecified, allocation is derived as the remaining amount from the sum across pools. */
  allocation?: InputMaybe<NewResourcePoolAllocation>;
  priorities?: InputMaybe<NewResourcePoolHighLowPriorities>;
};

export type NewDropRule = {
  conditionalRateBasedDrop?: InputMaybe<InputDropRuleConditionalRateBasedDrop>;
  dropNanValue?: InputMaybe<Scalars['Boolean']['input']>;
  filters: Array<LabelFilter>;
  mode: DropRuleMode;
  name: Scalars['String']['input'];
  slug?: InputMaybe<Scalars['String']['input']>;
  valueBasedDrop?: InputMaybe<InputDropRuleValueBasedDrop>;
};

export type NewIngestionPolicy = {
  active: Scalars['Boolean']['input'];
  conditionalDrop?: InputMaybe<Scalars['Boolean']['input']>;
  dropDuration?: InputMaybe<Scalars['String']['input']>;
  dropDurationMins?: InputMaybe<Scalars['Int']['input']>;
  dropIndex: Scalars['Float']['input'];
  dropNanValue?: InputMaybe<Scalars['Boolean']['input']>;
  dropValue?: InputMaybe<Scalars['Float']['input']>;
  isValueBasedDrop?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  query?: InputMaybe<Array<Scalars['String']['input']>>;
  rateLimitThreshold?: InputMaybe<Scalars['Float']['input']>;
  /** Deprecated, use name instead. */
  title: Scalars['String']['input'];
};

export type NewLabelMatcher = {
  name: Scalars['String']['input'];
  type: LabelMatcherType;
  value: Scalars['String']['input'];
};

export type NewMappingRule = {
  aggregationPolicy?: InputMaybe<InputMappingRuleAggregationPolicy>;
  drop: Scalars['Boolean']['input'];
  filters: Array<LabelFilter>;
  mode: MappingRuleMode;
  name: Scalars['String']['input'];
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type NewMonitor = {
  annotations: Array<NewAlertRuleAnnotation>;
  collectionSlug?: InputMaybe<Scalars['String']['input']>;
  collectionType?: InputMaybe<CollectionType>;
  labels: Array<NewAlertRuleLabel>;
  name: Scalars['String']['input'];
  slug?: InputMaybe<Scalars['String']['input']>;
  spec: NewMonitorSpec;
};

export type NewMonitorCondition = {
  op: ThresholdOp;
  resolveSustain?: InputMaybe<Scalars['String']['input']>;
  sustain: Scalars['String']['input'];
  value: Scalars['Float']['input'];
};

export type NewMonitorQuery = {
  queryDatasource: DatasourceType;
  queryExpression: Scalars['String']['input'];
};

export type NewMonitorSpec = {
  interval?: InputMaybe<Scalars['String']['input']>;
  notificationPolicySlug?: InputMaybe<Scalars['String']['input']>;
  query: NewMonitorQuery;
  schedule?: InputMaybe<NewSchedule>;
  seriesConditions: NewSeriesConditions;
  signalGrouping: NewSignalGrouping;
};

export type NewMutingRule = {
  comment?: InputMaybe<Scalars['String']['input']>;
  /**
   * EndsAt is either a RFC3339 formatted string or `now`. In the latter case the current time is used
   * and the muting rule is immediately expired.
   */
  endsAt: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  matchers: Array<NewLabelMatcher>;
  name?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  /** StarsAt is a RFC3339 formatted string. */
  startsAt: Scalars['String']['input'];
};

export type NewNotificationRoutes = {
  defaults: Array<NewNotifiersBySeverity>;
  overrides: Array<NewNotificationRoutesOverride>;
};

export type NewNotificationRoutesOverride = {
  matchers: Array<NewAlertRouteMatcher>;
  notifiers: Array<NewNotifiersBySeverity>;
};

export type NewNotificationRule = {
  name: Scalars['String']['input'];
  /** A list of receiver names. */
  receivers: Array<Scalars['String']['input']>;
};

export type NewNotifier = {
  name: Scalars['String']['input'];
  slug: Scalars['String']['input'];
};

export type NewNotifiersBySeverity = {
  groupBy?: InputMaybe<Array<Scalars['String']['input']>>;
  notifierList: Array<NewNotifier>;
  repeatInterval?: InputMaybe<Scalars['String']['input']>;
  severity: Scalars['String']['input'];
};

export type NewRecordingRule = {
  executionGroup?: InputMaybe<Scalars['String']['input']>;
  expr: Scalars['String']['input'];
  interval: Scalars['String']['input'];
  labels: Array<NewRecordingRuleLabel>;
  name: Scalars['String']['input'];
};

export type NewRecordingRuleLabel = {
  name: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type NewResourcePool = {
  allocation: NewResourcePoolAllocation;
  matchRules?: InputMaybe<Array<LabelFilter>>;
  matchType: Match;
  name: Scalars['String']['input'];
  priorities?: InputMaybe<NewResourcePoolHighLowPriorities>;
};

export type NewResourcePoolAllocation = {
  fixedValues?: InputMaybe<Array<NewResourcePoolAllocationFixedValue>>;
  percentOfLicense?: InputMaybe<Scalars['Float']['input']>;
};

export type NewResourcePoolAllocationFixedValue = {
  license: ResourcePoolLicense;
  value: Scalars['Int']['input'];
};

export type NewResourcePoolHighLowPriorities = {
  highPriorityMatchRules?: InputMaybe<Array<LabelFilter>>;
  lowPriorityMatchRules?: InputMaybe<Array<LabelFilter>>;
};

export type NewResourcePools = {
  defaultPool?: InputMaybe<NewDefaultResourcePool>;
  pools?: InputMaybe<Array<NewResourcePool>>;
};

export type NewRollupRule = {
  addMetricTypeLabel: Scalars['Boolean']['input'];
  aggregationType: AggregationType;
  dropRaw: Scalars['Boolean']['input'];
  expansiveMatch: Scalars['Boolean']['input'];
  filters: Array<LabelFilter>;
  graphiteLabelPolicy?: InputMaybe<InputGraphiteLabelPolicy>;
  interval?: InputMaybe<Scalars['String']['input']>;
  labelPolicy?: InputMaybe<InputLabelPolicy>;
  labelReplace?: InputMaybe<Array<InputRollupRuleLabelReplace>>;
  metricName: Scalars['String']['input'];
  metricType: MetricType;
  mode: RollupRuleMode;
  name: Scalars['String']['input'];
  slug?: InputMaybe<Scalars['String']['input']>;
  storagePolicy?: InputMaybe<InputStoragePolicy>;
};

export type NewSchedule = {
  timezone: Scalars['String']['input'];
  /**
   * Keyed by time.Weekday index in array (sunday -> saturday).
   * Entire field can be null to indicate no available ranges.
   * Individual days of the week can be null to indicate no ranges on that day.
   */
  weeklySchedule?: InputMaybe<Array<InputMaybe<NewScheduleDay>>>;
};

export type NewScheduleDay = {
  ranges: Array<NewTimeRange>;
};

export type NewSearchHistory = {
  name?: InputMaybe<Scalars['String']['input']>;
  saved?: InputMaybe<Scalars['Boolean']['input']>;
  search: SearchHistoryQueryInput;
  type: SearchType;
};

export type NewSeriesConditions = {
  defaults: Array<NewSeverityCondition>;
  overrides: Array<NewConditionOverride>;
};

export type NewSeverityCondition = {
  conditionLevel: ConditionLevel;
  conditions: Array<NewMonitorCondition>;
};

export type NewSignalGrouping = {
  labelNames: Array<Scalars['String']['input']>;
  signalPerSeries: Scalars['Boolean']['input'];
};

export type NewTeam = {
  addRoles?: InputMaybe<Array<Scalars['String']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  /** Deprecated. Use `roles` instead. Only one of `roles` and `isSysAdmin` may be set. */
  isSysAdmin?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
};

export type NewTimeRange = {
  end: Scalars['String']['input'];
  start: Scalars['String']['input'];
};

/**
 * Queries for notification policies that are in-use by a set of monitors. The filters specify the set of monitors, and the
 * unique ordered notification policies used by those monitors are returned.
 * Repeated fields are ORed together and multiple fields are ANDed.
 */
export type NotificationPoliciesInUseFilters = {
  /** Get policies of monitors within any of the given collections. */
  collectionSlugs?: InputMaybe<Array<Scalars['String']['input']>>;
  /**  Get policies of monitors by specific monitor slugs. */
  slugs?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Get policies used by a monitor within any collection of the given teams. */
  teamSlugs?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type NotificationPoliciesPageFilters = {
  nameContains?: InputMaybe<Scalars['String']['input']>;
  onlyCustomerManaged?: InputMaybe<Scalars['Boolean']['input']>;
  teamSlugs?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type NotificationPolicy = {
  createdAt: Scalars['String']['output'];
  managedBy: ManagedBy;
  name: Scalars['String']['output'];
  routes: NotificationRoutes;
  rules: Array<NotificationRule>;
  slug: Scalars['String']['output'];
  source: EditSource;
  team: Maybe<Team>;
  /** Team that owns the policy. If null then policy is owned by a legacy collection. */
  teamSlug: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['String']['output'];
};

/** NotificationPolicyConnection is a paginated container of a list of notification policies. */
export type NotificationPolicyConnection = {
  /** NPs for this page. The pageInfo field contains metadata to fetch additional pages. */
  notificationPolicies: Array<NotificationPolicy>;
  /** Pagination metadata to fetch additional pages of data. */
  pageInfo: PageInfo;
  /**
   * Total count of nps.
   * This count includes all nps and therefore may be larger than the length of the monitors field.
   */
  totalCount: Scalars['Int']['output'];
};

export type NotificationRoutes = {
  defaults: Array<NotifiersBySeverity>;
  overrides: Array<NotificationRoutesOverride>;
};

export type NotificationRoutesOverride = {
  matchers: Array<AlertRouteMatcher>;
  notifiers: Array<NotifiersBySeverity>;
};

export type NotificationRule = {
  name: Scalars['String']['output'];
  receivers: Array<AlertReceiver>;
};

export type Notifier = {
  name: Scalars['String']['output'];
  notifierConfig: AlertReceiver;
  slug: Scalars['String']['output'];
};

export type NotifiersBySeverity = {
  groupBy: Maybe<Array<Scalars['String']['output']>>;
  notifierList: Array<Notifier>;
  repeatInterval: Scalars['String']['output'];
  severity: Scalars['String']['output'];
};

export type NumericFilter = {
  comparison: NumericFilterComparisonType;
  value: Scalars['Float']['output'];
};

export enum NumericFilterComparisonType {
  EQUAL = 'EQUAL',
  GREATER_THAN = 'GREATER_THAN',
  GREATER_THAN_OR_EQUAL = 'GREATER_THAN_OR_EQUAL',
  LESS_THAN = 'LESS_THAN',
  LESS_THAN_OR_EQUAL = 'LESS_THAN_OR_EQUAL',
  NOT_EQUAL = 'NOT_EQUAL',
  UNKNOWN = 'UNKNOWN',
}

export type NumericFilterInput = {
  comparison: NumericFilterComparisonType;
  value: Scalars['Float']['input'];
};

export type ObjectDiscoveryMetricRule = {
  effectiveExpr: Scalars['String']['output'];
  expr: Scalars['String']['output'];
  objectLabels: Array<Scalars['String']['output']>;
  objectMappingLabel: Scalars['String']['output'];
};

export type ObjectDiscoveryRule = {
  metric: Maybe<ObjectDiscoveryMetricRule>;
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
};

export type ObjectMergeRule = {
  action: ObjectMergeRuleAction;
  description: Scalars['String']['output'];
  regex: Maybe<Scalars['String']['output']>;
  replacement: Maybe<Scalars['String']['output']>;
  separator: Maybe<Scalars['String']['output']>;
  sourceLabels: Maybe<Array<Scalars['String']['output']>>;
  status: ObjectMergeRuleStatus;
  suppressionRule: Maybe<SuppressionMergeRule>;
};

export enum ObjectMergeRuleAction {
  DROP = 'DROP',
  KEEP = 'KEEP',
  REPLACE_KEY = 'REPLACE_KEY',
  SUPPRESS = 'SUPPRESS',
  UNKNOWN = 'UNKNOWN',
}

export type ObjectMergeRuleInput = {
  action: ObjectMergeRuleAction;
  description: Scalars['String']['input'];
  regex?: InputMaybe<Scalars['String']['input']>;
  replacement?: InputMaybe<Scalars['String']['input']>;
  separator?: InputMaybe<Scalars['String']['input']>;
  sourceLabels?: InputMaybe<Array<Scalars['String']['input']>>;
  status?: InputMaybe<ObjectMergeRuleStatus>;
  suppressionRule?: InputMaybe<SuppressionMergeRuleInput>;
};

export enum ObjectMergeRuleStatus {
  DISABLED = 'DISABLED',
  ENABLED = 'ENABLED',
}

export type OemLoggingConfig = {
  fieldName: Scalars['String']['output'];
  repo: Scalars['String']['output'];
};

export enum Ordering {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type PageInfo = {
  /** Cursor for fetching additional pages of data. */
  cursor: Maybe<Scalars['String']['output']>;
  /** Whether there are additional pages of data. If this is true, cursor will be set to a non-empty value. */
  hasNextPage: Scalars['Boolean']['output'];
};

export type Panel = {
  attributes: Maybe<Attributes>;
  name: Scalars['String']['output'];
  queries: Array<PanelQuery>;
};

export type PanelQuery = {
  query: Scalars['String']['output'];
  seriesNameFormat: Maybe<Scalars['String']['output']>;
};

export enum PermissionAction {
  READ = 'READ',
  READWRITE = 'READWRITE',
  UNKNOWN = 'UNKNOWN',
  WRITE = 'WRITE',
}

export type PersonalAccessToken = {
  createdAt: Scalars['String']['output'];
  expiresAt: Scalars['String']['output'];
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

export type PresentationComponent = {
  slug: Scalars['String']['output'];
  status: DiscoveryStatus;
};

export type PresentationConfigsInput = {
  overrides: Array<ComponentPresentationOverrideInput>;
};

export type PresentationDashboardInput = {
  /**
   *  Input Dashboard JSON along with the additional fields used to generate the component.
   * Can be used to edit the component
   */
  inputJSON?: InputMaybe<Scalars['JSONObject']['input']>;
  interval?: InputMaybe<Scalars['String']['input']>;
  serviceTemplateVar?: InputMaybe<Scalars['String']['input']>;
};

export type PresentationEligibility = {
  allowedServiceSlugs: Maybe<Array<Scalars['String']['output']>>;
  ignoredServiceSlugs: Maybe<Array<Scalars['String']['output']>>;
  requiredDiscoverySlugs: Array<Scalars['String']['output']>;
  withoutDiscoverySlugs: Maybe<Array<Scalars['String']['output']>>;
};

export type PresentationEligibilityInput = {
  allowedServiceSlugs?: InputMaybe<Array<Scalars['String']['input']>>;
  ignoredServiceSlugs?: InputMaybe<Array<Scalars['String']['input']>>;
  requiredDiscoverySlugs: Array<Scalars['String']['input']>;
  withoutDiscoverySlugs?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type PresentationEventsInput = {
  /**
   * Event JSONs to render on panels and more details views.
   *
   * This field is only available if the ComponentPresentationType is "EVENTS"
   */
  eventJSONs?: InputMaybe<Array<Scalars['JSONObject']['input']>>;
};

export type PromQL = {
  expr: Scalars['String']['output'];
  labelName: Scalars['String']['output'];
};

export type Query = {
  /**
   * Return a paginated list of alert events. Results are pagigated even when pageSize is omitted.
   *
   * If monitor slugs are not specified, alert events for all monitors are included in the response.
   * If event types are not specified, all event types are included in the response.
   * Signals can only be specified if at least one monitor slug is set. Currently only one signal
   * can be specified at a time.
   * SignalFilters can only be set if Signals is not specified. If set, only events which match all
   * the specified signal filters will be included in the response. Using signal fileters is less
   * efficient than Signals but provides more flexibility since you can query using a subset of
   * the signal labels.
   * The optional timeRange field should be specified in RFC3339 format.
   */
  alertEvents: AlertEventConnection;
  alertReceivers: AlertReceivers;
  allDashboards: AllDashboardsConnection;
  /** Fetch a collection by slug and type, returns null if not found. collectionType defaults to SIMPLE if not provided */
  collection: Maybe<Collection>;
  /** Fetches a collection link by its slug. */
  collectionLink: Maybe<CollectionLink>;
  /** Fetches a list of collection links based on the provided filter criteria. */
  collectionLinks: CollectionLinksConnection;
  collections: CollectionsConnection;
  /** Used by admin UI to fetch component discovery configuration including overrides */
  componentDiscoveryConfigs: Array<ComponentDiscoveryConfig>;
  /** Used by admin UI to fetch a single component presentation configuration including overrides */
  componentPresentationConfig: Maybe<ComponentPresentationConfig>;
  /** Fetch a custom component */
  customComponent: Component;
  dashboard: Dashboard;
  dashboards: DashboardsConnection;
  /** Get a specific single dataset by slug with optionally configured enhancements. */
  dataset: Maybe<Dataset>;
  /** Get a filtered page of datasets with optionally configured enhancements. */
  datasets: DatasetConnection;
  deprecatedGrafanaDashboards: Array<GrafanaDashboard>;
  /** Get the effective expression for a given override */
  discoveryOverrideEffectiveExpr: Scalars['String']['output'];
  downtimes: Array<Downtime>;
  dropRule: DropRule;
  dropRules: Array<DropRule>;
  /** Used by admin UI to fetch which services a presentation would be eligible for */
  eligibleServices: EligibleServicesConnection;
  entityVersions: EntityVersionsConnection;
  eventLog: EventLog;
  eventLogMethodNames: Array<Scalars['String']['output']>;
  eventLogs: EventLogs;
  /**
   * expiredMutingRulesForMonitor checks if the monitor or a specific signal became un-muted during the
   * specified timerange, and if it did, the muting rules that had applied and expired during the
   * specified time range are returned.
   *
   * This endpoint is now deprecated in favor of mutingRulesForMonitor.
   */
  expiredMutingRulesForMonitor: MutingRuleConnection;
  /** ExportConfig exposes the raw of configuration of certain entity types */
  exportConfig: ExportConfigQuery;
  /** Returns a list of feature flags that were requested. */
  featureFlagsV2: Array<FeatureFlag>;
  gatewayConfigs: GatewayConfigs;
  /** Used by admin UI to fetch the global filters for a tenant */
  globalFilters: Array<Template>;
  grafanaDashboard: Maybe<GrafanaDashboard>;
  grafanaDashboards: GrafanaDashboardsConnection;
  ingestionPolicies: IngestionPolicies;
  ingestionPolicy: IngestionPolicy;
  /** Fetch a Link Template */
  linkTemplate: Maybe<LinkTemplate>;
  /** Fetch all Link Templates */
  linkTemplates: Array<LinkTemplate>;
  /** Used by admin UI to fetch component presentation configuration including overrides */
  listComponentPresentationConfigs: Array<ComponentPresentationConfig>;
  /** map canonical service to tracing service attribute value */
  mapServiceToTracingServiceAttribute: ServiceToTracingServiceAttributeMap;
  /** map tracing service attribute to the appropriate canonical service value */
  mapTracingServiceAttributeToService: TracingServiceAttributeToServiceMap;
  mappingRule: MappingRule;
  mappingRules: Array<MappingRule>;
  /** Find matching monitor notification routes and conditions by signal */
  matchingMonitorRoutesAndConditions: Array<MatchingMonitorRoutesAndConditions>;
  me: User;
  metricDDX: MetricDDXResult;
  monitor: Monitor;
  /** Return an updated PromQL expression that will only include series for specific signals */
  monitorSignalQuery: Scalars['String']['output'];
  monitorSignalsConfigs: Maybe<Array<MonitorSignalConfig>>;
  /** Get summary information about monitor stats */
  monitorStats: MonitorStats;
  /** Get summary data for all monitors */
  monitorSummaries: Array<MonitorSummary>;
  /**
   * Return a paginated list of monitors. Results are paginated even when pageSize is omitted.
   *
   * If triggeredFirst is true, triggered monitors are returned before non-triggered monitors.
   * If allowDuplicates is true, response times may be faster but some monitors may be returned multiple times.
   * If includeStates is empty, all states are included in the response.
   * If collectionSlugs is empty, all monitors are included in the response unless collection slugs are defined.
   * @deprecated no longer used by our UI
   */
  monitorsPage: MonitorsConnection;
  /**
   * monitorsPageEventFilters returns a list of predefined timeline event queries for the specified
   * monitor page. The response will include three filters along with corresponding definitoins.
   */
  monitorsPageEventFilters: eventFilters;
  /** Return a paginated list of monitors. */
  monitorsV2: MonitorsConnection;
  /** Returns an individual muting rule by slug. */
  mutingRule: MutingRule;
  /**
   * mutingRulesForMonitor returns the list of muting rules that are applicable to a monitor or the
   * monitor signal if one is specified. If thera any currently active muting rules, expired muting
   * rules are omitted from the response. Expiring muting rules, are muting rules that applied and
   * became un-muted during the specified time range.
   */
  mutingRulesForMonitor: MutingRulesForMonitor;
  /** Return a paginated list of muting rules. Results are pagigated even when pageSize is omitted. */
  mutingRulesPage: MutingRuleConnection;
  /**
   * Queries for notification policies that are in-use by a set of monitors. The filters specify the set of monitors, and the
   * unique ordered notification policies used by those monitors are returned.
   */
  notificationPoliciesInUse: NotificationPolicyConnection;
  notificationPoliciesPage: NotificationPolicyConnection;
  notificationPolicy: NotificationPolicy;
  /** Used by admin UI to fetch the service pod map */
  objectDiscoveryRules: Array<ObjectDiscoveryRule>;
  /** Used by admin UI to fetch the object merge rules */
  objectMergeRules: Array<ObjectMergeRule>;
  personalAccessTokens: Array<PersonalAccessToken>;
  /** Preview the discovery pipeline */
  previewServiceDiscovery: Maybe<ServiceDiscoveryOutput>;
  /**
   * Fetch recording rules matching a set of filters.
   * TODO: make RecordingRulesFilters required
   */
  recordingRules: RecordingRules;
  resourcePools: ResourcePools;
  rollupRule: RollupRule;
  rollupRules: Array<RollupRule>;
  ruleEvaluations: RuleEvaluations;
  /** Fetch paginated saved trace searches for the authenticated calling user */
  savedTraceSearches: SavedTraceSearchConnection;
  /** Fetch entities to search/filter through */
  searchV2: Maybe<SearchConnection>;
  serviceAccounts: Array<ServiceAccount>;
  /** Fetch data to render the moreDetails view */
  serviceComponentViewDetails: ServiceComponentViewDetails;
  /** Fetch a service view */
  serviceView: Maybe<ServiceView>;
  /**
   * Fetch a service view component
   * @deprecated replaced by custom component
   */
  serviceViewComponent: Component;
  /**
   * Fetch list of services
   * @deprecated replaced by paginated servicesPage
   */
  services: Array<Service>;
  /** Fetch paginated list of services */
  servicesPage: ServicesConnection;
  settings: Settings;
  /** shortenedMessage returns the (hash, message) pair for the provided hash. */
  shortenedMessage: ShortenedMessage;
  slo: SLO;
  sloQueries: SLORelatedMetricsQueries;
  slos: SLOConnection;
  /** Fetch a team by ID or name, returns null if not found */
  team: Maybe<Team>;
  /** Fetch all teams with optional filtering by user id and optional filtering by name */
  teams: Array<Team>;
  tenant: Tenant;
  /** Fetch timeline categories including or excluding delete categories */
  timelineCategories: Array<TimelineCategory>;
  /** Fetch the active behaviors for the specified dataset and behavior slugs over the specified time interval */
  traceActiveBehaviors: TraceActiveBehaviorsConnection;
  /** Fetch the trace behavior config */
  traceBehaviorConfig: Maybe<TraceBehaviorConfig>;
  /** Fetch TraceBehaviors for the specified slugs, or all behaviors if unspecified */
  traceBehaviors: TraceBehaviorsConnection;
  /** Fetch paginated Jaeger Remote Sampling strategies with optional filtering */
  traceJaegerRemoteSamplingStrategies: TraceJaegerRemoteSamplingStrategyConnection;
  /** Fetch a Jaeger Remote Sampling strategy by slug, returns null if not found */
  traceJaegerRemoteSamplingStrategy: Maybe<TraceJaegerRemoteSamplingStrategy>;
  /** Fetch tracing license limits */
  traceLimits: Maybe<TracingLicenseLimits>;
  /** Fetch a trace metric rule by slug, returns null if not found */
  traceMetricsRule: Maybe<TraceMetricsRule>;
  /** Fetch all trace metrics rules with optional filtering */
  traceMetricsRules: TraceMetricsRulesConnection;
  /** Fetch paginated trace search history for the authenticated calling user */
  traceSearchHistory: TraceSearchHistoryConnection;
  /** Fetch tail sampling rules */
  traceTailSamplingRules: TraceTailSamplingRules;
  /** Fetch tracing top tags */
  traceTopTags: Maybe<TraceTopTags>;
  /** Get the unassigned dataset for the given telemetry type. Some types may not have a need for this abstraction. */
  unassignedDataset: Maybe<Dataset>;
  usageStatsConfig: Maybe<UsageStatsConfig>;
  /** Fetch a user by id. Returns null if not found */
  user: Maybe<User>;
  /** Fetch paginated search history for the authenticated caller user, user_id is an optional field that can only be used when only_saved is true */
  userSearchHistory: SearchHistoryConnection;
  userSettings: UserSettings;
  userSettingsValue: Maybe<Scalars['Any']['output']>;
  users: Array<User>;
  validateDashboard: ValidationResult;
};

export type QueryalertEventsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  eventTypes: InputMaybe<Array<AlertEventType>>;
  latestFirst: InputMaybe<Scalars['Boolean']['input']>;
  monitorSlugs: InputMaybe<Array<Scalars['String']['input']>>;
  pageSize: InputMaybe<Scalars['Int']['input']>;
  signalFilters: InputMaybe<Array<NewAlertRouteMatcher>>;
  signals: InputMaybe<Array<InputMaybe<Array<NewAlertRuleLabel>>>>;
  timeRange: InputMaybe<NewTimeRange>;
};

export type QueryallDashboardsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  filter: InputMaybe<AllDashboardsFilter>;
  pageSize: InputMaybe<Scalars['Int']['input']>;
};

export type QuerycollectionArgs = {
  collectionType: InputMaybe<CollectionType>;
  slug: Scalars['String']['input'];
};

export type QuerycollectionLinkArgs = {
  slug: Scalars['String']['input'];
};

export type QuerycollectionLinksArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  filter: InputMaybe<CollectionLinkListFilterInput>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type QuerycollectionsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  filters: InputMaybe<CollectionsFilters>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type QuerycomponentPresentationConfigArgs = {
  input: ReadPresentationConfigInput;
};

export type QuerycustomComponentArgs = {
  slug: Scalars['String']['input'];
};

export type QuerydashboardArgs = {
  slug: Scalars['String']['input'];
};

export type QuerydashboardsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  collectionRefs: InputMaybe<Array<CollectionRef>>;
  collectionSlugs: InputMaybe<Array<Scalars['String']['input']>>;
  favoritesOnly: InputMaybe<Scalars['Boolean']['input']>;
  pageSize: InputMaybe<Scalars['Int']['input']>;
  slugs: InputMaybe<Array<Scalars['String']['input']>>;
};

export type QuerydatasetArgs = {
  enhancements: InputMaybe<DatasetEnhancementsInput>;
  slug: Scalars['String']['input'];
};

export type QuerydatasetsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  enhancements: InputMaybe<DatasetEnhancementsInput>;
  filter: InputMaybe<DatasetsListFilterInput>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type QuerydeprecatedGrafanaDashboardsArgs = {
  filter: InputMaybe<GrafanaDashboardFilter>;
};

export type QuerydiscoveryOverrideEffectiveExprArgs = {
  input: ComponentDiscoveryOverrideInput;
};

export type QuerydowntimesArgs = {
  activeOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QuerydropRuleArgs = {
  slug: Scalars['String']['input'];
};

export type QueryeligibleServicesArgs = {
  input: EligibleServicesInput;
};

export type QueryentityVersionsArgs = {
  filters: EntityVersionFilters;
};

export type QueryeventLogArgs = {
  id: Scalars['String']['input'];
};

export type QueryeventLogsArgs = {
  pageToken: InputMaybe<Scalars['Int']['input']>;
};

export type QueryexpiredMutingRulesForMonitorArgs = {
  monitorSlug: Scalars['ID']['input'];
  signal: Array<NewAlertRuleLabel>;
  timeRange: NewTimeRange;
};

export type QueryfeatureFlagsV2Args = {
  flags: Array<FeatureFlagRequest>;
};

export type QuerygrafanaDashboardArgs = {
  slug: Scalars['String']['input'];
};

export type QuerygrafanaDashboardsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  filter: InputMaybe<GrafanaDashboardFilter>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryingestionPolicyArgs = {
  id: Scalars['String']['input'];
};

export type QuerylinkTemplateArgs = {
  slug: Scalars['String']['input'];
};

export type QuerymapServiceToTracingServiceAttributeArgs = {
  input: ServiceToTracingServiceAttributeInput;
};

export type QuerymapTracingServiceAttributeToServiceArgs = {
  input: TracingServiceAttributeToServiceInput;
};

export type QuerymappingRuleArgs = {
  slug: Scalars['String']['input'];
};

export type QuerymatchingMonitorRoutesAndConditionsArgs = {
  monitorSlug: Scalars['String']['input'];
  signals: Array<Array<NewAlertRuleLabel>>;
};

export type QuerymetricDDXArgs = {
  req: MetricDDXRequest;
};

export type QuerymonitorArgs = {
  monitorSlug: Scalars['String']['input'];
};

export type QuerymonitorSignalQueryArgs = {
  promExpr: Scalars['String']['input'];
  signals: InputMaybe<Array<InputMaybe<Array<NewAlertRuleLabel>>>>;
};

export type QuerymonitorSignalsConfigsArgs = {
  monitorSlug: Scalars['String']['input'];
  signals: InputMaybe<Array<InputMaybe<Array<NewAlertRuleLabel>>>>;
};

export type QuerymonitorSummariesArgs = {
  collectionSlugs: InputMaybe<Array<Scalars['String']['input']>>;
};

export type QuerymonitorsPageArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  allowDuplicates: InputMaybe<Scalars['Boolean']['input']>;
  bucketSlugs: InputMaybe<Array<Scalars['String']['input']>>;
  collectionSlugs: InputMaybe<Array<Scalars['String']['input']>>;
  includeStates: InputMaybe<Array<AlertState>>;
  pageSize: InputMaybe<Scalars['Int']['input']>;
  triggeredFirst: InputMaybe<Scalars['Boolean']['input']>;
};

export type QuerymonitorsPageEventFiltersArgs = {
  pageInfo: MonitorInformation;
};

export type QuerymonitorsV2Args = {
  after: InputMaybe<Scalars['String']['input']>;
  filters: MonitorsV2Filters;
  pageSize: InputMaybe<Scalars['Int']['input']>;
};

export type QuerymutingRuleArgs = {
  slug: Scalars['String']['input'];
};

export type QuerymutingRulesForMonitorArgs = {
  monitorSlug: Scalars['ID']['input'];
  signal: Array<NewAlertRuleLabel>;
  timeRange: NewTimeRange;
};

export type QuerymutingRulesPageArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  filters: MutingRulePageFilters;
  pageSize: InputMaybe<Scalars['Int']['input']>;
};

export type QuerynotificationPoliciesInUseArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  filters: NotificationPoliciesInUseFilters;
  pageSize: InputMaybe<Scalars['Int']['input']>;
};

export type QuerynotificationPoliciesPageArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  filters: NotificationPoliciesPageFilters;
  pageSize: InputMaybe<Scalars['Int']['input']>;
};

export type QuerynotificationPolicyArgs = {
  slug: Scalars['String']['input'];
};

export type QuerypreviewServiceDiscoveryArgs = {
  input: DiscoveryConfigsInput;
};

export type QueryrecordingRulesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  filters: InputMaybe<RecordingRulesFilters>;
  pageSize: InputMaybe<Scalars['Int']['input']>;
};

export type QueryrollupRuleArgs = {
  slug: Scalars['String']['input'];
};

export type QueryruleEvaluationsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  pageSize: InputMaybe<Scalars['Int']['input']>;
};

export type QuerysavedTraceSearchesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  input: InputMaybe<SavedTraceSearchQueryInput>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type QuerysearchV2Args = {
  input: SearchQuery;
};

export type QueryserviceComponentViewDetailsArgs = {
  slug: Scalars['String']['input'];
};

export type QueryserviceViewArgs = {
  slug: Scalars['String']['input'];
};

export type QueryserviceViewComponentArgs = {
  slug: Scalars['String']['input'];
};

export type QueryservicesArgs = {
  input: InputMaybe<ServicesInput>;
};

export type QueryservicesPageArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  filters: InputMaybe<ServicesFilter>;
  pageSize: InputMaybe<Scalars['Int']['input']>;
};

export type QueryshortenedMessageArgs = {
  hash: Scalars['String']['input'];
};

export type QuerysloArgs = {
  slug: Scalars['String']['input'];
};

export type QuerysloQueriesArgs = {
  input: SLOQueriesInput;
};

export type QueryslosArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  filter: InputMaybe<SLOListFilterInput>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryteamArgs = {
  id: InputMaybe<Scalars['ID']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
  slug: InputMaybe<Scalars['String']['input']>;
};

export type QueryteamsArgs = {
  nameContains: InputMaybe<Scalars['String']['input']>;
  onlyCustomerManaged: InputMaybe<Scalars['Boolean']['input']>;
  userID: InputMaybe<Scalars['ID']['input']>;
};

export type QuerytimelineCategoriesArgs = {
  includeDeleted: Scalars['Boolean']['input'];
};

export type QuerytraceActiveBehaviorsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  behaviorSlugs: InputMaybe<Array<Scalars['String']['input']>>;
  datasetSlugs: InputMaybe<Array<Scalars['String']['input']>>;
  includeFutureBehaviors: InputMaybe<Scalars['Boolean']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  timeRange: InputMaybe<BetweenTimeFilterInput>;
};

export type QuerytraceBehaviorsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  slugs: InputMaybe<Array<Scalars['String']['input']>>;
};

export type QuerytraceJaegerRemoteSamplingStrategiesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  filter: InputMaybe<TraceJaegerRemoteSamplingStrategiesListFilterInput>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type QuerytraceJaegerRemoteSamplingStrategyArgs = {
  slug: Scalars['String']['input'];
};

export type QuerytraceMetricsRuleArgs = {
  slug: Scalars['String']['input'];
};

export type QuerytraceMetricsRulesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  filter: InputMaybe<TraceMetricsRulesListFilterInput>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type QuerytraceSearchHistoryArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryunassignedDatasetArgs = {
  enhancements: InputMaybe<DatasetEnhancementsInput>;
  type: DatasetType;
};

export type QueryuserArgs = {
  id: Scalars['ID']['input'];
};

export type QueryuserSearchHistoryArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  onlySaved: InputMaybe<Scalars['Boolean']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  searchType: InputMaybe<SearchType>;
  userID: InputMaybe<Scalars['String']['input']>;
};

export type QueryuserSettingsValueArgs = {
  name: Scalars['String']['input'];
};

export type QueryvalidateDashboardArgs = {
  config: Scalars['JSONObject']['input'];
};

export type QueryDowntimesRequest = {
  activeOnly: Scalars['Boolean']['input'];
};

export type RateLimit = {
  usageStatsTag: Scalars['String']['output'];
  writesPerSecond: Scalars['Int']['output'];
};

export type ReadPresentationConfigInput = {
  slug: Scalars['String']['input'];
};

export type RecordingRule = {
  executionGroup: Maybe<Scalars['String']['output']>;
  expr: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  interval: Scalars['String']['output'];
  labels: Array<AlertRuleLabel>;
  /** Optional name of the metric written by the recording rule. If unset, the recording rule's name is used as the metric name. */
  metricName: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type RecordingRuleLabel = {
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type RecordingRules = {
  /** Pagination metadata to fetch additional pages of data. */
  pageInfo: PageInfo;
  /** List of recording rules */
  rules: Array<RecordingRule>;
  /** Total count of recording rules matching the query. */
  totalCount: Scalars['Int']['output'];
};

export type RecordingRulesFilters = {
  /**
   * If set, only rules with an executionGroup that contains this value will be returned. For legacy support, we will
   * search the attached collection slug (if there is one) instead. For rules with only execution group set, that is the only
   * field that is searched.
   */
  executionGroupContains?: InputMaybe<Scalars['String']['input']>;
  /** If set, only rules whose name field contains the string provided will be returned. The match is case insensitive */
  nameContains?: InputMaybe<Scalars['String']['input']>;
};

export type RelativeTimeFilter = {
  endRelativeOffsetSeconds: Maybe<Scalars['Int']['output']>;
  startRelativeOffsetSeconds: Scalars['Int']['output'];
};

export type RelativeTimeFilterInput = {
  endRelativeOffsetSeconds?: InputMaybe<Scalars['Int']['input']>;
  startRelativeOffsetSeconds: Scalars['Int']['input'];
};

export type RemoveUserFromTeam = {
  teamID: Scalars['ID']['input'];
  userID: Scalars['ID']['input'];
};

export type ResendSignupInviteInput = {
  token: Scalars['String']['input'];
};

export type ResourcePool = {
  allocation: ResourcePoolAllocation;
  matchRules: Maybe<Array<Label>>;
  matchType: Match;
  name: Scalars['String']['output'];
  priorities: Maybe<ResourcePoolHighLowPriorities>;
};

export type ResourcePoolAllocation = {
  fixedValues: Maybe<Array<ResourcePoolAllocationFixedValue>>;
  percentOfLicense: Maybe<Scalars['Float']['output']>;
};

export type ResourcePoolAllocationFixedValue = {
  license: ResourcePoolLicense;
  value: Scalars['Int']['output'];
};

export type ResourcePoolHighLowPriorities = {
  highPriorityMatchRules: Maybe<Array<Label>>;
  lowPriorityMatchRules: Maybe<Array<Label>>;
};

export enum ResourcePoolLicense {
  LICENSE_INVALID = 'LICENSE_INVALID',
  MATCHED_WRITES_HISTOGRAM = 'MATCHED_WRITES_HISTOGRAM',
  MATCHED_WRITES_STANDARD = 'MATCHED_WRITES_STANDARD',
  PERSISTED_WRITES_HISTOGRAM = 'PERSISTED_WRITES_HISTOGRAM',
  PERSISTED_WRITES_STANDARD = 'PERSISTED_WRITES_STANDARD',
}

export type ResourcePools = {
  config: ExportedConfig;
  createdAt: Scalars['String']['output'];
  defaultPool: Maybe<DefaultResourcePool>;
  managedBy: ManagedBy;
  pools: Maybe<Array<ResourcePool>>;
  source: EditSource;
  updatedAt: Scalars['String']['output'];
};

export enum ResponderType {
  ID = 'ID',
  NAME = 'NAME',
  UNKNOWN = 'UNKNOWN',
  USERNAME = 'USERNAME',
}

export enum ResponseType {
  ESCALATION = 'ESCALATION',
  SCHEDULE = 'SCHEDULE',
  TEAM = 'TEAM',
  UNKNOWN = 'UNKNOWN',
  USER = 'USER',
}

export type Result = {
  collection: Maybe<Collection>;
  isFavorite: Scalars['Boolean']['output'];
  isMigratedDashboard: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  team: Maybe<Team>;
  type: Scalars['String']['output'];
};

export type RetentionPolicy = {
  resolution: Scalars['String']['output'];
  retention: Scalars['String']['output'];
};

export type Role = {
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type RollupRule = {
  addMetricTypeLabel: Scalars['Boolean']['output'];
  aggregationType: AggregationType;
  config: ExportedConfig;
  createdAt: Scalars['String']['output'];
  dropRaw: Scalars['Boolean']['output'];
  expansiveMatch: Scalars['Boolean']['output'];
  filters: Array<Label>;
  graphiteLabelPolicy: Maybe<GraphiteLabelPolicy>;
  interval: Maybe<Scalars['String']['output']>;
  labelPolicy: Maybe<LabelPolicy>;
  labelReplace: Maybe<Array<RollupRuleLabelReplace>>;
  managedBy: ManagedBy;
  metricName: Scalars['String']['output'];
  metricType: MetricType;
  mode: RollupRuleMode;
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  source: EditSource;
  storagePolicy: Maybe<StoragePolicy>;
  updatedAt: Scalars['String']['output'];
};

export type RollupRuleLabelReplace = {
  dstLabelName: Maybe<Scalars['String']['output']>;
  keepLabel: Maybe<Scalars['Boolean']['output']>;
  labelValueRegex: Maybe<Scalars['String']['output']>;
  newLabelValue: Scalars['String']['output'];
  srcLabelName: Scalars['String']['output'];
};

export enum RollupRuleMode {
  ENABLED = 'ENABLED',
  PREVIEW = 'PREVIEW',
}

/** RuleEvaluation is an individual rule evaluation failure */
export type RuleEvaluation = {
  errorCode: RuleEvaluationErrorCode;
  message: Scalars['String']['output'];
  ruleFrequency: Scalars['String']['output'];
  ruleID: Scalars['String']['output'];
  ruleName: Scalars['String']['output'];
  ruleSlug: Scalars['String']['output'];
  ruleType: RuleEvaluationRuleType;
  value: Scalars['Float']['output'];
  window: RuleEvaluationWindow;
};

export enum RuleEvaluationErrorCode {
  CONFLICTING_MONITOR_LABELS = 'CONFLICTING_MONITOR_LABELS',
  CONFLICTING_RECORDING_LABELS = 'CONFLICTING_RECORDING_LABELS',
  DUPLICATE_SERIES = 'DUPLICATE_SERIES',
  INTERNAL = 'INTERNAL',
  INVALID = 'INVALID',
  LICENSE_EXCEEDED = 'LICENSE_EXCEEDED',
  RESOURCE_EXHAUSTED = 'RESOURCE_EXHAUSTED',
  TEMPLATE = 'TEMPLATE',
  TIMEOUT = 'TIMEOUT',
}

export enum RuleEvaluationRuleType {
  ALERTING = 'ALERTING',
  INVALID = 'INVALID',
  RECORDING = 'RECORDING',
}

export enum RuleEvaluationWindow {
  INVALID = 'INVALID',
  WINDOW_5M = 'WINDOW_5M',
  WINDOW_30M = 'WINDOW_30M',
}

/** RuleEvaluations contains a summary of all rule evaluation failures */
export type RuleEvaluations = {
  evaluations: Array<RuleEvaluation>;
  pageInfo: PageInfo;
  totalMonitors: Scalars['Int']['output'];
  totalRecordingRules: Scalars['Int']['output'];
};

export type SLI = {
  customDimensionLabels: Maybe<Array<Scalars['String']['output']>>;
  rawQueries: Maybe<SLIQueries>;
  templateData: Maybe<SLITemplateData>;
};

export type SLIEndpointAvailability = {
  additionalPromFilters: Maybe<Array<LabelMatcher>>;
  endpointsMonitored: Maybe<Array<Scalars['String']['output']>>;
  errorCodes: Maybe<Array<Scalars['String']['output']>>;
  successCodes: Maybe<Array<Scalars['String']['output']>>;
};

export type SLIEndpointAvailabilityInput = {
  additionalPromFilters?: InputMaybe<Array<NewLabelMatcher>>;
  endpointsMonitored?: InputMaybe<Array<Scalars['String']['input']>>;
  errorCodes?: InputMaybe<Array<Scalars['String']['input']>>;
  successCodes?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type SLIEndpointLatency = {
  additionalPromFilters: Maybe<Array<LabelMatcher>>;
  endpointsMonitored: Maybe<Array<Scalars['String']['output']>>;
  latencyBucket: Scalars['String']['output'];
};

export type SLIEndpointLatencyInput = {
  additionalPromFilters?: InputMaybe<Array<NewLabelMatcher>>;
  endpointsMonitored?: InputMaybe<Array<Scalars['String']['input']>>;
  latencyBucket: Scalars['String']['input'];
};

export type SLIInput = {
  customDimensionLabels?: InputMaybe<Array<Scalars['String']['input']>>;
  rawQueries?: InputMaybe<SLIQueriesInput>;
  templateData?: InputMaybe<SLITemplateDataInput>;
};

export type SLIMetadata = {
  componentDiscoverySlug: Maybe<Scalars['String']['output']>;
  endpointLabel: Scalars['String']['output'];
};

export type SLIQueries = {
  /** A PromQL query that returns the number of bad results for the SLI */
  badQuery: Maybe<Scalars['String']['output']>;
  /** A PromQL query that returns the number of good results for the SLI */
  goodQuery: Maybe<Scalars['String']['output']>;
  /** A PromQL query that returns the total number for the SLI */
  totalQuery: Scalars['String']['output'];
};

export type SLIQueriesInput = {
  badQuery?: InputMaybe<Scalars['String']['input']>;
  goodQuery?: InputMaybe<Scalars['String']['input']>;
  totalQuery: Scalars['String']['input'];
};

export type SLITemplateData = {
  componentDiscoverySlug: Maybe<Scalars['String']['output']>;
  components: Maybe<Array<Component>>;
  endpointAvailability: Maybe<SLIEndpointAvailability>;
  endpointLabel: Scalars['String']['output'];
  endpointLatency: Maybe<SLIEndpointLatency>;
  sourceService: ICollection;
};

export type SLITemplateDatacomponentsArgs = {
  presentationTypes: InputMaybe<Array<ComponentPresentationType>>;
};

export type SLITemplateDataInput = {
  componentDiscoverySlug?: InputMaybe<Scalars['String']['input']>;
  endpointAvailability?: InputMaybe<SLIEndpointAvailabilityInput>;
  endpointLabel: Scalars['String']['input'];
  endpointLatency?: InputMaybe<SLIEndpointLatencyInput>;
  sourceService: CollectionRef;
};

export type SLO = {
  annotations: Array<Annotation>;
  /** The collection or service that the SLO is a part of */
  collection: ICollection;
  /** The date and time the SLO was created. */
  createdAt: Scalars['String']['output'];
  definition: Maybe<SLODefinition>;
  /** The description of the SLO. */
  description: Maybe<Scalars['String']['output']>;
  /** The unique ID of the SLO. */
  id: Scalars['String']['output'];
  isPreview: Maybe<Scalars['Boolean']['output']>;
  labels: Maybe<Array<Label>>;
  /** The monitors that have been created for the SLO */
  monitors: Array<Monitor>;
  /** The name of the SLO. */
  name: Scalars['String']['output'];
  /** The optional explicit notification policy for an SLO */
  notificationPolicy: Maybe<NotificationPolicy>;
  relatedQueries: Maybe<SLORelatedMetricsQueries>;
  signalGrouping: Maybe<SignalGrouping>;
  sli: SLI;
  /** The unique slug of the SLO. */
  slug: Scalars['String']['output'];
  /** The current state of the SLO */
  state: AlertState;
  /** The date and time the SLO was last updated. */
  updatedAt: Scalars['String']['output'];
};

export type SLOBurnRateDefinition = {
  /** The budget for the burn rate between 0.0 and 1.0. Examples: 0.99, 0.95 */
  budget: Scalars['Float']['output'];
  /** The duration of the burn rate. Examples: "30d", "1w", "1d" */
  window: Scalars['String']['output'];
};

export type SLOBurnRateDefinitionInput = {
  budget: Scalars['Float']['input'];
  window: Scalars['String']['input'];
};

export type SLOConnection = {
  pageInfo: PageInfo;
  slos: Array<SLO>;
  totalCount: Scalars['Int']['output'];
};

export type SLODefinition = {
  highErrorRateDef: Maybe<Array<SLOBurnRateDefinition>>;
  lowErrorRateDef: Maybe<Array<SLOBurnRateDefinition>>;
  lowVolume: Scalars['Boolean']['output'];
  objective: Scalars['Float']['output'];
  reportingWindows: Maybe<Array<SLOReportingWindow>>;
};

export type SLODefinitionInput = {
  highErrorRateDef?: InputMaybe<Array<SLOBurnRateDefinitionInput>>;
  lowErrorRateDef?: InputMaybe<Array<SLOBurnRateDefinitionInput>>;
  lowVolume: Scalars['Boolean']['input'];
  objective: Scalars['Float']['input'];
  reportingWindows?: InputMaybe<Array<SLOReportingWindowInput>>;
};

export type SLOErrorCode = {
  displayName: Maybe<Scalars['String']['output']>;
  isSuccess: Maybe<Scalars['Boolean']['output']>;
  value: Scalars['String']['output'];
};

export type SLOListFilterInput = {
  collectionRefs?: InputMaybe<Array<CollectionRef>>;
  nameContains?: InputMaybe<Scalars['String']['input']>;
  slugs?: InputMaybe<Array<Scalars['String']['input']>>;
  teamSlugs?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type SLOMetadata = {
  sloType: SLOType;
};

export type SLOMetadataEndpointAvailability = SLOMetadata & {
  allErrorCodes: Maybe<Array<SLOErrorCode>>;
  endpointLabel: Scalars['String']['output'];
  errorTypeLabel: Scalars['String']['output'];
  sloType: SLOType;
};

export type SLOMetadataLatency = SLOMetadata & {
  allErrorCodes: Maybe<Array<SLOErrorCode>>;
  classicHistogramMetricName: Maybe<Scalars['String']['output']>;
  endpointLabel: Scalars['String']['output'];
  errorTypeLabel: Scalars['String']['output'];
  sloType: SLOType;
};

export type SLOQueriesInput = {
  slo: UpdateSLOInput;
};

export type SLORelatedMetricsQueries = {
  percentErrorsByEndpoint: Maybe<Scalars['String']['output']>;
  rawAvailability: Maybe<Scalars['String']['output']>;
  rawError: Maybe<Scalars['String']['output']>;
  rawTotal: Maybe<Scalars['String']['output']>;
  totalRequests: Maybe<Scalars['String']['output']>;
};

export type SLOReportingWindow = {
  /** The duration of the time window. Examples: "30d", "1w", "1d" */
  duration: Scalars['String']['output'];
  /** The name of the derived metric to query for error ratio of this reporting window */
  errorRatioMetric: Scalars['String']['output'];
};

export type SLOReportingWindowInput = {
  duration: Scalars['String']['input'];
};

export enum SLOType {
  ENDPOINT_AVAILABILITY = 'ENDPOINT_AVAILABILITY',
  LATENCY = 'LATENCY',
}

/** Input used to save a collection homepage. */
export type SaveCollectionHomepageInput = {
  collectionSlug: Scalars['String']['input'];
  links?: InputMaybe<Array<InputMaybe<LinkInput>>>;
};

export type SaveDashboardInput = {
  /** Collection containing this dashboard */
  collectionSlug: Scalars['String']['input'];
  /**
   * Type of the collection containing the dashboard. Optional for backwards compatibility. Defaults to SIMPLE if not
   * specified.
   */
  collectionType?: InputMaybe<CollectionType>;
  dashboardJSON: Scalars['JSONObject']['input'];
  labels?: InputMaybe<Array<LabelInput>>;
  /** Dashboard name. Must be unique within a collection. */
  name: Scalars['String']['input'];
  /** Slug of dashboard to create or update. A new dashboard will be created if this is unset or empty. */
  slug?: InputMaybe<Scalars['String']['input']>;
};

/** Input used to save a team homepage. */
export type SaveTeamHomepageInput = {
  links?: InputMaybe<Array<InputMaybe<LinkInput>>>;
  teamSlug: Scalars['String']['input'];
};

export type SavedTraceSearch = {
  comparison: Maybe<TraceSearch>;
  createdAt: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  name: Scalars['String']['output'];
  search: TraceSearch;
  slug: Scalars['ID']['output'];
  updatedAt: Scalars['String']['output'];
};

export enum SavedTraceSearchColumn {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
}

export type SavedTraceSearchConnection = {
  pageInfo: PageInfo;
  savedSearches: Array<SavedTraceSearch>;
};

export type SavedTraceSearchOrdering = {
  column: SavedTraceSearchColumn;
  order: Ordering;
};

export type SavedTraceSearchQueryInput = {
  createdBy?: InputMaybe<Scalars['String']['input']>;
  createdByContains?: InputMaybe<Scalars['String']['input']>;
  createdByStartsWith?: InputMaybe<Scalars['String']['input']>;
  nameContains?: InputMaybe<Scalars['String']['input']>;
  names?: InputMaybe<Array<Scalars['String']['input']>>;
  orderBy?: InputMaybe<SavedTraceSearchOrdering>;
  slugs?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type Schedule = {
  timezone: Scalars['String']['output'];
  /**
   * Keyed by time.Weekday index in array (sunday -> saturday).
   * Entire field can be null to indicate no available ranges.
   * Individual days of the week can be null to indicate no ranges on that day.
   */
  weeklySchedule: Maybe<Array<Maybe<ScheduleDay>>>;
};

export type ScheduleDay = {
  ranges: Array<TimeRange>;
};

export type ScopeFilter = {
  spanScopes: Maybe<Array<SpanFilter>>;
};

export type ScopeFilterInput = {
  spanScopes?: InputMaybe<Array<SpanFilterInput>>;
};

export type SearchConnection = {
  items: Array<Result>;
  totalCount: Maybe<Scalars['Int']['output']>;
};

/** SearchHistoryConnection is a paginated container of a list of search history entries. */
export type SearchHistoryConnection = {
  /** Pagination metadata to fetch additional pages of data. */
  pageInfo: PageInfo;
  /** Search history for this page. The pageInfo field contains metadata to fetch additional pages. */
  searchHistories: Array<UserSearchHistory>;
};

export type SearchHistoryQuery = {
  query: Scalars['String']['output'];
  summaryFields: Maybe<Array<Scalars['String']['output']>>;
  version: Maybe<Scalars['Int']['output']>;
};

export type SearchHistoryQueryInput = {
  query: Scalars['String']['input'];
  summaryFields?: InputMaybe<Array<Scalars['String']['input']>>;
  version?: InputMaybe<Scalars['Int']['input']>;
};

export type SearchQuery = {
  /** A list of entity kinds to search for. If not set, all kinds are matched. */
  kindFilter?: InputMaybe<Array<Scalars['String']['input']>>;
  /** The string to search for */
  query?: InputMaybe<Scalars['String']['input']>;
};

export enum SearchType {
  INVALID = 'INVALID',
  LOGGING = 'LOGGING',
  TIMELINE = 'TIMELINE',
}

export type SeriesConditions = {
  defaults: Maybe<Array<SeverityCondition>>;
  overrides: Maybe<Array<ConditionOverride>>;
};

export type Service = ICollection & {
  collectionSlug: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  derivedName: Scalars['String']['output'];
  description: Scalars['String']['output'];
  discoveries: Maybe<Array<ComponentDiscovery>>;
  name: Scalars['String']['output'];
  policy: Maybe<NotificationPolicy>;
  slug: Scalars['String']['output'];
  team: Maybe<Team>;
  type: CollectionType;
  updatedAt: Scalars['String']['output'];
  view: ServiceView;
};

export type ServiceAccount = {
  createdAt: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isAdmin: Scalars['Boolean']['output'];
  labels: Array<ServiceAccountLabel>;
  lastUsedAt: Maybe<Scalars['String']['output']>;
  managedBy: ManagedBy;
  name: Scalars['String']['output'];
  permission: PermissionAction;
  roles: Array<Role>;
  source: EditSource;
};

export type ServiceAccountLabel = {
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type ServiceComponentViewDetails = {
  /** Component containing the moreDetailsJSON dashboard to render */
  component: Component;
  /**
   * Events to render on the more details dashboard.
   *
   * It should contain a list of all events in all enabled component presentations.
   */
  eventsConfig: Maybe<Array<Scalars['JSONObject']['output']>>;
  hasChangeEvents: Maybe<Scalars['Boolean']['output']>;
  hasTraces: Scalars['Boolean']['output'];
};

export type ServiceDiscoveryOutput = {
  compressedDebugLogs: Maybe<Scalars['String']['output']>;
  services: Array<DiscoveredService>;
};

export type ServiceToTracingServiceAttributeInput = {
  serviceDerivedNames: Array<Scalars['String']['input']>;
};

export type ServiceToTracingServiceAttributeMap = {
  tracingServices: Array<TracingServiceMapping>;
};

export type ServiceView = {
  components: Maybe<Array<Component>>;
  /**
   * Events to render on the more details dashboard.
   *
   * It should contain a list of all events in all enabled component presentations.
   */
  eventsConfig: Maybe<Array<Scalars['JSONObject']['output']>>;
  hasChangeEvents: Maybe<Scalars['Boolean']['output']>;
  hasTraces: Scalars['Boolean']['output'];
  inactive: Maybe<Scalars['Boolean']['output']>;
  labelsConfig: Maybe<Array<LabelConfig>>;
  oemLoggingConfig: Maybe<OemLoggingConfig>;
  service: Service;
  templates: Maybe<Array<Template>>;
};

export type ServiceViewcomponentsArgs = {
  presentationTypes: InputMaybe<Array<ComponentPresentationType>>;
};

export type ServicesConnection = {
  /** Pagination metadata to fetch additional pages of data. */
  pageInfo: PageInfo;
  /** Services for this page. The pageInfo field contains metadata to fetch additional pages. */
  services: Array<Service>;
  /**
   * Total count of services.
   * This count includes all services and therefore may be larger than the length of the services field.
   * A value less than 0 indicates there are results, but the total count is uncertain.
   */
  totalCount: Scalars['Int']['output'];
};

export type ServicesFilter = {
  discoverySlugs?: InputMaybe<Array<Scalars['String']['input']>>;
  includeInactive?: InputMaybe<Scalars['Boolean']['input']>;
  nameContains?: InputMaybe<Scalars['String']['input']>;
  teamSlugs?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ServicesInput = {
  teamSlugs?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type Settings = {
  buildVersion: Scalars['String']['output'];
  metrics: Maybe<MetricSettings>;
  revision: Scalars['String']['output'];
};

export type SeverityCondition = {
  conditionLevel: ConditionLevel;
  conditions: Maybe<Array<MonitorCondition>>;
};

export type ShortenMessageRequest = {
  message: Scalars['String']['input'];
};

export type ShortenedMessage = {
  /** the short identifier for the original message, obtained by hashing & base62-encoding. */
  hash: Scalars['String']['output'];
  /** The original unshortened message. */
  message: Scalars['String']['output'];
};

export type SignalGrouping = {
  labelNames: Maybe<Array<Scalars['String']['output']>>;
  signalPerSeries: Scalars['Boolean']['output'];
};

export type SignupInvite = {
  email: Scalars['String']['output'];
  expiresAt: Scalars['String']['output'];
  inviteURL: Scalars['String']['output'];
  tenant: Tenant;
  token: Scalars['String']['output'];
  used: Scalars['Boolean']['output'];
};

export type SpanFilter = {
  duration: Maybe<DurationFilter>;
  error: Maybe<BoolFilter>;
  inCriticalPath: Maybe<BoolFilter>;
  isRootSpan: Maybe<BoolFilter>;
  leafError: Maybe<BoolFilter>;
  matchType: SpanFilterMatchType;
  operation: Maybe<StringFilter>;
  parentOperation: Maybe<StringFilter>;
  parentService: Maybe<StringFilter>;
  service: Maybe<StringFilter>;
  spanCount: Maybe<CountFilter>;
  tags: Array<TagFilter>;
};

export type SpanFilterInput = {
  duration?: InputMaybe<DurationFilterInput>;
  error?: InputMaybe<BoolFilterInput>;
  inCriticalPath?: InputMaybe<BoolFilterInput>;
  isRootSpan?: InputMaybe<BoolFilterInput>;
  leafError?: InputMaybe<BoolFilterInput>;
  matchType?: InputMaybe<SpanFilterMatchType>;
  operation?: InputMaybe<StringFilterInput>;
  parentOperation?: InputMaybe<StringFilterInput>;
  parentService?: InputMaybe<StringFilterInput>;
  service?: InputMaybe<StringFilterInput>;
  spanCount?: InputMaybe<CountFilterInput>;
  tags: Array<TagFilterInput>;
};

export enum SpanFilterMatchType {
  EXCLUDE = 'EXCLUDE',
  INCLUDE = 'INCLUDE',
  UNKNOWN = 'UNKNOWN',
}

export type StaticList = {
  values: Array<Scalars['String']['output']>;
};

export type StoragePolicy = {
  resolution: Scalars['String']['output'];
  retention: Scalars['String']['output'];
};

export type StringFilter = {
  inValues: Maybe<Array<Scalars['String']['output']>>;
  match: StringFilterMatchType;
  value: Scalars['String']['output'];
};

export type StringFilterInput = {
  inValues?: InputMaybe<Array<Scalars['String']['input']>>;
  match: StringFilterMatchType;
  value: Scalars['String']['input'];
};

export enum StringFilterMatchType {
  EXACT = 'EXACT',
  EXACT_NEGATION = 'EXACT_NEGATION',
  IN = 'IN',
  NOT_IN = 'NOT_IN',
  REGEX = 'REGEX',
  REGEX_NEGATION = 'REGEX_NEGATION',
  UNKNOWN = 'UNKNOWN',
}

export type StringValue = {
  value: Scalars['String']['output'];
};

export type SubmitChangeRequestInput = {
  message?: InputMaybe<Scalars['String']['input']>;
  requestType: Scalars['String']['input'];
  subject?: InputMaybe<Scalars['String']['input']>;
};

export type SuppressionMergeRule = {
  exceptionServiceNames: Maybe<Array<Scalars['String']['output']>>;
  suppressedDiscoveryComponentSlug: Scalars['String']['output'];
};

export type SuppressionMergeRuleInput = {
  exceptionServiceNames?: InputMaybe<Array<Scalars['String']['input']>>;
  suppressedDiscoveryComponentSlug: Scalars['String']['input'];
};

export type TagFilter = {
  key: Scalars['String']['output'];
  numericValue: Maybe<NumericFilter>;
  value: Maybe<StringFilter>;
};

export type TagFilterInput = {
  key: Scalars['String']['input'];
  numericValue?: InputMaybe<NumericFilterInput>;
  value?: InputMaybe<StringFilterInput>;
};

export type Team = {
  /** filtered event logs containing changes to this team e.g. name changes, users added/removed */
  changeLog: EventLogs;
  collections: CollectionsConnection;
  config: ExportedConfig;
  createdAt: Scalars['String']['output'];
  description: Scalars['String']['output'];
  homepage: Maybe<TeamHomepage>;
  id: Scalars['ID']['output'];
  isAdmin: Scalars['Boolean']['output'];
  managedBy: ManagedBy;
  name: Scalars['String']['output'];
  policies: Array<NotificationPolicy>;
  roles: Array<Role>;
  slug: Scalars['String']['output'];
  source: EditSource;
  users: Array<User>;
};

export type TeamchangeLogArgs = {
  pageToken: Scalars['Int']['input'];
};

export type TeamcollectionsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  filters: InputMaybe<CollectionsFilters>;
  pageSize: InputMaybe<Scalars['Int']['input']>;
};

export type TeamBulkUpdate = {
  addedIDs: Array<Scalars['ID']['output']>;
  removedIDs: Array<Scalars['ID']['output']>;
};

export type TeamHomepage = {
  links: Maybe<Array<Link>>;
};

export type TeamsForUserBulkUpdate = {
  addedIDs: Array<Scalars['ID']['output']>;
  removedIDs: Array<Scalars['ID']['output']>;
};

export type Template = {
  defaultValue: Scalars['String']['output'];
  name: Scalars['String']['output'];
  value: TemplateValue;
};

export type TemplateInput = {
  defaultValue?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  value: TemplateValueInput;
};

export type TemplateValue = {
  labelNames: Maybe<LabelNames>;
  labelValues: Maybe<LabelValues>;
  list: Maybe<StaticList>;
  promQL: Maybe<PromQL>;
};

export type TemplateValueInput = {
  labelValues: LabelValuesInput;
};

export type Tenant = {
  graphiteEnabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  loggingDataSourceEnabled: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  settings: TenantSettings;
  subdomain: Scalars['String']['output'];
  team: Maybe<Team>;
  tenantConfiguration: TenantConfiguration;
};

export type TenantConfiguration = {
  scrapeInterval: Scalars['String']['output'];
};

export type TenantSettings = {
  alertsIntervalsSeconds: Maybe<Array<Scalars['Int']['output']>>;
  loggingAlertsIntervalSeconds: Maybe<Array<Scalars['Int']['output']>>;
};

export enum ThresholdOp {
  EQ = 'EQ',
  EXISTS = 'EXISTS',
  GEQ = 'GEQ',
  GT = 'GT',
  INVALID = 'INVALID',
  LEQ = 'LEQ',
  LT = 'LT',
  NEQ = 'NEQ',
  NOT_EXISTS = 'NOT_EXISTS',
}

export type TimeFilter = BetweenTimeFilter | CloseToTimeFilter | RelativeTimeFilter;

export type TimeFilterInput = {
  between?: InputMaybe<BetweenTimeFilterInput>;
  close?: InputMaybe<CloseToTimeFilterInput>;
  relative?: InputMaybe<RelativeTimeFilterInput>;
};

export type TimeRange = {
  end: Scalars['String']['output'];
  start: Scalars['String']['output'];
};

export type TimelineCategory = {
  Deleted: Scalars['Boolean']['output'];
  FieldValue: Scalars['String']['output'];
  Name: Scalars['String']['output'];
};

export type TraceActiveBehaviorsConnection = {
  activeBehaviors: Maybe<Array<TraceBehaviorActiveAssignment>>;
  futureActiveBehaviors: Maybe<Array<TraceBehaviorActiveAssignment>>;
  pageInfo: PageInfo;
};

export type TraceAggregationFocus = {
  criticalPathOnly: Maybe<Scalars['Boolean']['output']>;
  terminalErrorsOnly: Maybe<Scalars['Boolean']['output']>;
};

export type TraceAggregationFocusInput = {
  criticalPathOnly?: InputMaybe<Scalars['Boolean']['input']>;
  terminalErrorsOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TraceAggregationGroupByKey = {
  key: Maybe<Scalars['String']['output']>;
  type: TraceAggregationGroupByType;
  value: Maybe<Scalars['String']['output']>;
};

export type TraceAggregationGroupByKeyInput = {
  type: TraceAggregationGroupByType;
  value?: InputMaybe<Scalars['String']['input']>;
};

export enum TraceAggregationGroupByType {
  OP = 'OP',
  SVC = 'SVC',
  TAG = 'TAG',
  UNKNOWN = 'UNKNOWN',
}

export type TraceAggregationOptions = {
  focus: Maybe<TraceAggregationFocus>;
  shape: Maybe<TraceAggregationShape>;
};

export type TraceAggregationOptionsInput = {
  focus?: InputMaybe<TraceAggregationFocusInput>;
  shape?: InputMaybe<TraceAggregationShapeInput>;
};

export type TraceAggregationShape = {
  groupBy: Array<TraceAggregationGroupByKey>;
};

export type TraceAggregationShapeInput = {
  groupBy: Array<TraceAggregationGroupByKeyInput>;
};

export type TraceBehavior = {
  baseHeadSampleRate: Maybe<Scalars['Float']['output']>;
  baseTailSampleRate: Maybe<Scalars['Float']['output']>;
  baselineBehaviorOptions: Maybe<TraceBehaviorBaselineOptions>;
  createdAt: Scalars['String']['output'];
  description: Maybe<Scalars['String']['output']>;
  errorSampleOptions: Maybe<TraceBehaviorErrorSampleOptions>;
  fastSampleOptions: Maybe<TraceBehaviorFastSampleOptions>;
  largeTraceSampleOptions: Maybe<TraceBehaviorLargeTraceSampleOptions>;
  name: Scalars['String']['output'];
  slowSampleOptions: Maybe<TraceBehaviorSlowSampleOptions>;
  slug: Scalars['String']['output'];
  smallTraceSampleOptions: Maybe<TraceBehaviorSmallTraceSampleOptions>;
  type: Maybe<TraceBehaviorType>;
  updatedAt: Scalars['String']['output'];
};

export type TraceBehaviorActiveAssignment = {
  assignmentType: TraceBehaviorAssignmentType;
  behavior: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  dataset: Scalars['String']['output'];
  description: Scalars['String']['output'];
  endTime: Maybe<Scalars['String']['output']>;
  startTime: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
};

export enum TraceBehaviorAssignmentType {
  MAIN = 'MAIN',
  OVERRIDE = 'OVERRIDE',
  REMOVED = 'REMOVED',
  UNKNOWN = 'UNKNOWN',
}

export type TraceBehaviorBaselineOptions = {
  baseHeadSampleRate: Scalars['Float']['output'];
  baseTailSampleRate: Scalars['Float']['output'];
  errorSampleOptions: Maybe<TraceBehaviorErrorSampleOptions>;
  fastSampleOptions: Maybe<TraceBehaviorFastSampleOptions>;
  largeTraceSampleOptions: Maybe<TraceBehaviorLargeTraceSampleOptions>;
  slowSampleOptions: Maybe<TraceBehaviorSlowSampleOptions>;
  smallTraceSampleOptions: Maybe<TraceBehaviorSmallTraceSampleOptions>;
};

export type TraceBehaviorBaselineOptionsInput = {
  baseHeadSampleRate: Scalars['Float']['input'];
  baseTailSampleRate: Scalars['Float']['input'];
  errorSampleOptions?: InputMaybe<TraceBehaviorErrorSampleOptionsInput>;
  fastSampleOptions?: InputMaybe<TraceBehaviorFastSampleOptionsInput>;
  largeTraceSampleOptions?: InputMaybe<TraceBehaviorLargeTraceSampleOptionsInput>;
  slowSampleOptions?: InputMaybe<TraceBehaviorSlowSampleOptionsInput>;
  smallTraceSampleOptions?: InputMaybe<TraceBehaviorSmallTraceSampleOptionsInput>;
};

export type TraceBehaviorConfig = {
  baselineBehaviorSlug: Maybe<Scalars['String']['output']>;
  datasetPriorities: Array<Scalars['String']['output']>;
  mainBehaviorAssignments: Array<TraceBehaviorMainAssignment>;
  overrideBehaviorAssignments: Maybe<Array<TraceBehaviorOverrideAssignment>>;
  previewBehaviorAssignments: Maybe<Array<TraceBehaviorOverrideAssignment>>;
  version: Scalars['Int']['output'];
};

export type TraceBehaviorErrorSampleOptions = {
  enabled: Scalars['Boolean']['output'];
  sampleRate: Scalars['Float']['output'];
  samplingType: TraceBehaviorSamplingType;
};

export type TraceBehaviorErrorSampleOptionsInput = {
  enabled: Scalars['Boolean']['input'];
  sampleRate: Scalars['Float']['input'];
  samplingType: TraceBehaviorSamplingType;
};

export type TraceBehaviorFastSampleOptions = {
  enabled: Scalars['Boolean']['output'];
  maxDurationSeconds: Scalars['Float']['output'];
  sampleRate: Scalars['Float']['output'];
  samplingType: TraceBehaviorSamplingType;
};

export type TraceBehaviorFastSampleOptionsInput = {
  enabled: Scalars['Boolean']['input'];
  maxDurationSeconds: Scalars['Float']['input'];
  sampleRate: Scalars['Float']['input'];
  samplingType: TraceBehaviorSamplingType;
};

export type TraceBehaviorLargeTraceSampleOptions = {
  enabled: Scalars['Boolean']['output'];
  sampleRate: Scalars['Float']['output'];
  samplingType: TraceBehaviorSamplingType;
  spanCountThreshold: Scalars['Int']['output'];
};

export type TraceBehaviorLargeTraceSampleOptionsInput = {
  enabled: Scalars['Boolean']['input'];
  sampleRate: Scalars['Float']['input'];
  samplingType: TraceBehaviorSamplingType;
  spanCountThreshold: Scalars['Int']['input'];
};

export type TraceBehaviorMainAssignment = {
  behavior: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  dataset: Scalars['String']['output'];
  description: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
};

export type TraceBehaviorMainAssignmentInput = {
  behavior: Scalars['String']['input'];
  createdBy: Scalars['String']['input'];
  dataset: Scalars['String']['input'];
  description: Scalars['String']['input'];
};

export type TraceBehaviorOverrideAssignment = {
  behavior: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  dataset: Scalars['String']['output'];
  description: Scalars['String']['output'];
  endTime: Scalars['String']['output'];
  startTime: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
};

export type TraceBehaviorOverrideAssignmentInput = {
  behavior: Scalars['String']['input'];
  createdBy: Scalars['String']['input'];
  dataset: Scalars['String']['input'];
  description: Scalars['String']['input'];
  endTime: Scalars['String']['input'];
  startTime: Scalars['String']['input'];
};

export enum TraceBehaviorSamplingType {
  HIGH_VALUE = 'HIGH_VALUE',
  LOW_VALUE = 'LOW_VALUE',
  UNKNOWN = 'UNKNOWN',
}

export type TraceBehaviorSlowSampleOptions = {
  enabled: Scalars['Boolean']['output'];
  minDurationSeconds: Scalars['Float']['output'];
  sampleRate: Scalars['Float']['output'];
  samplingType: TraceBehaviorSamplingType;
};

export type TraceBehaviorSlowSampleOptionsInput = {
  enabled: Scalars['Boolean']['input'];
  minDurationSeconds: Scalars['Float']['input'];
  sampleRate: Scalars['Float']['input'];
  samplingType: TraceBehaviorSamplingType;
};

export type TraceBehaviorSmallTraceSampleOptions = {
  enabled: Scalars['Boolean']['output'];
  sampleRate: Scalars['Float']['output'];
  samplingType: TraceBehaviorSamplingType;
  spanCountThreshold: Scalars['Int']['output'];
};

export type TraceBehaviorSmallTraceSampleOptionsInput = {
  enabled: Scalars['Boolean']['input'];
  sampleRate: Scalars['Float']['input'];
  samplingType: TraceBehaviorSamplingType;
  spanCountThreshold: Scalars['Int']['input'];
};

export enum TraceBehaviorType {
  BASELINE = 'BASELINE',
  DEPRECATED = 'DEPRECATED',
  DROP_ALL = 'DROP_ALL',
  KEEP_ALL = 'KEEP_ALL',
  UNKNOWN = 'UNKNOWN',
}

export type TraceBehaviorsConnection = {
  behaviors: Maybe<Array<TraceBehavior>>;
  pageInfo: PageInfo;
};

export type TraceDatasetConfiguration = {
  filter: TraceSearchFilter;
};

export type TraceDatasetConfigurationInput = {
  filter: TraceSearchFilterInput;
};

export type TraceDatasetEnhancement = {
  persistedTotalVolumeBytes: Scalars['Float']['output'];
  persistedUniqueVolumeBytes: Scalars['Float']['output'];
  processedTotalVolumeBytes: Scalars['Float']['output'];
  processedUniqueVolumeBytes: Scalars['Float']['output'];
};

export type TraceDatasetEnhancementsInput = {
  metricsTimeRange?: InputMaybe<TimeFilterInput>;
};

export type TraceDiscovery = {
  selectServiceAttribute: Scalars['Boolean']['output'];
};

export type TraceFilter = {
  duration: Maybe<DurationFilter>;
  error: Maybe<BoolFilter>;
};

export type TraceFilterInput = {
  duration?: InputMaybe<DurationFilterInput>;
  error?: InputMaybe<BoolFilterInput>;
};

export type TraceJaegerRemoteSamplingAppliedStrategy =
  | JaegerRemotePerOperationSamplingStrategies
  | JaegerRemoteSamplingProbabilisticStrategy
  | JaegerRemoteSamplingRateLimitingSamplingStrategy;

export type TraceJaegerRemoteSamplingStrategiesListFilterInput = {
  nameOrServiceContains?: InputMaybe<Scalars['String']['input']>;
  names?: InputMaybe<Array<Scalars['String']['input']>>;
  serviceNames?: InputMaybe<Array<Scalars['String']['input']>>;
  slugs?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type TraceJaegerRemoteSamplingStrategy = {
  appliedStrategy: TraceJaegerRemoteSamplingAppliedStrategy;
  createdAt: Scalars['String']['output'];
  lastAccessedAt: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  service: Scalars['String']['output'];
  slug: Scalars['ID']['output'];
  updatedAt: Scalars['String']['output'];
};

export type TraceJaegerRemoteSamplingStrategyConnection = {
  foundStrategies: Array<TraceJaegerRemoteSamplingStrategy>;
  pageInfo: PageInfo;
};

export type TraceMetricsGroupBy = {
  key: TraceMetricsGroupByKey;
  label: Scalars['String']['output'];
};

export type TraceMetricsGroupByInput = {
  key: TraceMetricsGroupByKeyInput;
  label: Scalars['String']['input'];
};

export type TraceMetricsGroupByKey = {
  namedKey: Maybe<Scalars['String']['output']>;
  type: TraceMetricsGroupByKeyType;
};

export type TraceMetricsGroupByKeyInput = {
  namedKey?: InputMaybe<Scalars['String']['input']>;
  type: TraceMetricsGroupByKeyType;
};

export enum TraceMetricsGroupByKeyType {
  OPERATION = 'OPERATION',
  SERVICE = 'SERVICE',
  TAG = 'TAG',
  UNKNOWN = 'UNKNOWN',
}

export type TraceMetricsRule = {
  createdAt: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  groupBys: Maybe<Array<TraceMetricsGroupBy>>;
  histogramBucketsSeconds: Array<Scalars['Float']['output']>;
  managedBy: ManagedBy;
  metricLabels: Array<Label>;
  metricName: Scalars['String']['output'];
  name: Scalars['String']['output'];
  slug: Scalars['ID']['output'];
  source: EditSource;
  traceFilter: TraceSearchFilter;
  updatedAt: Scalars['String']['output'];
  updatedBy: Scalars['String']['output'];
};

/** TraceMetricsRulesConnection is a paginated container of a list of Trace metrics rules. */
export type TraceMetricsRulesConnection = {
  /** Pagination metadata to fetch additional pages of data. */
  pageInfo: PageInfo;
  /** Trace metrics rules for this page. The pageInfo field contains metadata to fetch additional pages. */
  traceMetricsRules: Array<TraceMetricsRule>;
};

export type TraceMetricsRulesListFilterInput = {
  metricNames?: InputMaybe<Array<Scalars['String']['input']>>;
  names?: InputMaybe<Array<Scalars['String']['input']>>;
  slugs?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type TraceSearch = {
  aggregationOptions: Maybe<TraceAggregationOptions>;
  criteria: TraceSearchFilter;
  criticalPathMode: Maybe<Scalars['Boolean']['output']>;
  statisticsGroupBy: Maybe<Scalars['String']['output']>;
  time: TimeFilter;
};

export type TraceSearchFilter = {
  scope: Maybe<ScopeFilter>;
  span: Array<SpanFilter>;
  trace: Maybe<TraceFilter>;
};

export type TraceSearchFilterInput = {
  scope?: InputMaybe<ScopeFilterInput>;
  span: Array<SpanFilterInput>;
  trace?: InputMaybe<TraceFilterInput>;
};

export type TraceSearchHistory = {
  comparison: Maybe<TraceSearch>;
  createdAt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  search: TraceSearch;
};

/** TraceSearchHistoryConnection is a paginated container of a list of trace search history entries. */
export type TraceSearchHistoryConnection = {
  /** Pagination metadata to fetch additional pages of data. */
  pageInfo: PageInfo;
  /** Trace search history for this page. The pageInfo field contains metadata to fetch additional pages. */
  traceSearchHistories: Array<TraceSearchHistory>;
};

export type TraceSearchInput = {
  aggregationOptions?: InputMaybe<TraceAggregationOptionsInput>;
  criteria: TraceSearchFilterInput;
  criticalPathMode?: InputMaybe<Scalars['Boolean']['input']>;
  statisticsGroupBy?: InputMaybe<Scalars['String']['input']>;
  time: TimeFilterInput;
};

export type TraceTailSamplingRule = {
  createdAt: Scalars['String']['output'];
  filter: TraceSearchFilter;
  name: Scalars['String']['output'];
  sampleRate: Scalars['Float']['output'];
  systemName: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
};

export type TraceTailSamplingRules = {
  createdAt: Scalars['String']['output'];
  defaultSampleRate: DefaultSampleRate;
  rules: Array<TraceTailSamplingRule>;
  updatedAt: Scalars['String']['output'];
};

export type TraceTopTag = {
  description: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type TraceTopTagInput = {
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type TraceTopTags = {
  data: Array<TraceTopTag>;
  version: Scalars['Int']['output'];
};

export type TracingLicenseLimits = {
  persistedBytes: Scalars['Float']['output'];
  processedBytes: Scalars['Float']['output'];
};

export type TracingServiceAttributeToServiceInput = {
  tracingServiceAttribute: Array<Scalars['String']['input']>;
};

export type TracingServiceAttributeToServiceMap = {
  tracingServiceMappings: Array<TracingServiceMapping>;
};

export type TracingServiceMapping = {
  serviceDerivedName: Maybe<Scalars['String']['output']>;
  serviceSlug: Maybe<Scalars['String']['output']>;
  tracingServiceAttribute: Array<Scalars['String']['output']>;
};

export type Transform = {
  regex: Scalars['String']['output'];
  replacement: Scalars['String']['output'];
};

export type TransformInput = {
  regex: Scalars['String']['input'];
  replacement: Scalars['String']['input'];
};

export enum UITheme {
  DARK = 'DARK',
  LIGHT = 'LIGHT',
  SYSTEM = 'SYSTEM',
  UNKNOWN = 'UNKNOWN',
}

export type UIThemeInput = {
  uiTheme: UITheme;
};

export type UpdateAlertReceiver = {
  email?: InputMaybe<Array<NewAlertReceiverEmailConfig>>;
  /** Deprecated, use slug when possible */
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  opsgenie?: InputMaybe<Array<NewAlertReceiverOpsGenieConfig>>;
  pagerduty?: InputMaybe<Array<NewAlertReceiverPagerdutyConfig>>;
  slack?: InputMaybe<Array<NewAlertReceiverSlackConfig>>;
  slug?: InputMaybe<Scalars['ID']['input']>;
  victorOps?: InputMaybe<Array<NewAlertReceiverVictorOpsConfig>>;
  webhook?: InputMaybe<Array<NewAlertReceiverWebhookConfig>>;
};

export type UpdateCollection = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  /** Optional slug of notification policy used by this collection */
  policySlug?: InputMaybe<Scalars['String']['input']>;
  /** Required slug for the collection to update. */
  slug: Scalars['String']['input'];
  team_slug?: InputMaybe<Scalars['String']['input']>;
  /** Optional type of collection to update. If unspecified, updates a SIMPLE collection. */
  type?: InputMaybe<CollectionType>;
};

export type UpdateCustomComponentInput = {
  dashboard: Scalars['JSONObject']['input'];
  dryRun?: InputMaybe<Scalars['Boolean']['input']>;
  interval?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  serviceTemplateVar?: InputMaybe<Scalars['String']['input']>;
  slug: Scalars['String']['input'];
};

export type UpdateDatasetInput = {
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
  slug: Scalars['String']['input'];
  traceConfiguration?: InputMaybe<TraceDatasetConfigurationInput>;
};

export type UpdateDiscoveryInput = {
  /** Discovery config for events. */
  events?: InputMaybe<EventDiscoveryInput>;
  /** Discovery config for metrics. */
  metrics?: InputMaybe<MetricsDiscoveryInput>;
  name: Scalars['String']['input'];
  slug: Scalars['String']['input'];
};

export type UpdateDropRule = {
  conditionalRateBasedDrop?: InputMaybe<InputDropRuleConditionalRateBasedDrop>;
  dropNanValue?: InputMaybe<Scalars['Boolean']['input']>;
  filters: Array<LabelFilter>;
  mode: DropRuleMode;
  name: Scalars['String']['input'];
  slug: Scalars['String']['input'];
  valueBasedDrop?: InputMaybe<InputDropRuleValueBasedDrop>;
};

export type UpdateFavoriteInput = {
  entityType: FavoriteEntityType;
  isFavorite: Scalars['Boolean']['input'];
  slug: Scalars['String']['input'];
};

export type UpdateGlobalFiltersInput = {
  filters: Array<TemplateInput>;
};

export type UpdateIngestionPolicy = {
  active: Scalars['Boolean']['input'];
  conditionalDrop?: InputMaybe<Scalars['Boolean']['input']>;
  dropDuration?: InputMaybe<Scalars['String']['input']>;
  dropDurationMins?: InputMaybe<Scalars['Int']['input']>;
  dropIndex: Scalars['Float']['input'];
  dropNanValue?: InputMaybe<Scalars['Boolean']['input']>;
  dropValue?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['ID']['input'];
  isValueBasedDrop?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  query?: InputMaybe<Array<Scalars['String']['input']>>;
  rateLimitThreshold?: InputMaybe<Scalars['Float']['input']>;
  /** Deprecated, use name instead. */
  title: Scalars['String']['input'];
};

export type UpdateLinkTemplateInput = {
  name: Scalars['String']['input'];
  slug: Scalars['ID']['input'];
  urlTemplate: Scalars['String']['input'];
};

export type UpdateMappingRule = {
  aggregationPolicy?: InputMaybe<InputMappingRuleAggregationPolicy>;
  drop: Scalars['Boolean']['input'];
  filters: Array<LabelFilter>;
  mode: MappingRuleMode;
  name: Scalars['String']['input'];
  slug: Scalars['String']['input'];
};

export type UpdatePresentationInput = {
  dashboard?: InputMaybe<PresentationDashboardInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  eligibility?: InputMaybe<PresentationEligibilityInput>;
  events?: InputMaybe<PresentationEventsInput>;
  name: Scalars['String']['input'];
  slug: Scalars['String']['input'];
};

export type UpdateRecordingRule = {
  executionGroup?: InputMaybe<Scalars['String']['input']>;
  expr: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  interval: Scalars['String']['input'];
  labels: Array<NewRecordingRuleLabel>;
  name: Scalars['String']['input'];
};

export type UpdateRollupRule = {
  addMetricTypeLabel: Scalars['Boolean']['input'];
  aggregationType: AggregationType;
  dropRaw: Scalars['Boolean']['input'];
  expansiveMatch: Scalars['Boolean']['input'];
  filters: Array<LabelFilter>;
  graphiteLabelPolicy?: InputMaybe<InputGraphiteLabelPolicy>;
  interval?: InputMaybe<Scalars['String']['input']>;
  labelPolicy?: InputMaybe<InputLabelPolicy>;
  labelReplace?: InputMaybe<Array<InputRollupRuleLabelReplace>>;
  metricName: Scalars['String']['input'];
  metricType: MetricType;
  mode: RollupRuleMode;
  name: Scalars['String']['input'];
  slug: Scalars['String']['input'];
  storagePolicy?: InputMaybe<InputStoragePolicy>;
};

export type UpdateSLOInput = {
  annotations?: InputMaybe<Array<AnnotationInput>>;
  collectionSlug: Scalars['String']['input'];
  collectionType: CollectionType;
  definition: SLODefinitionInput;
  description?: InputMaybe<Scalars['String']['input']>;
  isPreview?: InputMaybe<Scalars['Boolean']['input']>;
  labels?: InputMaybe<Array<LabelInput>>;
  name: Scalars['String']['input'];
  notificationPolicySlug?: InputMaybe<Scalars['String']['input']>;
  signalGrouping?: InputMaybe<NewSignalGrouping>;
  sli: SLIInput;
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateSavedTraceSearchInput = {
  name: Scalars['String']['input'];
  slug: Scalars['ID']['input'];
};

export type UpdateSearchHistoryInput = {
  search: NewSearchHistory;
};

export type UpdateTeam = {
  addedRoles?: InputMaybe<Array<Scalars['String']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  /** Deprecated. Use `addedRoles` and `removedRoles` instead. Only one of `isSysAdmin` and `addedRoles` or `removedRoles` may be set. */
  isSysAdmin?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  removedRoles?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateTraceBehaviorConfigInput = {
  baselineBehaviorSlug?: InputMaybe<Scalars['String']['input']>;
  datasetPriorities?: InputMaybe<Array<Scalars['String']['input']>>;
  mainBehaviorAssignments?: InputMaybe<Array<TraceBehaviorMainAssignmentInput>>;
  overrideBehaviorAssignments?: InputMaybe<Array<TraceBehaviorOverrideAssignmentInput>>;
  previewBehaviorAssignments?: InputMaybe<Array<TraceBehaviorOverrideAssignmentInput>>;
  version: Scalars['Int']['input'];
};

export type UpdateTraceBehaviorInput = {
  baseHeadSampleRate?: InputMaybe<Scalars['Float']['input']>;
  baseTailSampleRate?: InputMaybe<Scalars['Float']['input']>;
  baselineBehaviorOptions?: InputMaybe<TraceBehaviorBaselineOptionsInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  errorSampleOptions?: InputMaybe<TraceBehaviorErrorSampleOptionsInput>;
  fastSampleOptions?: InputMaybe<TraceBehaviorFastSampleOptionsInput>;
  largeTraceSampleOptions?: InputMaybe<TraceBehaviorLargeTraceSampleOptionsInput>;
  name: Scalars['String']['input'];
  slowSampleOptions?: InputMaybe<TraceBehaviorSlowSampleOptionsInput>;
  slug: Scalars['ID']['input'];
  smallTraceSampleOptions?: InputMaybe<TraceBehaviorSmallTraceSampleOptionsInput>;
  type?: InputMaybe<TraceBehaviorType>;
};

export type UpdateTraceMetricsRuleInput = {
  groupBys?: InputMaybe<Array<TraceMetricsGroupByInput>>;
  histogramBucketsSeconds: Array<Scalars['Float']['input']>;
  metricLabels: Array<LabelInput>;
  metricName: Scalars['String']['input'];
  name: Scalars['String']['input'];
  slug: Scalars['ID']['input'];
  traceFilter: TraceSearchFilterInput;
};

export type UpdateTraceTopTagsInput = {
  data: Array<TraceTopTagInput>;
  version: Scalars['Int']['input'];
};

export type UpdatedMonitor = {
  annotations: Array<NewAlertRuleAnnotation>;
  collectionSlug?: InputMaybe<Scalars['String']['input']>;
  collectionType?: InputMaybe<CollectionType>;
  labels: Array<NewAlertRuleLabel>;
  name: Scalars['String']['input'];
  slug: Scalars['String']['input'];
  spec: NewMonitorSpec;
};

/** UpsertCollectionLinksInput is an input type that specifies a list of collection links to create or update. */
export type UpsertCollectionLinksInput = {
  collectionLinks: Array<CollectionLinkInput>;
};

export type UsageStatsConfig = {
  tags: Maybe<Array<Scalars['String']['output']>>;
};

export type User = {
  /** filtered event logs containing actions performed by this user */
  auditLog: EventLogs;
  createdAt: Scalars['String']['output'];
  deactivatedAt: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  inviteURL: Maybe<Scalars['String']['output']>;
  /** whether this user is in the admin role */
  isAdmin: Scalars['Boolean']['output'];
  lastName: Scalars['String']['output'];
  /** firstName + lastName for ACCOUNT types, serviceAccount.name for SERVICE_ACCOUNT types */
  name: Scalars['String']['output'];
  roles: Array<Role>;
  state: UserState;
  teams: Array<Team>;
  token: Maybe<Scalars['String']['output']>;
  type: UserType;
};

export type UserauditLogArgs = {
  pageToken: Scalars['Int']['input'];
};

export type UserSearchHistory = {
  createdAt: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Maybe<Scalars['String']['output']>;
  saved: Scalars['Boolean']['output'];
  search: SearchHistoryQuery;
  type: Maybe<SearchType>;
  updatedAt: Maybe<Scalars['String']['output']>;
  userId: Scalars['String']['output'];
};

export type UserSettings = {
  darkMode: Scalars['Boolean']['output'];
  uiTheme: UITheme;
};

export type UserSettingsInput = {
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserSettingsValueInput = {
  name: Scalars['String']['input'];
  value?: InputMaybe<Scalars['Any']['input']>;
};

export enum UserState {
  ACTIVE = 'ACTIVE',
  DEACTIVATED = 'DEACTIVATED',
  INVITED = 'INVITED',
  PENDING = 'PENDING',
  SYNCED = 'SYNCED',
  UNKNOWN = 'UNKNOWN',
}

export enum UserType {
  ACCOUNT = 'ACCOUNT',
  SERVICE_ACCOUNT = 'SERVICE_ACCOUNT',
  UNKNOWN = 'UNKNOWN',
}

export type ValidationResult = {
  message: Scalars['String']['output'];
  status: ValidationStatus;
};

export enum ValidationStatus {
  INVALID = 'INVALID',
  UNKNOWN = 'UNKNOWN',
  VALID = 'VALID',
}

export type ValueMapping = {
  name: Scalars['String']['output'];
  values: Array<Scalars['String']['output']>;
};

export type eventFilter = {
  description: Scalars['String']['output'];
  queries: Array<Scalars['String']['output']>;
};

export type eventFilters = {
  filters: Array<eventFilter>;
};

export type GetDashboardJSONVariables = Exact<{
  slugs: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
}>;

export type GetDashboardJSON = {
  allDashboards: {
    dashboards: Array<
      { slug: string; config: any; type: 'Dashboard' } | { slug: string; config: any; type: 'GrafanaDashboard' }
    >;
  };
};

export type CreateShortUrlMutationVariables = Exact<{
  input: ShortenMessageRequest;
}>;

export type CreateShortUrlMutation = { createShortenedMessage: { hash: string; message: string } };

export type LoadDashboardTagsVariables = Exact<{ [key: string]: never }>;

export type LoadDashboardTags = { grafanaDashboards: Array<{ slug: string; labels: Array<{ value: string }> | null }> };

export type versionsFragment = {
  nodes: Array<{
    createdAt: string;
    sourceClient: EntityVersionSource;
    id: string;
    config: { terraform: string; chronoctl: string; api: string };
    user: { type: UserType; email: string; name: string } | null;
  }>;
};

export type GetMonitorVersionsVariables = Exact<{
  slug: Scalars['String']['input'];
}>;

export type GetMonitorVersions = {
  entity: {
    versions: {
      nodes: Array<{
        createdAt: string;
        sourceClient: EntityVersionSource;
        id: string;
        config: { terraform: string; chronoctl: string; api: string };
        user: { type: UserType; email: string; name: string } | null;
      }>;
    };
  };
};

export type GetDashboardVersionsVariables = Exact<{
  slug: Scalars['String']['input'];
}>;

export type GetDashboardVersions = {
  entity: {
    versions: {
      nodes: Array<{
        createdAt: string;
        sourceClient: EntityVersionSource;
        id: string;
        config: { terraform: string; chronoctl: string; api: string };
        user: { type: UserType; email: string; name: string } | null;
      }>;
    };
  };
};

export type ExportMonitorConfigVariables = Exact<{
  config: NewMonitor;
}>;

export type ExportMonitorConfig = {
  exportConfig: { config: { dryRunError: string | null; terraform: string; chronoctl: string; api: string } };
};

export type ExportDropRuleConfigVariables = Exact<{
  config: NewDropRule;
}>;

export type ExportDropRuleConfig = {
  exportConfig: { config: { dryRunError: string | null; terraform: string; chronoctl: string; api: string } };
};

export type ExportRollupRuleConfigVariables = Exact<{
  config: NewRollupRule;
}>;

export type ExportRollupRuleConfig = {
  exportConfig: { config: { dryRunError: string | null; terraform: string; chronoctl: string; api: string } };
};

export type ExportCollectionConfigVariables = Exact<{
  config: CreateCollection;
}>;

export type ExportCollectionConfig = {
  exportConfig: { config: { dryRunError: string | null; terraform: string; chronoctl: string; api: string } };
};

export type ExportMappingRuleConfigBySlugVariables = Exact<{
  slug: Scalars['String']['input'];
}>;

export type ExportMappingRuleConfigBySlug = {
  exportConfig: { config: { terraform: string; api: string; chronoctl: string } };
};

export type ExportRollupRuleConfigBySlugVariables = Exact<{
  slug: Scalars['String']['input'];
}>;

export type ExportRollupRuleConfigBySlug = {
  exportConfig: { config: { terraform: string; api: string; chronoctl: string } };
};

export type GetUpdatedPoolConfigurationVariables = Exact<{
  config: NewResourcePools;
}>;

export type GetUpdatedPoolConfiguration = {
  exportConfig: { config: { dryRunError: string | null; terraform: string } };
};

export type ExportDashboardConfigVariables = Exact<{
  config: SaveDashboardInput;
}>;

export type ExportDashboardConfig = {
  exportConfig: { config: { dryRunError: string | null; terraform: string; chronoctl: string; api: string } };
};

export type LoadUserSettingsMenuVariables = Exact<{ [key: string]: never }>;

export type LoadUserSettingsMenu = {
  tenant: { id: string; name: string };
  settings: { buildVersion: string; revision: string };
};

export type LoadNotifiersAutocompleteVariables = Exact<{ [key: string]: never }>;

export type LoadNotifiersAutocomplete = {
  alertReceivers: {
    receivers: Array<{
      id: string;
      slug: string;
      name: string;
      webhook: Array<{ url: string }> | null;
      email: Array<{ to: string }> | null;
      slack: Array<{ channel: string }> | null;
      pagerduty: Array<{ description: string }> | null;
      opsgenie: Array<{ description: string }> | null;
      victorOps: Array<{ routingKey: string }> | null;
    }>;
  };
};

export type LoadAnalyticsVariables = Exact<{ [key: string]: never }>;

export type LoadAnalytics = {
  me: { id: string; email: string; isAdmin: boolean };
  tenant: { id: string; name: string };
  settings: { buildVersion: string };
  userSettings: { darkMode: boolean };
};

export type DarkModeSettingQueryVariables = Exact<{ [key: string]: never }>;

export type DarkModeSettingQuery = { userSettings: { uiTheme: UITheme } };

export type SaveThemePreferenceMutationVariables = Exact<{
  input: UIThemeInput;
}>;

export type SaveThemePreferenceMutation = { setUITheme: boolean };

export type LoadDashboardOptionsVariables = Exact<{
  filter: InputMaybe<AllDashboardsFilter>;
  pageSize: InputMaybe<Scalars['Int']['input']>;
  after: InputMaybe<Scalars['String']['input']>;
}>;

export type LoadDashboardOptions = {
  allDashboards: {
    totalCount: number;
    pageInfo: { hasNextPage: boolean; cursor: string | null };
    dashboards: Array<
      | { __typename: 'Dashboard'; name: string; slug: string }
      | { __typename: 'GrafanaDashboard'; name: string; slug: string }
    >;
  };
};

export type LoadMonitorOptionsVariables = Exact<{
  pageSize: InputMaybe<Scalars['Int']['input']>;
  after: InputMaybe<Scalars['String']['input']>;
  filters: MonitorsV2Filters;
}>;

export type LoadMonitorOptions = {
  monitorsV2: {
    totalCount: number;
    pageInfo: { hasNextPage: boolean; cursor: string | null };
    monitors: Array<{ slug: string; name: string }>;
  };
};

export type LoadCollectionOptionsVariables = Exact<{
  pageSize: InputMaybe<Scalars['Int']['input']>;
  after: InputMaybe<Scalars['String']['input']>;
  filters: InputMaybe<CollectionsFilters>;
}>;

export type LoadCollectionOptions = {
  collections: {
    totalCount: number;
    pageInfo: { hasNextPage: boolean; cursor: string | null };
    collections: Array<{ name: string; slug: string }>;
  };
};

export type DeleteNativeDashboardMutationVariables = Exact<{
  slug: Scalars['String']['input'];
}>;

export type DeleteNativeDashboardMutation = { deleteDashboard: boolean };

export type GetResourcePoolsVariables = Exact<{ [key: string]: never }>;

export type GetResourcePools = {
  resourcePools: {
    defaultPool: {
      allocation: { percentOfLicense: number | null } | null;
      priorities: {
        highPriorityMatchRules: Array<{ name: string; value: string }> | null;
        lowPriorityMatchRules: Array<{ name: string; value: string }> | null;
      } | null;
    } | null;
    pools: Array<{
      name: string;
      matchType: Match;
      allocation: {
        percentOfLicense: number | null;
        fixedValues: Array<{ license: ResourcePoolLicense; value: number }> | null;
      };
      matchRules: Array<{ name: string; value: string }> | null;
      priorities: {
        highPriorityMatchRules: Array<{ name: string; value: string }> | null;
        lowPriorityMatchRules: Array<{ name: string; value: string }> | null;
      } | null;
    }> | null;
  };
};

export type GetSearchHistoryVariables = Exact<{
  userID: InputMaybe<Scalars['String']['input']>;
  after: InputMaybe<Scalars['String']['input']>;
  onlySaved: InputMaybe<Scalars['Boolean']['input']>;
  searchType: InputMaybe<SearchType>;
}>;

export type GetSearchHistory = {
  userSearchHistory: {
    searchHistories: Array<{ id: string; updatedAt: string | null; name: string | null; search: { query: string } }>;
  };
};

export type CreateSearchHistoryVariables = Exact<{
  input: CreateSearchHistoryInput;
}>;

export type CreateSearchHistory = { createUserSearchHistory: { id: string } };

export type DeleteSearchHistoryVariables = Exact<{
  input: DeleteSearchHistoryInput;
}>;

export type DeleteSearchHistory = { deleteUserSearchHistory: boolean };

export type ListTeamsWithCollectionsVariables = Exact<{
  userId: InputMaybe<Scalars['ID']['input']>;
}>;

export type ListTeamsWithCollections = {
  teams: Array<{
    slug: string;
    name: string;
    description: string;
    isAdmin: boolean;
    roles: Array<{ name: string }>;
    collections: { collections: Array<{ slug: string; name: string; description: string }> };
  }>;
};

export type GetUsageStatsConfigVariables = Exact<{ [key: string]: never }>;

export type GetUsageStatsConfig = { usageStatsConfig: { tags: Array<string> | null } | null };

export type CreateTestCollectionVariables = Exact<{
  input: CreateCollection;
}>;

export type CreateTestCollection = { createCollection: { slug: string; name: string } };

export type GetPublicCollectionVariables = Exact<{ [key: string]: never }>;

export type GetPublicCollection = { collection: { slug: string; name: string } | null };

export type DeleteTestCollectionVariables = Exact<{
  input: DeleteCollection;
}>;

export type DeleteTestCollection = { deleteCollection: boolean };

export type CreateTestComponentVariables = Exact<{
  input: CreateCustomComponentInput;
}>;

export type CreateTestComponent = {
  createCustomComponent: { component: { slug: string }; discovery: { slug: string } } | null;
};

export type DeleteTestComponentVariables = Exact<{
  input: DeleteCustomComponentInput;
}>;

export type DeleteTestComponent = { deleteCustomComponent: boolean };

export type CreateTestMonitorVariables = Exact<{
  input: NewMonitor;
}>;

export type CreateTestMonitor = { createMonitor: { slug: string } };

export type DeleteTestMonitorVariables = Exact<{
  input: DeleteMonitor;
}>;

export type DeleteTestMonitor = { deleteMonitor: boolean };

export type CreateTestAlertReceiverVariables = Exact<{
  input: NewAlertReceiver;
}>;

export type CreateTestAlertReceiver = { createAlertReceiver: { slug: string } };

export type DeleteTestAlertReceiverVariables = Exact<{
  input: DeleteAlertReceiver;
}>;

export type DeleteTestAlertReceiver = { deleteAlertReceiver: boolean };

export type CreateTestNotificationPolicyVariables = Exact<{
  input: NewAlertNotificationPolicy;
}>;

export type CreateTestNotificationPolicy = { createAlertNotificationPolicy: { slug: string } };

export type DeleteTestNotificationPolicyVariables = Exact<{
  input: DeleteAlertNotificationPolicy;
}>;

export type DeleteTestNotificationPolicy = { deleteAlertNotificationPolicy: boolean };

export type GetAllTestScopesVariables = Exact<{ [key: string]: never }>;

export type GetAllTestScopes = {
  globalFilters: Array<{ name: string; value: { labelValues: { matcher: string } | null } }>;
};

export type CreateTestScopeVariables = Exact<{
  input: UpdateGlobalFiltersInput;
}>;

export type CreateTestScope = { updateGlobalFilters: Array<{ name: string }> };

export type CreateTestServiceAccountVariables = Exact<{
  input: CreateServiceAccountInput;
}>;

export type CreateTestServiceAccount = { createServiceAccount: { serviceAccount: { id: string; name: string } } };

export type DeleteTestServiceAccountsVariables = Exact<{
  input: DeleteServiceAccountsInput;
}>;

export type DeleteTestServiceAccounts = { deleteServiceAccounts: boolean };

export type CreateTestTeamVariables = Exact<{
  input: NewTeam;
}>;

export type CreateTestTeam = { createTeam: { id: string; slug: string; name: string } };

export type DeleteTestTeamVariables = Exact<{
  input: DeleteTeam;
}>;

export type DeleteTestTeam = { deleteTeam: boolean };

export type GetAllSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllSettingsQuery = {
  settings: { metrics: { retentionPolicies: Array<{ retention: string; resolution: string }> } | null };
};

export type UpsertCollectionLinksMutationVariables = Exact<{
  input: UpsertCollectionLinksInput;
}>;

export type UpsertCollectionLinksMutation = {
  upsertCollectionLinks: Array<{
    slug: string;
    updatedAt: string;
    createdAt: string;
    collection: {
      name: string;
      slug: string;
      type: CollectionType;
      nullableTeam: { name: string; slug: string } | null;
    };
  }>;
};

export type DeleteCollectionLinksMutationVariables = Exact<{
  input: DeleteCollectionLinksInput;
}>;

export type DeleteCollectionLinksMutation = { deleteCollectionLinks: boolean };

export type CollectionMonitorLinksQueryVariables = Exact<{
  pageSize: InputMaybe<Scalars['Int']['input']>;
  after: InputMaybe<Scalars['String']['input']>;
  filter: InputMaybe<CollectionLinkListFilterInput>;
}>;

export type CollectionMonitorLinksQuery = {
  collectionLinks: {
    totalCount: number;
    pageInfo: { cursor: string | null; hasNextPage: boolean };
    collectionLinks: Array<{
      slug: string;
      monitorLink: {
        impactsServiceHealth: boolean;
        monitor: {
          slug: string;
          name: string;
          policy: { explicitReference: boolean; policy: { name: string; slug: string } } | null;
          collection: {
            name: string;
            type: CollectionType;
            slug: string;
            nullableTeam: { id: string; slug: string; name: string } | null;
          };
          state: {
            state: AlertState;
            signals: Array<{
              state: AlertState;
              series: Array<{ status: { status: AlertState; startedAt: string | null } }> | null;
              labels: Array<{ name: string; value: string }> | null;
            }> | null;
          };
        };
      } | null;
    }>;
  };
};

export type CollectionDashboardLinksQueryVariables = Exact<{
  pageSize: InputMaybe<Scalars['Int']['input']>;
  after: InputMaybe<Scalars['String']['input']>;
  filter: InputMaybe<CollectionLinkListFilterInput>;
}>;

export type CollectionDashboardLinksQuery = {
  collectionLinks: {
    totalCount: number;
    pageInfo: { cursor: string | null; hasNextPage: boolean };
    collectionLinks: Array<{
      slug: string;
      dashboardLink: {
        dashboard:
          | {
              __typename: 'Dashboard';
              name: string;
              slug: string;
              source: EditSource;
              isFavorite: boolean;
              labels: Array<{ name: string; value: string }> | null;
              collection: {
                name: string;
                type: CollectionType;
                slug: string;
                nullableTeam: { id: string; name: string; slug: string } | null;
              } | null;
            }
          | {
              __typename: 'GrafanaDashboard';
              name: string;
              slug: string;
              source: EditSource;
              isFavorite: boolean;
              labels: Array<{ name: string; value: string }> | null;
              collection: {
                name: string;
                type: CollectionType;
                slug: string;
                nullableTeam: { id: string; name: string; slug: string } | null;
              } | null;
            };
      } | null;
    }>;
  };
};

export type ListTeamCollectionsVariables = Exact<{
  teamSlug: InputMaybe<Scalars['String']['input']>;
  pageSize: InputMaybe<Scalars['Int']['input']>;
  after: InputMaybe<Scalars['String']['input']>;
  collectionsFilters: InputMaybe<CollectionsFilters>;
}>;

export type ListTeamCollections = {
  team: {
    collections: {
      totalCount: number;
      pageInfo: { hasNextPage: boolean; cursor: string | null };
      collections: Array<{
        name: string;
        slug: string;
        type: CollectionType;
        nullableTeam: { name: string; slug: string } | null;
      }>;
    };
  } | null;
};

export type ListCollectionsVariables = Exact<{
  pageSize: InputMaybe<Scalars['Int']['input']>;
  after: InputMaybe<Scalars['String']['input']>;
  filters: InputMaybe<CollectionsFilters>;
}>;

export type ListCollections = {
  collections: {
    totalCount: number;
    pageInfo: { hasNextPage: boolean; cursor: string | null };
    collections: Array<{
      name: string;
      slug: string;
      type: CollectionType;
      description: string;
      managedBy: { editable: boolean; source: ManagedBySource };
      nullableTeam: { name: string; slug: string } | null;
      policy: { slug: string } | null;
    }>;
  };
};

export type ListCollectionHomepageVariables = Exact<{
  collectionSlug: Scalars['String']['input'];
}>;

export type ListCollectionHomepage = {
  collection: {
    name: string;
    type: CollectionType;
    slug: string;
    description: string;
    managedBy: { editable: boolean; source: ManagedBySource };
    policy: { name: string; slug: string } | null;
    homepage: { links: Array<{ name: string; url: string }> | null } | null;
    nullableTeam: { name: string; slug: string } | null;
  } | null;
};

export type UpdateCollectionHomepageVariables = Exact<{
  input: SaveCollectionHomepageInput;
}>;

export type UpdateCollectionHomepage = { saveCollectionHomepage: { slug: string } };

export type CreateCollectionMutationVariables = Exact<{
  input: CreateCollection;
}>;

export type CreateCollectionMutation = { createCollection: { slug: string } };

export type UpdateCollectionMutationVariables = Exact<{
  input: UpdateCollection;
}>;

export type UpdateCollectionMutation = { updateCollection: { slug: string } };

export type DeleteCollectionMutationVariables = Exact<{
  input: DeleteCollection;
}>;

export type DeleteCollectionMutation = { deleteCollection: boolean };

export type ListAllDashboardsVariables = Exact<{
  filter: InputMaybe<AllDashboardsFilter>;
  pageSize: InputMaybe<Scalars['Int']['input']>;
  after: InputMaybe<Scalars['String']['input']>;
  fetchUpdateProps?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type ListAllDashboards = {
  allDashboards: {
    totalCount: number;
    pageInfo: { hasNextPage: boolean; cursor: string | null };
    dashboards: Array<
      | {
          __typename: 'Dashboard';
          config?: any;
          name: string;
          slug: string;
          isFavorite: boolean;
          labels: Array<{ name: string; value: string }> | null;
          managedBy: { editable: boolean; source: ManagedBySource };
          collection: {
            name: string;
            type: CollectionType;
            slug: string;
            nullableTeam: { id: string; name: string; slug: string } | null;
          } | null;
        }
      | {
          __typename: 'GrafanaDashboard';
          name: string;
          slug: string;
          isFavorite: boolean;
          labels: Array<{ name: string; value: string }> | null;
          managedBy: { editable: boolean; source: ManagedBySource };
          collection: {
            name: string;
            type: CollectionType;
            slug: string;
            nullableTeam: { id: string; name: string; slug: string } | null;
          } | null;
        }
    >;
  };
};

export type SaveDashboardVariables = Exact<{
  slug: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  collectionSlug: Scalars['String']['input'];
  collectionType: InputMaybe<CollectionType>;
  dashboardJSON: Scalars['JSONObject']['input'];
  labels: InputMaybe<Array<LabelInput> | LabelInput>;
}>;

export type SaveDashboard = { saveDashboard: { slug: string } };

export type DashboardBySlugVariables = Exact<{
  slug: Scalars['String']['input'];
}>;

export type DashboardBySlug = {
  grafanaDashboard: {
    slug: string;
    name: string;
    isFavorite: boolean;
    dashboardJSON: string;
    managedBy: { editable: boolean; source: ManagedBySource };
    collection: {
      name: string;
      slug: string;
      type: CollectionType;
      nullableTeam: { id: string; name: string; slug: string } | null;
    } | null;
    collectionLinks: Array<{
      slug: string;
      collection: {
        name: string;
        slug: string;
        type: CollectionType;
        nullableTeam: { name: string; slug: string } | null;
      };
    }> | null;
  } | null;
};

export type SaveDashboardSettingsVariables = Exact<{
  input: GrafanaDashboardSettings;
}>;

export type SaveDashboardSettings = { saveGrafanaDashboardSettings: { slug: string } };

export type ListAllDashboardsForSelectVariables = Exact<{
  filter: InputMaybe<AllDashboardsFilter>;
}>;

export type ListAllDashboardsForSelect = {
  allDashboards: { dashboards: Array<{ name: string; slug: string } | { name: string; slug: string }> };
};

export type GetDashboardConfigVariables = Exact<{
  slug: Scalars['String']['input'];
}>;

export type GetDashboardConfig = { dashboard: { config: any } };

export type UpdateFavoritesMutationVariables = Exact<{
  input: UpdateFavoriteInput;
}>;

export type UpdateFavoritesMutation = { updateFavorite: boolean };

export type GetMonitorOwnershipVariables = Exact<{
  monitorSlug: Scalars['String']['input'];
}>;

export type GetMonitorOwnership = {
  monitor: {
    slug: string;
    collection: { name: string; slug: string; nullableTeam: { id: string; name: string; slug: string } | null };
  };
};

export type ListMonitorsQueryVariables = Exact<{
  pageSize: InputMaybe<Scalars['Int']['input']>;
  after: InputMaybe<Scalars['String']['input']>;
  filters: MonitorsV2Filters;
  fetchConnections?: InputMaybe<Scalars['Boolean']['input']>;
  fetchUpdateProps?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type ListMonitorsQuery = {
  monitorsV2: {
    totalCount: number;
    pageInfo: { hasNextPage: boolean; cursor: string | null };
    monitors: Array<{
      slug: string;
      name: string;
      isFavorite: boolean;
      annotations?: Array<{ name: string; value: string }> | null;
      labels?: Array<{ name: string; value: string }> | null;
      managedBy: { editable: boolean; source: ManagedBySource };
      spec: {
        interval?: string;
        schedule?: {
          timezone: string;
          weeklySchedule: Array<{ ranges: Array<{ start: string; end: string }> } | null> | null;
        } | null;
        signalGrouping: { labelNames: Array<string> | null; signalPerSeries: boolean };
        query?: { queryExpression: string; queryDatasource: DatasourceType };
        seriesConditions?: {
          defaults: Array<{
            conditionLevel: ConditionLevel;
            conditions: Array<{ op: ThresholdOp; value: number; sustain: string; resolveSustain: string }> | null;
          }> | null;
          overrides: Array<{
            conditions: Array<{
              conditionLevel: ConditionLevel;
              conditions: Array<{ op: ThresholdOp; value: number; sustain: string; resolveSustain: string }> | null;
            }> | null;
            matchers: Array<{ name: string; type: AlertRouteMatcherType; value: string }> | null;
          }> | null;
        };
      };
      policy: { explicitReference: boolean; policy: { name: string; slug: string } } | null;
      collection: {
        name: string;
        type: CollectionType;
        slug: string;
        nullableTeam: { id: string; slug: string; name: string } | null;
      };
      collectionLinks?: Array<{
        collection: { slug: string; type: CollectionType };
        monitorLink: { impactsServiceHealth: boolean } | null;
      }> | null;
      state: {
        state: AlertState;
        signals: Array<{
          state: AlertState;
          series: Array<{ status: { status: AlertState; startedAt: string | null } }> | null;
          labels: Array<{ name: string; value: string }> | null;
        }> | null;
      };
    }>;
  };
};

export type BasicListMonitorsQueryVariables = Exact<{
  pageSize: InputMaybe<Scalars['Int']['input']>;
  after: InputMaybe<Scalars['String']['input']>;
  filters: MonitorsV2Filters;
}>;

export type BasicListMonitorsQuery = {
  monitorsV2: {
    totalCount: number;
    pageInfo: { hasNextPage: boolean; cursor: string | null };
    monitors: Array<{ slug: string; name: string; isFavorite: boolean }>;
  };
};

export type NotifiersAutocompleteValue = {
  id: string;
  slug: string;
  name: string;
  webhook: Array<{ url: string }> | null;
  email: Array<{ to: string }> | null;
  slack: Array<{ channel: string }> | null;
  pagerduty: Array<{ description: string }> | null;
  opsgenie: Array<{ description: string }> | null;
  victorOps: Array<{ routingKey: string }> | null;
};

export type ListNotificationPoliciesQueryVariables = Exact<{
  pageSize: InputMaybe<Scalars['Int']['input']>;
  after: InputMaybe<Scalars['String']['input']>;
  filters: NotificationPoliciesPageFilters;
}>;

export type ListNotificationPoliciesQuery = {
  notificationPoliciesPage: {
    totalCount: number;
    pageInfo: { hasNextPage: boolean; cursor: string | null };
    notificationPolicies: Array<{
      slug: string;
      name: string;
      managedBy: { editable: boolean; source: ManagedBySource };
      team: { name: string; slug: string } | null;
      routes: {
        defaults: Array<{
          severity: string;
          repeatInterval: string;
          notifierList: Array<{ slug: string; name: string; notifierConfig: { slug: string; name: string } }>;
        }>;
        overrides: Array<{
          matchers: Array<{ name: string; value: string; type: AlertRouteMatcherType }>;
          notifiers: Array<{
            severity: string;
            repeatInterval: string;
            notifierList: Array<{ slug: string; name: string; notifierConfig: { slug: string; name: string } }>;
          }>;
        }>;
      };
    }>;
  };
};

export type GetNotificationPolicyQueryVariables = Exact<{
  slug: Scalars['String']['input'];
}>;

export type GetNotificationPolicyQuery = {
  notificationPolicy: {
    slug: string;
    name: string;
    managedBy: { editable: boolean; source: ManagedBySource };
    team: { name: string; slug: string } | null;
    routes: {
      defaults: Array<{
        severity: string;
        repeatInterval: string;
        groupBy: Array<string> | null;
        notifierList: Array<{
          slug: string;
          name: string;
          notifierConfig: {
            id: string;
            slug: string;
            name: string;
            webhook: Array<{ url: string }> | null;
            email: Array<{ to: string }> | null;
            slack: Array<{ channel: string }> | null;
            pagerduty: Array<{ description: string }> | null;
            opsgenie: Array<{ description: string }> | null;
            victorOps: Array<{ routingKey: string }> | null;
          };
        }>;
      }>;
      overrides: Array<{
        matchers: Array<{ name: string; value: string; type: AlertRouteMatcherType }>;
        notifiers: Array<{
          severity: string;
          repeatInterval: string;
          groupBy: Array<string> | null;
          notifierList: Array<{
            slug: string;
            name: string;
            notifierConfig: {
              id: string;
              slug: string;
              name: string;
              webhook: Array<{ url: string }> | null;
              email: Array<{ to: string }> | null;
              slack: Array<{ channel: string }> | null;
              pagerduty: Array<{ description: string }> | null;
              opsgenie: Array<{ description: string }> | null;
              victorOps: Array<{ routingKey: string }> | null;
            };
          }>;
        }>;
      }>;
    };
  };
};

export type CreateNotificationPolicyMutationVariables = Exact<{
  input: NewAlertNotificationPolicy;
}>;

export type CreateNotificationPolicyMutation = {
  createAlertNotificationPolicy: { slug: string; rules: Array<{ name: string }> };
};

export type UpdateNotificationPolicyMutationVariables = Exact<{
  input: NewAlertNotificationPolicy;
}>;

export type UpdateNotificationPolicyMutation = { updateAlertNotificationPolicy: { rules: Array<{ name: string }> } };

export type DeleteNotificationPolicyMutationVariables = Exact<{
  input: DeleteAlertNotificationPolicy;
}>;

export type DeleteNotificationPolicyMutation = { deleteAlertNotificationPolicy: boolean };

export type AvailableScopesQueryVariables = Exact<{ [key: string]: never }>;

export type AvailableScopesQuery = {
  globalFilters: Array<{ name: string; defaultValue: string; value: { labelValues: { matcher: string } | null } }>;
};

export type UpdateScopeConfigurationMutationVariables = Exact<{
  input: UpdateGlobalFiltersInput;
}>;

export type UpdateScopeConfigurationMutation = { updateGlobalFilters: Array<{ name: string }> };

export type SearchVariables = Exact<{
  input: SearchQuery;
}>;

export type Search = {
  searchV2: {
    totalCount: number | null;
    items: Array<{
      type: string;
      name: string;
      slug: string;
      isFavorite: boolean;
      isMigratedDashboard: boolean;
      team: { name: string; slug: string } | null;
      collection: { name: string; type: CollectionType; slug: string } | null;
    }>;
  } | null;
};

export type ObjectMergeRulesVariables = Exact<{ [key: string]: never }>;

export type ObjectMergeRules = {
  objectMergeRules: Array<{
    status: ObjectMergeRuleStatus;
    action: ObjectMergeRuleAction;
    description: string;
    regex: string | null;
    replacement: string | null;
    sourceLabels: Array<string> | null;
    suppressionRule: { suppressedDiscoveryComponentSlug: string; exceptionServiceNames: Array<string> | null } | null;
  }>;
};

export type GetComponentDiscoveryConfigsVariables = Exact<{ [key: string]: never }>;

export type GetComponentDiscoveryConfigs = {
  componentDiscoveryConfigs: Array<{
    slug: string;
    name: string;
    discovery: {
      metric: {
        objectLabel: string;
        objectMappingLabel: string;
        uniqueLabel: string;
        expr: string;
        objectMappingLabelTransform: { regex: string; replacement: string } | null;
        target: { filters: Array<{ name: string; valueGlob: string }> } | null;
      } | null;
      trace: { selectServiceAttribute: boolean } | null;
      event: { serviceNameLabel: string } | null;
    };
    discoveryOverride: {
      status: ComponentDiscoveryOverrideStatus | null;
      metric: {
        objectLabel: string;
        objectMappingLabel: string;
        uniqueLabel: string;
        objectMappingLabelTransform: { regex: string; replacement: string } | null;
      } | null;
    } | null;
  }>;
};

export type ListComponentPresentationConfigsVariables = Exact<{ [key: string]: never }>;

export type ListComponentPresentationConfigs = {
  listComponentPresentationConfigs: Array<{
    name: string;
    slug: string;
    description: string | null;
    presentationType: ComponentPresentationType;
    managedBy: ManagedByType;
    events: { eventJSONs: Array<any> | null } | null;
    dashboard: { panelCount: number } | null;
    override: { status: ComponentPresentationOverrideStatus; componentPresentationSlug: string } | null;
  }>;
};

export type ComponentPresentationDetailVariables = Exact<{
  input: ReadPresentationConfigInput;
}>;

export type ComponentPresentationDetail = {
  componentPresentationConfig: {
    slug: string;
    name: string;
    description: string | null;
    presentationType: ComponentPresentationType;
    managedBy: ManagedByType;
    eligibility: {
      requiredDiscoverySlugs: Array<string>;
      withoutDiscoverySlugs: Array<string> | null;
      allowedServiceSlugs: Array<string> | null;
      ignoredServiceSlugs: Array<string> | null;
    };
    override: { componentPresentationSlug: string; status: ComponentPresentationOverrideStatus } | null;
    dashboard: {
      panelJSONs: Array<any> | null;
      panelCount: number;
      serviceTemplateVar: string | null;
      inputJSON: any | null;
      templates: Array<{
        name: string;
        defaultValue: string;
        value: {
          list: { __typename: 'StaticList'; values: Array<string> } | null;
          labelNames: { __typename: 'LabelNames'; matchers: Array<string> } | null;
          labelValues: { __typename: 'LabelValues'; labelName: string; matchers: Array<string> } | null;
          promQL: { __typename: 'PromQL'; labelName: string; expr: string } | null;
        };
      }>;
    } | null;
  } | null;
};

export type ObjectDiscoveryRulesVariables = Exact<{ [key: string]: never }>;

export type ObjectDiscoveryRules = {
  objectDiscoveryRules: Array<{
    slug: string;
    name: string;
    metric: { effectiveExpr: string; objectLabels: Array<string>; objectMappingLabel: string } | null;
  }>;
};

export type CreateCustomDiscoveryJobVariables = Exact<{
  input: CreateCustomComponentInput;
}>;

export type CreateCustomDiscoveryJob = {
  createCustomComponent: { component: { slug: string }; discovery: { slug: string } } | null;
};

export type DeleteCustomComponentVariables = Exact<{
  input: DeleteCustomComponentInput;
}>;

export type DeleteCustomComponent = { deleteCustomComponent: boolean };

export type CreateCustomDiscoveryVariables = Exact<{
  input: CreateDiscoveryInput;
}>;

export type CreateCustomDiscovery = { createDiscovery: { slug: string } };

export type GetServiceComponentPresentationVariables = Exact<{
  serviceComponentSlug: Scalars['String']['input'];
}>;

export type GetServiceComponentPresentation = {
  customComponent: {
    name: string;
    slug: string;
    serviceTemplateVar: string | null;
    interval: string | null;
    inputJSON: any | null;
  };
};

export type UpdateCustomServiceComponentVariables = Exact<{
  input: UpdateCustomComponentInput;
}>;

export type UpdateCustomServiceComponent = {
  updateCustomComponent: { component: { slug: string }; discovery: { slug: string } } | null;
};

export type ServiceViewAttributes = {
  eventsConfig: Array<any> | null;
  inactive: boolean | null;
  hasChangeEvents: boolean | null;
  service: {
    name: string;
    derivedName: string;
    slug: string;
    collectionSlug: string;
    description: string;
    policy: { name: string; slug: string } | null;
    team: { name: string; slug: string } | null;
  };
  labelsConfig: Array<{
    name: string;
    traces: { nameMapping: string | null; valueMapping: Array<{ name: string; values: Array<string> }> | null } | null;
    events: { nameMapping: string | null; valueMapping: Array<{ name: string; values: Array<string> }> | null } | null;
  }> | null;
  oemLoggingConfig: { repo: string; fieldName: string } | null;
  components: Array<{
    name: string;
    slug: string;
    moreDetailsJSON: any | null;
    panelJSONs: Array<any> | null;
    templates: Array<{
      name: string;
      value: {
        labelNames: { __typename: 'LabelNames'; matchers: Array<string> } | null;
        labelValues: { __typename: 'LabelValues'; matchers: Array<string> } | null;
        list: { __typename: 'StaticList'; values: Array<string> } | null;
        promQL: { __typename: 'PromQL'; labelName: string; expr: string } | null;
      };
    }>;
    panels: Array<{
      name: string;
      attributes: { panelType: string; unit: string | null } | null;
      queries: Array<{ query: string; seriesNameFormat: string | null }>;
    }>;
  }> | null;
};

export type ListPaginatedServicesVariables = Exact<{
  getFullView?: InputMaybe<Scalars['Boolean']['input']>;
  getDiscoveries?: InputMaybe<Scalars['Boolean']['input']>;
  filters: InputMaybe<ServicesFilter>;
  pageSize: InputMaybe<Scalars['Int']['input']>;
  after: InputMaybe<Scalars['String']['input']>;
}>;

export type ListPaginatedServices = {
  servicesPage: {
    totalCount: number;
    pageInfo: { hasNextPage: boolean; cursor: string | null };
    services: Array<{
      name: string;
      slug: string;
      derivedName: string;
      collectionSlug: string;
      createdAt: string;
      team: { name: string; slug: string } | null;
      view?: {
        inactive: boolean | null;
        hasChangeEvents: boolean | null;
        hasTraces: boolean;
        eventsConfig: Array<any> | null;
        components: Array<{
          name: string;
          slug: string;
          panels: Array<{ name: string; attributes: { panelType: string } | null }>;
        }> | null;
      };
      discoveries?: Array<{
        slug: string;
        name: string;
        metric: { sloMetadata: Array<{ sloType: SLOType } | { sloType: SLOType }> | null } | null;
      }> | null;
    }>;
  };
};

export type ListPaginatedServicesAlertVariables = Exact<{
  filters: InputMaybe<ServicesFilter>;
  pageSize: InputMaybe<Scalars['Int']['input']>;
  after: InputMaybe<Scalars['String']['input']>;
}>;

export type ListPaginatedServicesAlert = {
  servicesPage: {
    totalCount: number;
    pageInfo: { hasNextPage: boolean; cursor: string | null };
    services: Array<{
      name: string;
      slug: string;
      derivedName: string;
      collectionSlug: string;
      createdAt: string;
      view: { inactive: boolean | null };
    }>;
  };
};

export type ServiceOverviewVariables = Exact<{
  serviceSlug: Scalars['String']['input'];
}>;

export type ServiceOverview = {
  serviceView: {
    eventsConfig: Array<any> | null;
    inactive: boolean | null;
    hasChangeEvents: boolean | null;
    service: {
      name: string;
      derivedName: string;
      slug: string;
      collectionSlug: string;
      description: string;
      policy: { name: string; slug: string } | null;
      team: { name: string; slug: string } | null;
    };
    labelsConfig: Array<{
      name: string;
      traces: {
        nameMapping: string | null;
        valueMapping: Array<{ name: string; values: Array<string> }> | null;
      } | null;
      events: {
        nameMapping: string | null;
        valueMapping: Array<{ name: string; values: Array<string> }> | null;
      } | null;
    }> | null;
    oemLoggingConfig: { repo: string; fieldName: string } | null;
    components: Array<{
      name: string;
      slug: string;
      moreDetailsJSON: any | null;
      panelJSONs: Array<any> | null;
      templates: Array<{
        name: string;
        value: {
          labelNames: { __typename: 'LabelNames'; matchers: Array<string> } | null;
          labelValues: { __typename: 'LabelValues'; matchers: Array<string> } | null;
          list: { __typename: 'StaticList'; values: Array<string> } | null;
          promQL: { __typename: 'PromQL'; labelName: string; expr: string } | null;
        };
      }>;
      panels: Array<{
        name: string;
        attributes: { panelType: string; unit: string | null } | null;
        queries: Array<{ query: string; seriesNameFormat: string | null }>;
      }>;
    }> | null;
  } | null;
};

export type ServiceComponentDetailsViewVariables = Exact<{
  serviceComponentSlug: Scalars['String']['input'];
}>;

export type ServiceComponentDetailsView = {
  serviceComponentViewDetails: {
    eventsConfig: Array<any> | null;
    hasChangeEvents: boolean | null;
    component: { name: string; type: ComponentType; moreDetailsJSON: any | null };
  };
};

export type ListPaginatedServiceSlugsVariables = Exact<{
  filters: InputMaybe<ServicesFilter>;
  pageSize: InputMaybe<Scalars['Int']['input']>;
  after: InputMaybe<Scalars['String']['input']>;
}>;

export type ListPaginatedServiceSlugs = {
  servicesPage: {
    totalCount: number;
    pageInfo: { hasNextPage: boolean; cursor: string | null };
    services: Array<{ name: string; slug: string }>;
  };
};

export type GetSloListVariables = Exact<{
  pageSize: InputMaybe<Scalars['Int']['input']>;
  after: InputMaybe<Scalars['String']['input']>;
  filter: SLOListFilterInput;
}>;

export type GetSloList = {
  slos: {
    totalCount: number;
    pageInfo: { cursor: string | null; hasNextPage: boolean };
    slos: Array<{
      name: string;
      slug: string;
      state: AlertState;
      collection:
        | { name: string; slug: string; type: CollectionType; team: { name: string; slug: string } | null }
        | { name: string; slug: string; type: CollectionType; team: { name: string; slug: string } | null };
      definition: { objective: number } | null;
      signalGrouping: { labelNames: Array<string> | null; signalPerSeries: boolean } | null;
    }>;
  };
};

export type GetSloDetailsVariables = Exact<{
  slug: Scalars['String']['input'];
}>;

export type GetSloDetails = {
  slo: {
    createdAt: string;
    description: string | null;
    id: string;
    isPreview: boolean | null;
    name: string;
    slug: string;
    state: AlertState;
    annotations: Array<{ name: string; value: string }>;
    collection:
      | {
          __typename: 'Collection';
          name: string;
          slug: string;
          type: CollectionType;
          policy: { name: string; slug: string } | null;
          team: { name: string; slug: string } | null;
        }
      | {
          __typename: 'Service';
          derivedName: string;
          name: string;
          slug: string;
          type: CollectionType;
          policy: { name: string; slug: string } | null;
          team: { name: string; slug: string } | null;
          view: {
            eventsConfig: Array<any> | null;
            hasChangeEvents: boolean | null;
            labelsConfig: Array<{
              tracing: { valueMapping: Array<{ name: string; values: Array<string> }> | null } | null;
            }> | null;
            oemLoggingConfig: { fieldName: string; repo: string } | null;
          };
        };
    definition: {
      lowVolume: boolean;
      objective: number;
      reportingWindows: Array<{ errorRatioMetric: string; duration: string }> | null;
    } | null;
    monitors: Array<{ slug: string }>;
    notificationPolicy: { name: string; slug: string } | null;
    relatedQueries: { percentErrorsByEndpoint: string | null; totalRequests: string | null } | null;
    signalGrouping: { labelNames: Array<string> | null; signalPerSeries: boolean } | null;
    sli: {
      customDimensionLabels: Array<string> | null;
      rawQueries: { badQuery: string | null; goodQuery: string | null; totalQuery: string } | null;
      templateData: {
        componentDiscoverySlug: string | null;
        endpointLabel: string;
        components: Array<{
          moreDetailsJSON: any | null;
          name: string;
          panelJSONs: Array<any> | null;
          slug: string;
          panels: Array<{
            name: string;
            attributes: { panelType: string; unit: string | null } | null;
            queries: Array<{ query: string; seriesNameFormat: string | null }>;
          }>;
          templates: Array<{
            name: string;
            value: {
              labelNames: { __typename: 'LabelNames'; matchers: Array<string> } | null;
              labelValues: { __typename: 'LabelValues'; matchers: Array<string> } | null;
              list: { __typename: 'StaticList'; values: Array<string> } | null;
              promQL: { __typename: 'PromQL'; expr: string; labelName: string } | null;
            };
          }>;
        }> | null;
        endpointAvailability: {
          endpointsMonitored: Array<string> | null;
          errorCodes: Array<string> | null;
          additionalPromFilters: Array<{ name: string; type: LabelMatcherType; value: string }> | null;
        } | null;
        endpointLatency: {
          endpointsMonitored: Array<string> | null;
          latencyBucket: string;
          additionalPromFilters: Array<{ name: string; type: LabelMatcherType; value: string }> | null;
        } | null;
        sourceService:
          | { __typename: 'Collection'; name: string; slug: string }
          | { __typename: 'Service'; derivedName: string; name: string; slug: string };
      } | null;
    };
  };
};

export type CreateSLOMutationVariables = Exact<{
  input: UpdateSLOInput;
}>;

export type CreateSLOMutation = { createSLO: { slug: string } };

export type UpdateSLOMutationVariables = Exact<{
  input: UpdateSLOInput;
}>;

export type UpdateSLOMutation = { updateSLO: { slug: string } };

export type DeleteSloMutationVariables = Exact<{
  input: DeleteSLOInput;
}>;

export type DeleteSloMutation = { deleteSLO: boolean };

export type ServiceDiscoveryMetaVariables = Exact<{
  serviceSlug: Scalars['String']['input'];
}>;

export type ServiceDiscoveryMeta = {
  serviceView: {
    service: {
      discoveries: Array<{
        slug: string;
        name: string;
        metric: {
          sloMetadata: Array<
            | {
                endpointLabel: string;
                errorTypeLabel: string;
                sloType: SLOType;
                allErrorCodes: Array<{ displayName: string | null; isSuccess: boolean | null; value: string }> | null;
              }
            | {
                endpointLabel: string;
                errorTypeLabel: string;
                sloType: SLOType;
                allErrorCodes: Array<{ displayName: string | null; isSuccess: boolean | null; value: string }> | null;
              }
          > | null;
        } | null;
      }> | null;
    };
  } | null;
};

export type ListTeamsVariables = Exact<{ [key: string]: never }>;

export type ListTeams = {
  teams: Array<{
    id: string;
    slug: string;
    name: string;
    description: string;
    isAdmin: boolean;
    managedBy: { editable: boolean; source: ManagedBySource };
    roles: Array<{ name: string }>;
  }>;
};

export type ListTeamHomepageVariables = Exact<{
  teamSlug: Scalars['String']['input'];
}>;

export type ListTeamHomepage = {
  team: {
    id: string;
    name: string;
    slug: string;
    description: string;
    isAdmin: boolean;
    managedBy: { editable: boolean; source: ManagedBySource };
    roles: Array<{ name: string }>;
    users: Array<{
      id: string;
      email: string;
      name: string;
      type: UserType;
      state: UserState;
      roles: Array<{ name: string }>;
    }>;
    homepage: { links: Array<{ name: string; url: string }> | null } | null;
    policies: Array<{ name: string; slug: string; team: { name: string; slug: string } | null }>;
  } | null;
};

export type CreateTeamMutationVariables = Exact<{
  input: NewTeam;
}>;

export type CreateTeamMutation = { createTeam: { id: string; slug: string } };

export type UpdateTeamMutationVariables = Exact<{
  input: UpdateTeam;
}>;

export type UpdateTeamMutation = { updateTeam: { id: string } };

export type DeleteTeamMutationVariables = Exact<{
  input: DeleteTeam;
}>;

export type DeleteTeamMutation = { deleteTeam: boolean };

export type UpdateTeamHomepageVariables = Exact<{
  input: SaveTeamHomepageInput;
}>;

export type UpdateTeamHomepage = { saveTeamHomepage: { slug: string } };

export type BulkUpdateTeamMutationVariables = Exact<{
  input: BulkUpdateTeam;
}>;

export type BulkUpdateTeamMutation = { bulkUpdateTeam: { addedIDs: Array<string>; removedIDs: Array<string> } };

export type UserSettingsQueryVariables = Exact<{
  name: Scalars['String']['input'];
}>;

export type UserSettingsQuery = { userSettingsValue: any | null };

export type UpdateUserSettingsMutationVariables = Exact<{
  input: UserSettingsValueInput;
}>;

export type UpdateUserSettingsMutation = { setUserSettingsValue: boolean };

export type LoadUsersVariables = Exact<{ [key: string]: never }>;

export type LoadUsers = {
  users: Array<{ __typename: 'User'; id: string; email: string; state: UserState; roles: Array<{ name: string }> }>;
  serviceAccounts: Array<{
    __typename: 'ServiceAccount';
    id: string;
    name: string;
    email: string;
    roles: Array<{ name: string }>;
  }>;
};

export type LoadUsersSidebarVariables = Exact<{
  excludeServiceAccounts: Scalars['Boolean']['input'];
}>;

export type LoadUsersSidebar = {
  users: Array<{ __typename: 'User'; id: string; email: string; isAdmin: boolean; state: UserState }>;
  serviceAccounts?: Array<{ __typename: 'ServiceAccount'; id: string; name: string; email: string; isAdmin: boolean }>;
};

export type LoadUserDetailsVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;

export type LoadUserDetails = {
  user: {
    id: string;
    email: string;
    state: UserState;
    inviteURL: string | null;
    token: string | null;
    name: string;
    type: UserType;
    roles: Array<{ name: string }>;
    teams: Array<{ id: string; name: string; slug: string; roles: Array<{ name: string }> }>;
    auditLog: { items: Array<{ id: string; eventCode: string; eventTime: string; eventDescription: string }> | null };
  } | null;
};

export type CreatePersonalAccessTokenMutationVariables = Exact<{
  input: CreatePersonalAccessTokenInput;
}>;

export type CreatePersonalAccessTokenMutation = {
  createPersonalAccessToken: {
    token: string;
    metadata: { name: string; expiresAt: string; slug: string; status: string; createdAt: string };
  };
};

export type DeletePersonalAccessTokensMutationVariables = Exact<{
  input: DeletePersonalAccessTokensInput;
}>;

export type DeletePersonalAccessTokensMutation = { deletePersonalAccessTokens: boolean };

export type RevokeAllPersonalAccessTokensMutationVariables = Exact<{ [key: string]: never }>;

export type RevokeAllPersonalAccessTokensMutation = { revokeAllPersonalAccessTokens: boolean };

export type LoadMyAccountVariables = Exact<{ [key: string]: never }>;

export type LoadMyAccount = { me: { id: string; email: string }; tenant: { id: string; name: string } };

export type PersonalAccessTokensListVariables = Exact<{ [key: string]: never }>;

export type PersonalAccessTokensList = {
  personalAccessTokens: Array<{ name: string; slug: string; status: string; createdAt: string; expiresAt: string }>;
};

export type GetRollupRulesVariables = Exact<{ [key: string]: never }>;

export type GetRollupRules = {
  rollupRules: Array<{
    addMetricTypeLabel: boolean;
    aggregationType: AggregationType;
    createdAt: string;
    dropRaw: boolean;
    expansiveMatch: boolean;
    interval: string | null;
    metricName: string;
    metricType: MetricType;
    mode: RollupRuleMode;
    name: string;
    slug: string;
    source: EditSource;
    updatedAt: string;
    filters: Array<{ name: string; value: string }>;
    graphiteLabelPolicy: { replace: Array<{ name: string; newValue: string } | null> | null } | null;
    labelPolicy: { discard: Array<string> | null; keep: Array<string> | null } | null;
    labelReplace: Array<{
      dstLabelName: string | null;
      keepLabel: boolean | null;
      labelValueRegex: string | null;
      newLabelValue: string;
      srcLabelName: string;
    }> | null;
    storagePolicy: { resolution: string; retention: string } | null;
  }>;
};

export type RollupRuleValue = {
  addMetricTypeLabel: boolean;
  aggregationType: AggregationType;
  createdAt: string;
  dropRaw: boolean;
  expansiveMatch: boolean;
  interval: string | null;
  metricName: string;
  metricType: MetricType;
  mode: RollupRuleMode;
  name: string;
  slug: string;
  source: EditSource;
  updatedAt: string;
  filters: Array<{ name: string; value: string }>;
  graphiteLabelPolicy: { replace: Array<{ name: string; newValue: string } | null> | null } | null;
  labelPolicy: { discard: Array<string> | null; keep: Array<string> | null } | null;
  labelReplace: Array<{
    dstLabelName: string | null;
    keepLabel: boolean | null;
    labelValueRegex: string | null;
    newLabelValue: string;
    srcLabelName: string;
  }> | null;
  storagePolicy: { resolution: string; retention: string } | null;
};

export type GetMappingRulesVariables = Exact<{ [key: string]: never }>;

export type GetMappingRules = {
  mappingRules: Array<{
    createdAt: string;
    drop: boolean;
    mode: MappingRuleMode;
    name: string;
    slug: string;
    source: EditSource;
    updatedAt: string;
    aggregationPolicy: {
      aggregation: AggregationType;
      dropTimestamp: boolean;
      interval: string | null;
      storagePolicy: { resolution: string; retention: string } | null;
    } | null;
    filters: Array<{ name: string; value: string }>;
  }>;
};

export type MappingRuleValue = {
  createdAt: string;
  drop: boolean;
  mode: MappingRuleMode;
  name: string;
  slug: string;
  source: EditSource;
  updatedAt: string;
  aggregationPolicy: {
    aggregation: AggregationType;
    dropTimestamp: boolean;
    interval: string | null;
    storagePolicy: { resolution: string; retention: string } | null;
  } | null;
  filters: Array<{ name: string; value: string }>;
};

export type GetPaginatedAlertEventsListVariables = Exact<{
  monitorSlugs: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  latestFirst: InputMaybe<Scalars['Boolean']['input']>;
  after: InputMaybe<Scalars['String']['input']>;
  pageSize: InputMaybe<Scalars['Int']['input']>;
  timeRange: InputMaybe<NewTimeRange>;
  eventTypes: InputMaybe<Array<AlertEventType> | AlertEventType>;
  signals: InputMaybe<
    | Array<InputMaybe<Array<NewAlertRuleLabel> | NewAlertRuleLabel>>
    | InputMaybe<Array<NewAlertRuleLabel> | NewAlertRuleLabel>
  >;
  signalFilters: InputMaybe<Array<NewAlertRouteMatcher> | NewAlertRouteMatcher>;
}>;

export type GetPaginatedAlertEventsList = {
  alertEvents: {
    alertEvents: Array<{
      monitorSlug: string;
      eventType: AlertEventType;
      createdAt: string;
      signal: Array<{ name: string; value: string }>;
      details:
        | {
            __typename: 'MonitorMuted';
            severity: ConditionLevel;
            mutingRulesObj: { mutingRules: Array<{ slug: string }> };
          }
        | { __typename: 'MonitorResolvedAlertEvent'; severity: ConditionLevel }
        | {
            __typename: 'MonitorResolvedNotificationFailedToSend';
            severity: ConditionLevel;
            error: string;
            notifier: { name: string; slug: string };
          }
        | {
            __typename: 'MonitorResolvedNotificationSent';
            severity: ConditionLevel;
            notifier: { name: string; slug: string };
          }
        | { __typename: 'MonitorTriggeredAlertEvent'; severity: ConditionLevel }
        | {
            __typename: 'MonitorTriggeredNotificationFailedToSend';
            severity: ConditionLevel;
            error: string;
            notifier: { name: string; slug: string };
          }
        | {
            __typename: 'MonitorTriggeredNotificationSent';
            severity: ConditionLevel;
            notifier: { name: string; slug: string };
          }
        | {
            __typename: 'MonitorUnmuted';
            severity: ConditionLevel;
            mutingRulesObj: { mutingRules: Array<{ slug: string }> };
          };
    }>;
    pageInfo: { cursor: string | null; hasNextPage: boolean };
  };
};

export type GetCollectionNotificationPolicyVariables = Exact<{
  slug: Scalars['String']['input'];
  collectionType: InputMaybe<CollectionType>;
}>;

export type GetCollectionNotificationPolicy = {
  collection: {
    type: CollectionType;
    slug: string;
    policy: {
      name: string;
      slug: string;
      rules: Array<{
        name: string;
        receivers: Array<{
          id: string;
          slug: string;
          name: string;
          webhook: Array<{ url: string }> | null;
          email: Array<{ to: string }> | null;
          slack: Array<{ channel: string }> | null;
          pagerduty: Array<{ description: string }> | null;
          opsgenie: Array<{ description: string }> | null;
          victorOps: Array<{ routingKey: string }> | null;
        }>;
      }>;
      routes: {
        defaults: Array<{
          severity: string;
          notifierList: Array<{ slug: string; name: string; notifierConfig: { name: string; slug: string } }>;
        }>;
        overrides: Array<{
          matchers: Array<{ name: string; value: string; type: AlertRouteMatcherType }>;
          notifiers: Array<{ severity: string; notifierList: Array<{ slug: string; name: string }> }>;
        }>;
      };
    } | null;
  } | null;
};

export type MonitorSignalQueryVariables = Exact<{
  expression: Scalars['String']['input'];
  signals: InputMaybe<
    | Array<InputMaybe<Array<NewAlertRuleLabel> | NewAlertRuleLabel>>
    | InputMaybe<Array<NewAlertRuleLabel> | NewAlertRuleLabel>
  >;
}>;

export type MonitorSignalQuery = { monitorSignalQuery: string };

export type GetTenantDataSourceSettingsVariables = Exact<{ [key: string]: never }>;

export type GetTenantDataSourceSettings = {
  tenant: {
    id: string;
    graphiteEnabled: boolean;
    loggingDataSourceEnabled: boolean;
    settings: { alertsIntervalsSeconds: Array<number> | null; loggingAlertsIntervalSeconds: Array<number> | null };
  };
};

export type CreateMonitorMutationVariables = Exact<{
  input: NewMonitor;
}>;

export type CreateMonitorMutation = { createMonitor: { slug: string; name: string } };

export type DeleteMonitorMutationVariables = Exact<{
  input: DeleteMonitor;
}>;

export type DeleteMonitorMutation = { deleteMonitor: boolean };

export type LoadMonitorEditorVariables = Exact<{
  slug: Scalars['String']['input'];
}>;

export type LoadMonitorEditor = {
  monitor: {
    slug: string;
    name: string;
    collection: { slug: string; name: string; type: CollectionType };
    collectionLinks: Array<{ collection: { name: string } }> | null;
    managedBy: { editable: boolean; source: ManagedBySource };
    labels: Array<{ name: string; value: string }> | null;
    annotations: Array<{ name: string; value: string }> | null;
    policy: {
      policy: {
        name: string;
        slug: string;
        routes: {
          defaults: Array<{
            severity: string;
            notifierList: Array<{ slug: string; name: string; notifierConfig: { name: string; slug: string } }>;
          }>;
          overrides: Array<{
            matchers: Array<{ name: string; value: string; type: AlertRouteMatcherType }>;
            notifiers: Array<{ severity: string; notifierList: Array<{ slug: string; name: string }> }>;
          }>;
        };
      };
    } | null;
    spec: {
      interval: string;
      query: { queryExpression: string; queryDatasource: DatasourceType };
      signalGrouping: { labelNames: Array<string> | null; signalPerSeries: boolean };
      seriesConditions: {
        defaults: Array<{
          conditionLevel: ConditionLevel;
          conditions: Array<{ sustain: string; op: ThresholdOp; value: number; resolveSustain: string }> | null;
        }> | null;
        overrides: Array<{
          matchers: Array<{ name: string; value: string; type: AlertRouteMatcherType }> | null;
          conditions: Array<{
            conditionLevel: ConditionLevel;
            conditions: Array<{ sustain: string; op: ThresholdOp; value: number; resolveSustain: string }> | null;
          }> | null;
        }> | null;
      };
      schedule: {
        timezone: string;
        weeklySchedule: Array<{ ranges: Array<{ start: string; end: string }> } | null> | null;
      } | null;
    };
  };
};

export type UpdateMonitorMutationVariables = Exact<{
  input: UpdatedMonitor;
}>;

export type UpdateMonitorMutation = { updateMonitor: { slug: string; name: string } };

export type GetFullMonitorVariables = Exact<{
  slug: Scalars['String']['input'];
}>;

export type GetFullMonitor = {
  monitor: {
    name: string;
    slug: string;
    isFavorite: boolean;
    collection: {
      slug: string;
      name: string;
      type: CollectionType;
      nullableTeam: { id: string; name: string; slug: string } | null;
    };
    collectionLinks: Array<{
      slug: string;
      collection: {
        name: string;
        slug: string;
        type: CollectionType;
        nullableTeam: { name: string; slug: string } | null;
      };
    }> | null;
    matchingRoutesAndConditions: {
      signal: Array<{ name: string; value: string }>;
      conditions: {
        defaults: Array<{
          conditionLevel: ConditionLevel;
          conditions: Array<{ sustain: string; op: ThresholdOp; value: number; resolveSustain: string }> | null;
        }> | null;
        overrides: Array<{
          matchers: Array<{ name: string; value: string; type: AlertRouteMatcherType }> | null;
          conditions: Array<{
            conditionLevel: ConditionLevel;
            conditions: Array<{ sustain: string; op: ThresholdOp; value: number; resolveSustain: string }> | null;
          }> | null;
        }> | null;
      };
      routes: {
        defaults: Array<{
          severity: string;
          repeatInterval: string;
          groupBy: Array<string> | null;
          notifierList: Array<{ name: string; slug: string; notifierConfig: { name: string; slug: string } }>;
        }>;
        overrides: Array<{
          matchers: Array<{ name: string; value: string; type: AlertRouteMatcherType }>;
          notifiers: Array<{
            repeatInterval: string;
            severity: string;
            groupBy: Array<string> | null;
            notifierList: Array<{ name: string; slug: string }>;
          }>;
        }>;
      };
    } | null;
    policy: {
      policy: {
        name: string;
        routes: {
          defaults: Array<{
            severity: string;
            notifierList: Array<{ slug: string; name: string; notifierConfig: { name: string; slug: string } }>;
          }>;
          overrides: Array<{
            matchers: Array<{ name: string; value: string; type: AlertRouteMatcherType }>;
            notifiers: Array<{ severity: string; notifierList: Array<{ slug: string; name: string }> }>;
          }>;
        };
      };
    } | null;
    annotations: Array<{ name: string; value: string }> | null;
    labels: Array<{ name: string; value: string }> | null;
    state: { state: AlertState };
    spec: {
      interval: string;
      query: { queryDatasource: DatasourceType; queryExpression: string };
      signalGrouping: { labelNames: Array<string> | null; signalPerSeries: boolean };
      seriesConditions: {
        defaults: Array<{
          conditionLevel: ConditionLevel;
          conditions: Array<{ sustain: string; op: ThresholdOp; value: number; resolveSustain: string }> | null;
        }> | null;
        overrides: Array<{
          matchers: Array<{ name: string; value: string; type: AlertRouteMatcherType }> | null;
          conditions: Array<{
            conditionLevel: ConditionLevel;
            conditions: Array<{ sustain: string; op: ThresholdOp; value: number; resolveSustain: string }> | null;
          }> | null;
        }> | null;
      };
    };
  };
};

export type GetMutingRulesForMonitorVariables = Exact<{
  monitorSlug: Scalars['ID']['input'];
  signal: Array<NewAlertRuleLabel> | NewAlertRuleLabel;
  timeRange: NewTimeRange;
}>;

export type GetMutingRulesForMonitor = {
  mutingRulesForMonitor: {
    activeMutingRules: Array<{
      slug: string;
      id: string;
      startsAt: string;
      endsAt: string;
      comment: string;
      createdBy: string;
      state: MutingRuleState;
      matchers: Array<{ name: string; value: string; type: LabelMatcherType }>;
    }>;
    expiredMutingRules: Array<{
      slug: string;
      id: string;
      startsAt: string;
      endsAt: string;
      comment: string;
      createdBy: string;
      state: MutingRuleState;
      matchers: Array<{ name: string; value: string; type: LabelMatcherType }>;
    }>;
  };
};

export type GetMonitorRoutesAndConditionsVariables = Exact<{
  monitorSlug: Scalars['String']['input'];
  signals: Array<Array<NewAlertRuleLabel> | NewAlertRuleLabel> | Array<NewAlertRuleLabel> | NewAlertRuleLabel;
}>;

export type GetMonitorRoutesAndConditions = {
  matchingMonitorRoutesAndConditions: Array<{
    signal: Array<{ name: string; value: string }>;
    conditions: {
      defaults: Array<{
        conditionLevel: ConditionLevel;
        conditions: Array<{ sustain: string; op: ThresholdOp; value: number; resolveSustain: string }> | null;
      }> | null;
      overrides: Array<{
        matchers: Array<{ type: AlertRouteMatcherType; name: string; value: string }> | null;
        conditions: Array<{
          conditionLevel: ConditionLevel;
          conditions: Array<{ op: ThresholdOp; value: number; sustain: string; resolveSustain: string }> | null;
        }> | null;
      }> | null;
    };
    routes: {
      defaults: Array<{
        severity: string;
        notifierList: Array<{ slug: string; name: string; notifierConfig: { name: string; slug: string } }>;
      }>;
      overrides: Array<{
        matchers: Array<{ type: AlertRouteMatcherType; name: string; value: string }>;
        notifiers: Array<{ severity: string; notifierList: Array<{ name: string; slug: string }> }>;
      }>;
    };
  }>;
};

export type GetMonitorEventFiltersVariables = Exact<{
  pageInfo: MonitorInformation;
}>;

export type GetMonitorEventFilters = {
  monitorsPageEventFilters: { filters: Array<{ queries: Array<string>; description: string }> };
};

export type GetMonitorGroupingsVariables = Exact<{
  slug: Scalars['String']['input'];
}>;

export type GetMonitorGroupings = {
  monitor: {
    slug: string;
    spec: { signalGrouping: { labelNames: Array<string> | null; signalPerSeries: boolean } };
    state: {
      state: AlertState;
      signals: Array<{
        state: AlertState;
        labels: Array<{ name: string; value: string }> | null;
        series: Array<{
          status: {
            status: AlertState;
            startedAt: string | null;
            updatedAt: string | null;
            labels: Array<{ name: string; value: string }> | null;
          };
        }> | null;
      }> | null;
    };
  };
};

export type GetMonitorSummariesVariables = Exact<{ [key: string]: never }>;

export type GetMonitorSummaries = { monitorSummaries: Array<{ slug: string; name: string }> };

export type UpdateMutingRuleMutationVariables = Exact<{
  input: NewMutingRule;
}>;

export type UpdateMutingRuleMutation = { updateMutingRule: { id: string } };

export type CreateMutingRuleMutationVariables = Exact<{
  input: NewMutingRule;
}>;

export type CreateMutingRuleMutation = { createMutingRule: { slug: string } };

export type GetMutingRuleDetailsVariables = Exact<{
  slug: Scalars['String']['input'];
}>;

export type GetMutingRuleDetails = {
  mutingRule: {
    slug: string;
    name: string;
    startsAt: string;
    endsAt: string;
    createdBy: string;
    comment: string;
    state: MutingRuleState;
    matchers: Array<{ type: LabelMatcherType; name: string; value: string }>;
  };
};

export type GetMutingRulesQueryVariables = Exact<{
  filters: MutingRulePageFilters;
  after: InputMaybe<Scalars['String']['input']>;
  pageSize: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetMutingRulesQuery = {
  mutingRulesPage: {
    totalCount: number;
    pageInfo: { hasNextPage: boolean; cursor: string | null };
    mutingRules: Array<{
      id: string;
      slug: string;
      state: MutingRuleState;
      startsAt: string;
      endsAt: string;
      name: string;
      comment: string;
      createdBy: string;
      matchers: Array<{ name: string; value: string; type: LabelMatcherType }>;
    }>;
  };
};

export type GetEventLogByIdVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GetEventLogById = {
  eventLog: {
    id: string;
    userEmail: string;
    userId: string;
    eventCode: string;
    eventDescription: string;
    eventTime: string;
    applicationName: string;
    methodName: string;
    resourceName: string;
    dataJson: string;
  };
};

export type GetEventLogsVariables = Exact<{
  pageToken: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetEventLogs = {
  eventLogs: {
    nextPageToken: number;
    items: Array<{
      id: string;
      userEmail: string;
      eventCode: string;
      eventDescription: string;
      eventTime: string;
    }> | null;
  };
};

export type ListChangeEventCategoriesVariables = Exact<{
  includeDeleted: Scalars['Boolean']['input'];
}>;

export type ListChangeEventCategories = {
  timelineCategories: Array<{ Name: string; Deleted: boolean; FieldValue: string }>;
};

export type ImportDashboardMutationVariables = Exact<{
  input: ImportGrafanaDashboard;
}>;

export type ImportDashboardMutation = { importGrafanaDashboard: { slug: string } };

export type MigrateDashboardVariables = Exact<{
  slug: Scalars['String']['input'];
  nativeConfig: Scalars['JSONObject']['input'];
}>;

export type MigrateDashboard = { migrateGrafanaDashboard: { slug: string } };

export type CreateTestDashboardVariables = Exact<{
  input: SaveDashboardInput;
}>;

export type CreateTestDashboard = { saveDashboard: { slug: string; name: string } };

export type DeleteTestDashboardVariables = Exact<{
  input: DeleteDashboard;
}>;

export type DeleteTestDashboard = { deleteDashboard: boolean };

export type CreateTestGrafanaDashboardVariables = Exact<{
  input: GrafanaDashboardSettings;
}>;

export type CreateTestGrafanaDashboard = { saveGrafanaDashboardSettings: { slug: string; name: string } };

export type CreateTestGrafanaDashboardWithImportVariables = Exact<{
  input: ImportGrafanaDashboard;
}>;

export type CreateTestGrafanaDashboardWithImport = { importGrafanaDashboard: { slug: string; name: string } };

export type DeleteTestGrafanaDashboardVariables = Exact<{
  input: DeleteGrafanaDashboard;
}>;

export type DeleteTestGrafanaDashboard = { deleteGrafanaDashboard: boolean };

export type LoadDashboardSettingsVariables = Exact<{
  slug: Scalars['String']['input'];
}>;

export type LoadDashboardSettings = {
  grafanaDashboard: {
    slug: string;
    source: EditSource;
    name: string;
    collection: {
      slug: string;
      name: string;
      description: string;
      source: EditSource;
      type: CollectionType;
      nullableTeam: { slug: string; name: string } | null;
      policy: { slug: string } | null;
    } | null;
    labels: Array<{ name: string; value: string }> | null;
  } | null;
};

export type DeleteGrafanaDashboardMutationVariables = Exact<{
  slug: Scalars['String']['input'];
}>;

export type DeleteGrafanaDashboardMutation = { deleteGrafanaDashboard: boolean };

export type LoadDuplicateDashboardSettingsVariables = Exact<{
  slug: Scalars['String']['input'];
}>;

export type LoadDuplicateDashboardSettings = {
  grafanaDashboard: {
    slug: string;
    source: EditSource;
    name: string;
    dashboardJSON: string;
    collection: {
      slug: string;
      name: string;
      description: string;
      source: EditSource;
      type: CollectionType;
      nullableTeam: { slug: string; name: string } | null;
      policy: { slug: string } | null;
    } | null;
    labels: Array<{ name: string; value: string }> | null;
  } | null;
};

export type DuplicateDashboardVariables = Exact<{
  input: ImportGrafanaDashboard;
}>;

export type DuplicateDashboard = { importGrafanaDashboard: { slug: string } };

export type GetDashboardForMigrationVariables = Exact<{
  slugs: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
}>;

export type GetDashboardForMigration = {
  allDashboards: {
    dashboards: Array<
      | { slug: string; config: any; source: EditSource; type: 'Dashboard' }
      | { slug: string; config: any; source: EditSource; type: 'GrafanaDashboard' }
    >;
  };
};

export type GetDashboardVariables = Exact<{
  slug: Scalars['String']['input'];
}>;

export type GetDashboard = {
  dashboard: {
    slug: string;
    name: string;
    config: any;
    isFavorite: boolean;
    managedBy: { editable: boolean; source: ManagedBySource };
    labels: Array<{ name: string; value: string }> | null;
    collection: {
      slug: string;
      name: string;
      type: CollectionType;
      nullableTeam: { slug: string; name: string } | null;
    } | null;
    collectionLinks: Array<{
      slug: string;
      collection: {
        name: string;
        slug: string;
        type: CollectionType;
        nullableTeam: { name: string; slug: string } | null;
      };
    }> | null;
  };
};

export type GetDashboardTimestampsVariables = Exact<{
  slug: Scalars['String']['input'];
}>;

export type GetDashboardTimestamps = {
  dashboard: { slug: string; name: string; createdAt: string; updatedAt: string };
};

export type ListRecentDashboardsVariables = Exact<{
  slugs: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
}>;

export type ListRecentDashboards = {
  allDashboards: {
    dashboards: Array<
      | { __typename: 'Dashboard'; slug: string; name: string }
      | { __typename: 'GrafanaDashboard'; slug: string; name: string }
    >;
  };
};

export type GetSloServiceDashboardsVariables = Exact<{
  pageSize: InputMaybe<Scalars['Int']['input']>;
  after: InputMaybe<Scalars['String']['input']>;
  filter: InputMaybe<AllDashboardsFilter>;
}>;

export type GetSloServiceDashboards = {
  allDashboards: {
    totalCount: number;
    pageInfo: { hasNextPage: boolean; cursor: string | null };
    dashboards: Array<
      | {
          __typename: 'Dashboard';
          name: string;
          slug: string;
          collection: { name: string; slug: string; type: CollectionType } | null;
        }
      | {
          __typename: 'GrafanaDashboard';
          name: string;
          slug: string;
          collection: { name: string; slug: string; type: CollectionType } | null;
        }
    >;
  };
};

export type CreateTestDropRuleVariables = Exact<{
  input: NewDropRule;
}>;

export type CreateTestDropRule = { createDropRule: { slug: string } };

export type DeleteTestDropRuleMutationVariables = Exact<{
  input: DeleteDropRule;
}>;

export type DeleteTestDropRuleMutation = { deleteDropRule: boolean };

export type GetDropRulesVariables = Exact<{ [key: string]: never }>;

export type GetDropRules = {
  dropRules: Array<{
    slug: string;
    mode: DropRuleMode;
    name: string;
    source: EditSource;
    createdAt: string;
    updatedAt: string;
    dropNanValue: boolean | null;
    filters: Array<{ name: string; value: string }>;
    conditionalRateBasedDrop: {
      activatedDropDurationSecs: number;
      enabled: boolean;
      rateLimitThreshold: number;
    } | null;
    valueBasedDrop: { enabled: boolean; targetDropValue: number } | null;
  }>;
};

export type DropRuleValue = {
  slug: string;
  mode: DropRuleMode;
  name: string;
  source: EditSource;
  createdAt: string;
  updatedAt: string;
  dropNanValue: boolean | null;
  filters: Array<{ name: string; value: string }>;
  conditionalRateBasedDrop: { activatedDropDurationSecs: number; enabled: boolean; rateLimitThreshold: number } | null;
  valueBasedDrop: { enabled: boolean; targetDropValue: number } | null;
};

export type DeleteDropRuleMutationVariables = Exact<{
  input: DeleteDropRule;
}>;

export type DeleteDropRuleMutation = { deleteDropRule: boolean };

export type CreateDropRuleMutationVariables = Exact<{
  input: NewDropRule;
}>;

export type CreateDropRuleMutation = { createDropRule: { slug: string } };

export type UpdateDropRuleMutationVariables = Exact<{
  input: UpdateDropRule;
}>;

export type UpdateDropRuleMutation = { updateDropRule: { slug: string } };

export type GetLoggedInTestUserIdVariables = Exact<{ [key: string]: never }>;

export type GetLoggedInTestUserId = { me: { id: string } };

export type DeleteTestSignupUserVariables = Exact<{
  input: DeleteUserInput;
}>;

export type DeleteTestSignupUser = { deleteUser: boolean };

export type MetricDDXVariables = Exact<{
  req: MetricDDXRequest;
}>;

export type MetricDDX = { metricDDX: { allLabelNames: Array<string> | null; queryByLabelName: string } };

export type DeleteAlertReceiverMutationVariables = Exact<{
  input: DeleteAlertReceiver;
}>;

export type DeleteAlertReceiverMutation = { deleteAlertReceiver: boolean };

export type NotifiersValue = {
  id: string;
  source: EditSource;
  name: string;
  webhook: Array<{ url: string; baseConfig: { sendResolved: boolean } | null }> | null;
  slack: Array<{
    apiURL: string;
    channel: string;
    username: string;
    baseConfig: { sendResolved: boolean } | null;
  }> | null;
  email: Array<{ to: string; html: string; baseConfig: { sendResolved: boolean } | null }> | null;
  pagerduty: Array<{
    serviceKey: string;
    severity: string;
    url: string;
    baseConfig: { sendResolved: boolean } | null;
  }> | null;
  opsgenie: Array<{ apiURL: string; apiKey: string; baseConfig: { sendResolved: boolean } | null }> | null;
  victorOps: Array<{
    apiURL: string;
    apiKey: string;
    routingKey: string;
    baseConfig: { sendResolved: boolean } | null;
  }> | null;
};

export type GetNotifiersQueryVariables = Exact<{ [key: string]: never }>;

export type GetNotifiersQuery = {
  alertReceivers: {
    receivers: Array<{
      id: string;
      source: EditSource;
      name: string;
      webhook: Array<{ url: string; baseConfig: { sendResolved: boolean } | null }> | null;
      slack: Array<{
        apiURL: string;
        channel: string;
        username: string;
        baseConfig: { sendResolved: boolean } | null;
      }> | null;
      email: Array<{ to: string; html: string; baseConfig: { sendResolved: boolean } | null }> | null;
      pagerduty: Array<{
        serviceKey: string;
        severity: string;
        url: string;
        baseConfig: { sendResolved: boolean } | null;
      }> | null;
      opsgenie: Array<{ apiURL: string; apiKey: string; baseConfig: { sendResolved: boolean } | null }> | null;
      victorOps: Array<{
        apiURL: string;
        apiKey: string;
        routingKey: string;
        baseConfig: { sendResolved: boolean } | null;
      }> | null;
    }>;
  };
};

export type CreateTestNotifierMutationVariables = Exact<{
  input: NewAlertReceiver;
}>;

export type CreateTestNotifierMutation = { createAlertReceiver: { slug: string; name: string } };

export type DeleteTestNotifierMutationVariables = Exact<{
  input: DeleteAlertReceiver;
}>;

export type DeleteTestNotifierMutation = { deleteAlertReceiver: boolean };

export type CreateAlertReceiverMutationVariables = Exact<{
  input: NewAlertReceiver;
}>;

export type CreateAlertReceiverMutation = { createAlertReceiver: { id: string } };

export type UpdateAlertReceiverMutationVariables = Exact<{
  input: UpdateAlertReceiver;
}>;

export type UpdateAlertReceiverMutation = { patchAlertReceiver: { id: string } };

export type GetRecordingRulesQueryVariables = Exact<{
  pageSize: InputMaybe<Scalars['Int']['input']>;
  after: InputMaybe<Scalars['String']['input']>;
  filters: RecordingRulesFilters;
}>;

export type GetRecordingRulesQuery = {
  recordingRules: {
    totalCount: number;
    rules: Array<{
      id: string;
      name: string;
      interval: string;
      expr: string;
      executionGroup: string | null;
      metricName: string | null;
      labels: Array<{ value: string; name: string }>;
    }>;
    pageInfo: { hasNextPage: boolean; cursor: string | null };
  };
};

export type GetRuleEvaluationsQueryVariables = Exact<{ [key: string]: never }>;

export type GetRuleEvaluationsQuery = {
  ruleEvaluations: {
    totalMonitors: number;
    totalRecordingRules: number;
    evaluations: Array<{
      ruleID: string;
      ruleSlug: string;
      ruleFrequency: string;
      ruleName: string;
      ruleType: RuleEvaluationRuleType;
      errorCode: RuleEvaluationErrorCode;
      window: RuleEvaluationWindow;
      value: number;
      message: string;
    }>;
  };
};

export type CreateServiceAccountMutationVariables = Exact<{
  input: CreateServiceAccountInput;
}>;

export type CreateServiceAccountMutation = { createServiceAccount: { token: string; serviceAccount: { id: string } } };

export type GetServiceAccountsVariables = Exact<{
  includeLastUsedAt: Scalars['Boolean']['input'];
}>;

export type GetServiceAccounts = {
  serviceAccounts: Array<{
    source: EditSource;
    id: string;
    name: string;
    email: string;
    createdBy: string;
    lastUsedAt?: string | null;
    permission: PermissionAction;
    labels: Array<{ name: string; value: string }>;
  }>;
};

export type DeleteServiceAccountsMutationVariables = Exact<{
  input: DeleteServiceAccountsInput;
}>;

export type DeleteServiceAccountsMutation = { deleteServiceAccounts: boolean };

export type GetDiscoveryConfigExpressionVariables = Exact<{
  input: ComponentDiscoveryOverrideInput;
}>;

export type GetDiscoveryConfigExpression = { discoveryOverrideEffectiveExpr: string };

export type PreviewServiceDiscoveryVariables = Exact<{
  input: DiscoveryConfigsInput;
}>;

export type PreviewServiceDiscovery = {
  previewServiceDiscovery: {
    compressedDebugLogs: string | null;
    services: Array<{
      name: string;
      slug: string;
      status: DiscoveryStatus;
      components: Array<{ slug: string; status: DiscoveryStatus }>;
    }>;
  } | null;
};

export type ApplyServiceConfigurationVariables = Exact<{
  input: DiscoveryConfigsInput;
}>;

export type ApplyServiceConfiguration = { updateDiscoveryConfigs: boolean };

export type RunServiceDiscoveryVariables = Exact<{
  debugLogFilter: InputMaybe<DebugLogFilter>;
}>;

export type RunServiceDiscovery = {
  runServiceDiscovery: { compressedDebugLogs: string | null; services: Array<{ name: string }> } | null;
};

export type ServiceDiscoveryPreviewVariables = Exact<{
  input: DiscoveryConfigsInput;
  presentationInput: InputMaybe<Array<ComponentPresentationDiffInput> | ComponentPresentationDiffInput>;
}>;

export type ServiceDiscoveryPreview = {
  previewServiceDiscovery: {
    compressedDebugLogs: string | null;
    services: Array<{
      name: string;
      slug: string;
      status: DiscoveryStatus;
      discoveredComponents: Array<{ slug: string; status: DiscoveryStatus }>;
      presentationComponents: Array<{ slug: string; status: DiscoveryStatus }>;
    }>;
  } | null;
};

export type PresentationDiscoveryEligibilityVariables = Exact<{
  input: EligibleServicesInput;
}>;

export type PresentationDiscoveryEligibility = {
  eligibleServices: { services: Array<{ name: string; slug: string }> };
};

export type ServicePresentationCreateVariables = Exact<{
  input: CreatePresentationInput;
}>;

export type ServicePresentationCreate = {
  createPresentation: {
    slug: string;
    name: string;
    description: string | null;
    managedBy: ManagedByType;
    presentationType: ComponentPresentationType;
    dashboard: { panelCount: number } | null;
    override: { componentPresentationSlug: string; status: ComponentPresentationOverrideStatus } | null;
  };
};

export type ServicePresentationUpdateVariables = Exact<{
  input: UpdatePresentationInput;
}>;

export type ServicePresentationUpdate = {
  updatePresentation: {
    name: string;
    slug: string;
    description: string | null;
    presentationType: ComponentPresentationType;
    managedBy: ManagedByType;
    dashboard: { panelCount: number } | null;
    override: { componentPresentationSlug: string; status: ComponentPresentationOverrideStatus } | null;
  };
};

export type ServicePresentationDeleteVariables = Exact<{
  input: DeletePresentationInput;
}>;

export type ServicePresentationDelete = { deletePresentation: boolean };

export type LookupShortUrlVariables = Exact<{
  hash: Scalars['String']['input'];
}>;

export type LookupShortUrl = { shortenedMessage: { hash: string; message: string } };

export type CreateTraceMetricsRuleVariables = Exact<{
  input: CreateTraceMetricsRuleInput;
}>;

export type CreateTraceMetricsRule = { createTraceMetricsRule: { slug: string; metricName: string } };

export type DeleteTraceMetricsRuleVariables = Exact<{
  input: DeleteTraceMetricsRuleInput;
}>;

export type DeleteTraceMetricsRule = { deleteTraceMetricsRule: boolean };

export type TraceBehaviorValue = {
  name: string;
  slug: string;
  description: string | null;
  createdAt: string;
  updatedAt: string;
  type: TraceBehaviorType | null;
  baseHeadSampleRate: number | null;
  baseTailSampleRate: number | null;
  baselineBehaviorOptions: {
    baseHeadSampleRate: number;
    baseTailSampleRate: number;
    fastSampleOptions: {
      sampleRate: number;
      maxDurationSeconds: number;
      enabled: boolean;
      samplingType: TraceBehaviorSamplingType;
    } | null;
    slowSampleOptions: {
      sampleRate: number;
      minDurationSeconds: number;
      enabled: boolean;
      samplingType: TraceBehaviorSamplingType;
    } | null;
    errorSampleOptions: { sampleRate: number; enabled: boolean; samplingType: TraceBehaviorSamplingType } | null;
    largeTraceSampleOptions: {
      sampleRate: number;
      spanCountThreshold: number;
      enabled: boolean;
      samplingType: TraceBehaviorSamplingType;
    } | null;
    smallTraceSampleOptions: {
      sampleRate: number;
      spanCountThreshold: number;
      enabled: boolean;
      samplingType: TraceBehaviorSamplingType;
    } | null;
  } | null;
  fastSampleOptions: {
    sampleRate: number;
    maxDurationSeconds: number;
    enabled: boolean;
    samplingType: TraceBehaviorSamplingType;
  } | null;
  slowSampleOptions: {
    sampleRate: number;
    minDurationSeconds: number;
    enabled: boolean;
    samplingType: TraceBehaviorSamplingType;
  } | null;
  errorSampleOptions: { sampleRate: number; enabled: boolean; samplingType: TraceBehaviorSamplingType } | null;
  largeTraceSampleOptions: {
    sampleRate: number;
    spanCountThreshold: number;
    enabled: boolean;
    samplingType: TraceBehaviorSamplingType;
  } | null;
  smallTraceSampleOptions: {
    sampleRate: number;
    spanCountThreshold: number;
    enabled: boolean;
    samplingType: TraceBehaviorSamplingType;
  } | null;
};

export type GetTraceActiveBehaviorsVariables = Exact<{
  datasetSlugs: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  behaviorSlugs: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  timeRange: InputMaybe<BetweenTimeFilterInput>;
  includeFutureBehaviors: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type GetTraceActiveBehaviors = {
  traceActiveBehaviors: {
    activeBehaviors: Array<{
      dataset: string;
      behavior: string | null;
      startTime: string;
      endTime: string | null;
      assignmentType: TraceBehaviorAssignmentType;
      description: string;
      createdBy: string;
      createdAt: string;
      updatedAt: string;
    }> | null;
    futureActiveBehaviors: Array<{
      dataset: string;
      behavior: string | null;
      startTime: string;
      endTime: string | null;
      assignmentType: TraceBehaviorAssignmentType;
      description: string;
      createdBy: string;
      createdAt: string;
      updatedAt: string;
    }> | null;
  };
};

export type GetTraceBehaviorConfigVariables = Exact<{ [key: string]: never }>;

export type GetTraceBehaviorConfig = {
  traceBehaviorConfig: {
    datasetPriorities: Array<string>;
    version: number;
    baselineBehaviorSlug: string | null;
    mainBehaviorAssignments: Array<{
      dataset: string;
      behavior: string;
      createdBy: string;
      description: string;
      createdAt: string;
      updatedAt: string;
    }>;
    overrideBehaviorAssignments: Array<{
      dataset: string;
      behavior: string;
      startTime: string;
      endTime: string;
      createdBy: string;
      description: string;
      createdAt: string;
      updatedAt: string;
    }> | null;
  } | null;
};

export type GetTraceBehaviorsVariables = Exact<{ [key: string]: never }>;

export type GetTraceBehaviors = {
  traceBehaviors: {
    behaviors: Array<{
      name: string;
      slug: string;
      description: string | null;
      createdAt: string;
      updatedAt: string;
      type: TraceBehaviorType | null;
      baseHeadSampleRate: number | null;
      baseTailSampleRate: number | null;
      baselineBehaviorOptions: {
        baseHeadSampleRate: number;
        baseTailSampleRate: number;
        fastSampleOptions: {
          sampleRate: number;
          maxDurationSeconds: number;
          enabled: boolean;
          samplingType: TraceBehaviorSamplingType;
        } | null;
        slowSampleOptions: {
          sampleRate: number;
          minDurationSeconds: number;
          enabled: boolean;
          samplingType: TraceBehaviorSamplingType;
        } | null;
        errorSampleOptions: { sampleRate: number; enabled: boolean; samplingType: TraceBehaviorSamplingType } | null;
        largeTraceSampleOptions: {
          sampleRate: number;
          spanCountThreshold: number;
          enabled: boolean;
          samplingType: TraceBehaviorSamplingType;
        } | null;
        smallTraceSampleOptions: {
          sampleRate: number;
          spanCountThreshold: number;
          enabled: boolean;
          samplingType: TraceBehaviorSamplingType;
        } | null;
      } | null;
      fastSampleOptions: {
        sampleRate: number;
        maxDurationSeconds: number;
        enabled: boolean;
        samplingType: TraceBehaviorSamplingType;
      } | null;
      slowSampleOptions: {
        sampleRate: number;
        minDurationSeconds: number;
        enabled: boolean;
        samplingType: TraceBehaviorSamplingType;
      } | null;
      errorSampleOptions: { sampleRate: number; enabled: boolean; samplingType: TraceBehaviorSamplingType } | null;
      largeTraceSampleOptions: {
        sampleRate: number;
        spanCountThreshold: number;
        enabled: boolean;
        samplingType: TraceBehaviorSamplingType;
      } | null;
      smallTraceSampleOptions: {
        sampleRate: number;
        spanCountThreshold: number;
        enabled: boolean;
        samplingType: TraceBehaviorSamplingType;
      } | null;
    }> | null;
  };
};

export type UpdateTraceBehaviorConfigVariables = Exact<{
  input: UpdateTraceBehaviorConfigInput;
}>;

export type UpdateTraceBehaviorConfig = {
  updateTraceBehaviorConfig: {
    datasetPriorities: Array<string>;
    version: number;
    baselineBehaviorSlug: string | null;
    mainBehaviorAssignments: Array<{ dataset: string; behavior: string; createdBy: string; description: string }>;
    overrideBehaviorAssignments: Array<{
      dataset: string;
      behavior: string;
      startTime: string;
      endTime: string;
      createdBy: string;
      description: string;
    }> | null;
  };
};

export type DeleteTraceBehaviorVariables = Exact<{
  input: DeleteTraceBehaviorInput;
}>;

export type DeleteTraceBehavior = { deleteTraceBehavior: boolean };

export type CreateTraceBehaviorVariables = Exact<{
  input: CreateTraceBehaviorInput;
}>;

export type CreateTraceBehavior = {
  createTraceBehavior: {
    name: string;
    slug: string;
    description: string | null;
    createdAt: string;
    updatedAt: string;
    type: TraceBehaviorType | null;
    baseHeadSampleRate: number | null;
    baseTailSampleRate: number | null;
    baselineBehaviorOptions: {
      baseHeadSampleRate: number;
      baseTailSampleRate: number;
      fastSampleOptions: {
        sampleRate: number;
        maxDurationSeconds: number;
        enabled: boolean;
        samplingType: TraceBehaviorSamplingType;
      } | null;
      slowSampleOptions: {
        sampleRate: number;
        minDurationSeconds: number;
        enabled: boolean;
        samplingType: TraceBehaviorSamplingType;
      } | null;
      errorSampleOptions: { sampleRate: number; enabled: boolean; samplingType: TraceBehaviorSamplingType } | null;
      largeTraceSampleOptions: {
        sampleRate: number;
        spanCountThreshold: number;
        enabled: boolean;
        samplingType: TraceBehaviorSamplingType;
      } | null;
      smallTraceSampleOptions: {
        sampleRate: number;
        spanCountThreshold: number;
        enabled: boolean;
        samplingType: TraceBehaviorSamplingType;
      } | null;
    } | null;
    fastSampleOptions: {
      sampleRate: number;
      maxDurationSeconds: number;
      enabled: boolean;
      samplingType: TraceBehaviorSamplingType;
    } | null;
    slowSampleOptions: {
      sampleRate: number;
      minDurationSeconds: number;
      enabled: boolean;
      samplingType: TraceBehaviorSamplingType;
    } | null;
    errorSampleOptions: { sampleRate: number; enabled: boolean; samplingType: TraceBehaviorSamplingType } | null;
    largeTraceSampleOptions: {
      sampleRate: number;
      spanCountThreshold: number;
      enabled: boolean;
      samplingType: TraceBehaviorSamplingType;
    } | null;
    smallTraceSampleOptions: {
      sampleRate: number;
      spanCountThreshold: number;
      enabled: boolean;
      samplingType: TraceBehaviorSamplingType;
    } | null;
  };
};

export type UpdateTraceBehaviorVariables = Exact<{
  input: UpdateTraceBehaviorInput;
}>;

export type UpdateTraceBehavior = {
  updateTraceBehavior: {
    name: string;
    slug: string;
    description: string | null;
    createdAt: string;
    updatedAt: string;
    type: TraceBehaviorType | null;
    baseHeadSampleRate: number | null;
    baseTailSampleRate: number | null;
    baselineBehaviorOptions: {
      baseHeadSampleRate: number;
      baseTailSampleRate: number;
      fastSampleOptions: {
        sampleRate: number;
        maxDurationSeconds: number;
        enabled: boolean;
        samplingType: TraceBehaviorSamplingType;
      } | null;
      slowSampleOptions: {
        sampleRate: number;
        minDurationSeconds: number;
        enabled: boolean;
        samplingType: TraceBehaviorSamplingType;
      } | null;
      errorSampleOptions: { sampleRate: number; enabled: boolean; samplingType: TraceBehaviorSamplingType } | null;
      largeTraceSampleOptions: {
        sampleRate: number;
        spanCountThreshold: number;
        enabled: boolean;
        samplingType: TraceBehaviorSamplingType;
      } | null;
      smallTraceSampleOptions: {
        sampleRate: number;
        spanCountThreshold: number;
        enabled: boolean;
        samplingType: TraceBehaviorSamplingType;
      } | null;
    } | null;
    fastSampleOptions: {
      sampleRate: number;
      maxDurationSeconds: number;
      enabled: boolean;
      samplingType: TraceBehaviorSamplingType;
    } | null;
    slowSampleOptions: {
      sampleRate: number;
      minDurationSeconds: number;
      enabled: boolean;
      samplingType: TraceBehaviorSamplingType;
    } | null;
    errorSampleOptions: { sampleRate: number; enabled: boolean; samplingType: TraceBehaviorSamplingType } | null;
    largeTraceSampleOptions: {
      sampleRate: number;
      spanCountThreshold: number;
      enabled: boolean;
      samplingType: TraceBehaviorSamplingType;
    } | null;
    smallTraceSampleOptions: {
      sampleRate: number;
      spanCountThreshold: number;
      enabled: boolean;
      samplingType: TraceBehaviorSamplingType;
    } | null;
  };
};

export type TraceDatasetEnhancementValue = {
  persistedTotalVolumeBytes: number;
  persistedUniqueVolumeBytes: number;
  processedTotalVolumeBytes: number;
  processedUniqueVolumeBytes: number;
};

export type TraceDatasetConfigurationValue = {
  filter: {
    trace: { duration: { minSeconds: number; maxSeconds: number } | null; error: { value: boolean } | null } | null;
    span: Array<{
      matchType: SpanFilterMatchType;
      service: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
      operation: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
      parentService: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
      parentOperation: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
      duration: { minSeconds: number; maxSeconds: number } | null;
      error: { value: boolean } | null;
      inCriticalPath: { value: boolean } | null;
      isRootSpan: { value: boolean } | null;
      leafError: { value: boolean } | null;
      tags: Array<{
        key: string;
        value: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
        numericValue: { comparison: NumericFilterComparisonType; value: number } | null;
      }>;
      spanCount: { min: number; max: number; isMinExclusive: boolean | null; isMaxExclusive: boolean | null } | null;
    }>;
    scope: {
      spanScopes: Array<{
        matchType: SpanFilterMatchType;
        service: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
        operation: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
        parentService: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
        parentOperation: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
        duration: { minSeconds: number; maxSeconds: number } | null;
        error: { value: boolean } | null;
        inCriticalPath: { value: boolean } | null;
        isRootSpan: { value: boolean } | null;
        leafError: { value: boolean } | null;
        tags: Array<{
          key: string;
          value: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
          numericValue: { comparison: NumericFilterComparisonType; value: number } | null;
        }>;
        spanCount: { min: number; max: number; isMinExclusive: boolean | null; isMaxExclusive: boolean | null } | null;
      }> | null;
    } | null;
  };
};

export type DatasetValue = {
  name: string;
  slug: string;
  description: string;
  createdAt: string;
  updatedAt: string;
  type: DatasetType;
  configuration: {
    filter: {
      trace: { duration: { minSeconds: number; maxSeconds: number } | null; error: { value: boolean } | null } | null;
      span: Array<{
        matchType: SpanFilterMatchType;
        service: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
        operation: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
        parentService: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
        parentOperation: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
        duration: { minSeconds: number; maxSeconds: number } | null;
        error: { value: boolean } | null;
        inCriticalPath: { value: boolean } | null;
        isRootSpan: { value: boolean } | null;
        leafError: { value: boolean } | null;
        tags: Array<{
          key: string;
          value: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
          numericValue: { comparison: NumericFilterComparisonType; value: number } | null;
        }>;
        spanCount: { min: number; max: number; isMinExclusive: boolean | null; isMaxExclusive: boolean | null } | null;
      }>;
      scope: {
        spanScopes: Array<{
          matchType: SpanFilterMatchType;
          service: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
          operation: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
          parentService: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
          parentOperation: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
          duration: { minSeconds: number; maxSeconds: number } | null;
          error: { value: boolean } | null;
          inCriticalPath: { value: boolean } | null;
          isRootSpan: { value: boolean } | null;
          leafError: { value: boolean } | null;
          tags: Array<{
            key: string;
            value: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
            numericValue: { comparison: NumericFilterComparisonType; value: number } | null;
          }>;
          spanCount: {
            min: number;
            max: number;
            isMinExclusive: boolean | null;
            isMaxExclusive: boolean | null;
          } | null;
        }> | null;
      } | null;
    };
  };
  enhancements: {
    persistedTotalVolumeBytes: number;
    persistedUniqueVolumeBytes: number;
    processedTotalVolumeBytes: number;
    processedUniqueVolumeBytes: number;
  } | null;
};

export type GetDatasetVariables = Exact<{
  slug: Scalars['String']['input'];
  enhancements: InputMaybe<DatasetEnhancementsInput>;
}>;

export type GetDataset = {
  dataset: {
    name: string;
    slug: string;
    description: string;
    createdAt: string;
    updatedAt: string;
    type: DatasetType;
    configuration: {
      filter: {
        trace: { duration: { minSeconds: number; maxSeconds: number } | null; error: { value: boolean } | null } | null;
        span: Array<{
          matchType: SpanFilterMatchType;
          service: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
          operation: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
          parentService: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
          parentOperation: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
          duration: { minSeconds: number; maxSeconds: number } | null;
          error: { value: boolean } | null;
          inCriticalPath: { value: boolean } | null;
          isRootSpan: { value: boolean } | null;
          leafError: { value: boolean } | null;
          tags: Array<{
            key: string;
            value: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
            numericValue: { comparison: NumericFilterComparisonType; value: number } | null;
          }>;
          spanCount: {
            min: number;
            max: number;
            isMinExclusive: boolean | null;
            isMaxExclusive: boolean | null;
          } | null;
        }>;
        scope: {
          spanScopes: Array<{
            matchType: SpanFilterMatchType;
            service: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
            operation: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
            parentService: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
            parentOperation: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
            duration: { minSeconds: number; maxSeconds: number } | null;
            error: { value: boolean } | null;
            inCriticalPath: { value: boolean } | null;
            isRootSpan: { value: boolean } | null;
            leafError: { value: boolean } | null;
            tags: Array<{
              key: string;
              value: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
              numericValue: { comparison: NumericFilterComparisonType; value: number } | null;
            }>;
            spanCount: {
              min: number;
              max: number;
              isMinExclusive: boolean | null;
              isMaxExclusive: boolean | null;
            } | null;
          }> | null;
        } | null;
      };
    };
    enhancements: {
      persistedTotalVolumeBytes: number;
      persistedUniqueVolumeBytes: number;
      processedTotalVolumeBytes: number;
      processedUniqueVolumeBytes: number;
    } | null;
  } | null;
};

export type GetDatasetsVariables = Exact<{
  filter: InputMaybe<DatasetsListFilterInput>;
  enhancements: InputMaybe<DatasetEnhancementsInput>;
}>;

export type GetDatasets = {
  datasets: {
    datasets: Array<{
      name: string;
      slug: string;
      description: string;
      createdAt: string;
      updatedAt: string;
      type: DatasetType;
      configuration: {
        filter: {
          trace: {
            duration: { minSeconds: number; maxSeconds: number } | null;
            error: { value: boolean } | null;
          } | null;
          span: Array<{
            matchType: SpanFilterMatchType;
            service: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
            operation: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
            parentService: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
            parentOperation: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
            duration: { minSeconds: number; maxSeconds: number } | null;
            error: { value: boolean } | null;
            inCriticalPath: { value: boolean } | null;
            isRootSpan: { value: boolean } | null;
            leafError: { value: boolean } | null;
            tags: Array<{
              key: string;
              value: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
              numericValue: { comparison: NumericFilterComparisonType; value: number } | null;
            }>;
            spanCount: {
              min: number;
              max: number;
              isMinExclusive: boolean | null;
              isMaxExclusive: boolean | null;
            } | null;
          }>;
          scope: {
            spanScopes: Array<{
              matchType: SpanFilterMatchType;
              service: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
              operation: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
              parentService: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
              parentOperation: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
              duration: { minSeconds: number; maxSeconds: number } | null;
              error: { value: boolean } | null;
              inCriticalPath: { value: boolean } | null;
              isRootSpan: { value: boolean } | null;
              leafError: { value: boolean } | null;
              tags: Array<{
                key: string;
                value: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
                numericValue: { comparison: NumericFilterComparisonType; value: number } | null;
              }>;
              spanCount: {
                min: number;
                max: number;
                isMinExclusive: boolean | null;
                isMaxExclusive: boolean | null;
              } | null;
            }> | null;
          } | null;
        };
      };
      enhancements: {
        persistedTotalVolumeBytes: number;
        persistedUniqueVolumeBytes: number;
        processedTotalVolumeBytes: number;
        processedUniqueVolumeBytes: number;
      } | null;
    }>;
  };
};

export type GetUnassignedDatasetVariables = Exact<{
  type: DatasetType;
  enhancements: InputMaybe<DatasetEnhancementsInput>;
}>;

export type GetUnassignedDataset = {
  unassignedDataset: {
    name: string;
    slug: string;
    description: string;
    createdAt: string;
    updatedAt: string;
    type: DatasetType;
    configuration: {
      filter: {
        trace: { duration: { minSeconds: number; maxSeconds: number } | null; error: { value: boolean } | null } | null;
        span: Array<{
          matchType: SpanFilterMatchType;
          service: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
          operation: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
          parentService: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
          parentOperation: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
          duration: { minSeconds: number; maxSeconds: number } | null;
          error: { value: boolean } | null;
          inCriticalPath: { value: boolean } | null;
          isRootSpan: { value: boolean } | null;
          leafError: { value: boolean } | null;
          tags: Array<{
            key: string;
            value: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
            numericValue: { comparison: NumericFilterComparisonType; value: number } | null;
          }>;
          spanCount: {
            min: number;
            max: number;
            isMinExclusive: boolean | null;
            isMaxExclusive: boolean | null;
          } | null;
        }>;
        scope: {
          spanScopes: Array<{
            matchType: SpanFilterMatchType;
            service: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
            operation: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
            parentService: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
            parentOperation: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
            duration: { minSeconds: number; maxSeconds: number } | null;
            error: { value: boolean } | null;
            inCriticalPath: { value: boolean } | null;
            isRootSpan: { value: boolean } | null;
            leafError: { value: boolean } | null;
            tags: Array<{
              key: string;
              value: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
              numericValue: { comparison: NumericFilterComparisonType; value: number } | null;
            }>;
            spanCount: {
              min: number;
              max: number;
              isMinExclusive: boolean | null;
              isMaxExclusive: boolean | null;
            } | null;
          }> | null;
        } | null;
      };
    };
    enhancements: {
      persistedTotalVolumeBytes: number;
      persistedUniqueVolumeBytes: number;
      processedTotalVolumeBytes: number;
      processedUniqueVolumeBytes: number;
    } | null;
  } | null;
};

export type GetTraceLimitsVariables = Exact<{ [key: string]: never }>;

export type GetTraceLimits = { traceLimits: { processedBytes: number; persistedBytes: number } | null };

export type GetTraceHeadSamplingRulesVariables = Exact<{
  serviceNames: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  pageSize: InputMaybe<Scalars['Int']['input']>;
  after: InputMaybe<Scalars['String']['input']>;
  nameOrServiceContains: InputMaybe<Scalars['String']['input']>;
}>;

export type GetTraceHeadSamplingRules = {
  traceJaegerRemoteSamplingStrategies: {
    foundStrategies: Array<{
      slug: string;
      name: string;
      createdAt: string;
      updatedAt: string;
      lastAccessedAt: string | null;
      service: string;
      appliedStrategy:
        | {
            __typename: 'JaegerRemotePerOperationSamplingStrategies';
            defaultSamplingRate: number;
            defaultLowerBoundTracesPerSecond: number;
            defaultUpperBoundTracesPerSecond: number;
            perOperationSamplingStrategies: Array<{
              operation: string;
              probabilisticStrategy: { samplingRate: number };
            }> | null;
          }
        | { __typename: 'JaegerRemoteSamplingProbabilisticStrategy'; samplingRate: number }
        | { __typename: 'JaegerRemoteSamplingRateLimitingSamplingStrategy'; maxTracesPerSecond: number };
    }>;
    pageInfo: { hasNextPage: boolean; cursor: string | null };
  };
};

export type GetTraceTailSamplingRulesVariables = Exact<{ [key: string]: never }>;

export type GetTraceTailSamplingRules = {
  traceTailSamplingRules: {
    createdAt: string;
    updatedAt: string;
    rules: Array<{
      createdAt: string;
      updatedAt: string;
      name: string;
      systemName: string;
      sampleRate: number;
      filter: {
        trace: { duration: { minSeconds: number; maxSeconds: number } | null; error: { value: boolean } | null } | null;
        span: Array<{
          matchType: SpanFilterMatchType;
          service: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
          operation: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
          parentService: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
          parentOperation: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
          duration: { minSeconds: number; maxSeconds: number } | null;
          error: { value: boolean } | null;
          inCriticalPath: { value: boolean } | null;
          isRootSpan: { value: boolean } | null;
          leafError: { value: boolean } | null;
          tags: Array<{
            key: string;
            value: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
            numericValue: { comparison: NumericFilterComparisonType; value: number } | null;
          }>;
          spanCount: {
            min: number;
            max: number;
            isMinExclusive: boolean | null;
            isMaxExclusive: boolean | null;
          } | null;
        }>;
        scope: {
          spanScopes: Array<{
            matchType: SpanFilterMatchType;
            service: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
            operation: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
            parentService: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
            parentOperation: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
            duration: { minSeconds: number; maxSeconds: number } | null;
            error: { value: boolean } | null;
            inCriticalPath: { value: boolean } | null;
            isRootSpan: { value: boolean } | null;
            leafError: { value: boolean } | null;
            tags: Array<{
              key: string;
              value: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
              numericValue: { comparison: NumericFilterComparisonType; value: number } | null;
            }>;
            spanCount: {
              min: number;
              max: number;
              isMinExclusive: boolean | null;
              isMaxExclusive: boolean | null;
            } | null;
          }> | null;
        } | null;
      };
    }>;
    defaultSampleRate: { enabled: boolean; sampleRate: number };
  };
};

export type GetLinkTemplateVariables = Exact<{
  slug: Scalars['String']['input'];
}>;

export type GetLinkTemplate = {
  linkTemplate: { name: string; urlTemplate: string; slug: string; createdAt: string; updatedAt: string } | null;
};

export type GetLinkTemplatesVariables = Exact<{ [key: string]: never }>;

export type GetLinkTemplates = {
  linkTemplates: Array<{ name: string; urlTemplate: string; slug: string; createdAt: string; updatedAt: string }>;
};

export type CreateLinkTemplateVariables = Exact<{
  input: CreateLinkTemplateInput;
}>;

export type CreateLinkTemplate = { createLinkTemplate: { slug: string } };

export type UpdateLinkTemplateVariables = Exact<{
  input: UpdateLinkTemplateInput;
}>;

export type UpdateLinkTemplate = { updateLinkTemplate: { slug: string } };

export type DeleteLinkTemplateVariables = Exact<{
  input: DeleteLinkTemplateInput;
}>;

export type DeleteLinkTemplate = { deleteLinkTemplate: boolean };

export type TracingRecentSearchesVariables = Exact<{ [key: string]: never }>;

export type TracingRecentSearches = {
  traceSearchHistory: {
    traceSearchHistories: Array<{
      id: string;
      createdAt: string;
      search: {
        criteria: {
          scope: {
            spanScopes: Array<{
              matchType: SpanFilterMatchType;
              service: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
              operation: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
              parentService: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
              parentOperation: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
              duration: { minSeconds: number; maxSeconds: number } | null;
              error: { value: boolean } | null;
              inCriticalPath: { value: boolean } | null;
              isRootSpan: { value: boolean } | null;
              leafError: { value: boolean } | null;
              tags: Array<{
                key: string;
                value: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
                numericValue: { comparison: NumericFilterComparisonType; value: number } | null;
              }>;
              spanCount: {
                min: number;
                max: number;
                isMinExclusive: boolean | null;
                isMaxExclusive: boolean | null;
              } | null;
            }> | null;
          } | null;
          trace: {
            duration: { minSeconds: number; maxSeconds: number } | null;
            error: { value: boolean } | null;
          } | null;
          span: Array<{
            matchType: SpanFilterMatchType;
            service: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
            operation: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
            parentService: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
            parentOperation: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
            duration: { minSeconds: number; maxSeconds: number } | null;
            error: { value: boolean } | null;
            inCriticalPath: { value: boolean } | null;
            isRootSpan: { value: boolean } | null;
            leafError: { value: boolean } | null;
            tags: Array<{
              key: string;
              value: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
              numericValue: { comparison: NumericFilterComparisonType; value: number } | null;
            }>;
            spanCount: {
              min: number;
              max: number;
              isMinExclusive: boolean | null;
              isMaxExclusive: boolean | null;
            } | null;
          }>;
        };
        time:
          | { minTimestamp: string; maxTimestamp: string }
          | { timestamp: string }
          | { startRelativeOffsetSeconds: number; endRelativeOffsetSeconds: number | null };
        aggregationOptions: {
          focus: { criticalPathOnly: boolean | null; terminalErrorsOnly: boolean | null } | null;
          shape: {
            groupBy: Array<{ key: string | null; type: TraceAggregationGroupByType; value: string | null }>;
          } | null;
        } | null;
      };
      comparison: {
        criteria: {
          scope: {
            spanScopes: Array<{
              matchType: SpanFilterMatchType;
              service: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
              operation: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
              parentService: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
              parentOperation: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
              duration: { minSeconds: number; maxSeconds: number } | null;
              error: { value: boolean } | null;
              inCriticalPath: { value: boolean } | null;
              isRootSpan: { value: boolean } | null;
              leafError: { value: boolean } | null;
              tags: Array<{
                key: string;
                value: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
                numericValue: { comparison: NumericFilterComparisonType; value: number } | null;
              }>;
              spanCount: {
                min: number;
                max: number;
                isMinExclusive: boolean | null;
                isMaxExclusive: boolean | null;
              } | null;
            }> | null;
          } | null;
          trace: {
            duration: { minSeconds: number; maxSeconds: number } | null;
            error: { value: boolean } | null;
          } | null;
          span: Array<{
            matchType: SpanFilterMatchType;
            service: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
            operation: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
            parentService: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
            parentOperation: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
            duration: { minSeconds: number; maxSeconds: number } | null;
            error: { value: boolean } | null;
            inCriticalPath: { value: boolean } | null;
            isRootSpan: { value: boolean } | null;
            leafError: { value: boolean } | null;
            tags: Array<{
              key: string;
              value: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
              numericValue: { comparison: NumericFilterComparisonType; value: number } | null;
            }>;
            spanCount: {
              min: number;
              max: number;
              isMinExclusive: boolean | null;
              isMaxExclusive: boolean | null;
            } | null;
          }>;
        };
        time:
          | { minTimestamp: string; maxTimestamp: string }
          | { timestamp: string }
          | { startRelativeOffsetSeconds: number; endRelativeOffsetSeconds: number | null };
        aggregationOptions: {
          focus: { criticalPathOnly: boolean | null; terminalErrorsOnly: boolean | null } | null;
          shape: {
            groupBy: Array<{ key: string | null; type: TraceAggregationGroupByType; value: string | null }>;
          } | null;
        } | null;
      } | null;
    }>;
  };
};

export type CreateTracingRecentSearchVariables = Exact<{
  input: CreateTraceSearchHistoryInput;
}>;

export type CreateTracingRecentSearch = { createTraceSearchHistory: { id: string } };

export type CreateSavedTraceSearchVariables = Exact<{
  input: CreateSavedTraceSearchInput;
}>;

export type CreateSavedTraceSearch = { createSavedTraceSearch: { createdAt: string; slug: string } };

export type UpdateSavedTraceSearchVariables = Exact<{
  input: UpdateSavedTraceSearchInput;
}>;

export type UpdateSavedTraceSearch = { updatedSavedTraceSearch: { createdAt: string; slug: string } };

export type DeleteSavedTraceSearchVariables = Exact<{
  input: DeleteSavedTraceSearchInput;
}>;

export type DeleteSavedTraceSearch = { deleteSavedTraceSearch: boolean };

export type SavedTraceSearchConnections = {
  savedSearches: Array<{
    slug: string;
    createdAt: string;
    createdBy: string;
    updatedAt: string;
    name: string;
    search: {
      criteria: {
        scope: {
          spanScopes: Array<{
            matchType: SpanFilterMatchType;
            service: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
            operation: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
            parentService: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
            parentOperation: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
            duration: { minSeconds: number; maxSeconds: number } | null;
            error: { value: boolean } | null;
            inCriticalPath: { value: boolean } | null;
            isRootSpan: { value: boolean } | null;
            leafError: { value: boolean } | null;
            tags: Array<{
              key: string;
              value: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
              numericValue: { comparison: NumericFilterComparisonType; value: number } | null;
            }>;
            spanCount: {
              min: number;
              max: number;
              isMinExclusive: boolean | null;
              isMaxExclusive: boolean | null;
            } | null;
          }> | null;
        } | null;
        trace: { duration: { minSeconds: number; maxSeconds: number } | null; error: { value: boolean } | null } | null;
        span: Array<{
          matchType: SpanFilterMatchType;
          service: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
          operation: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
          parentService: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
          parentOperation: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
          duration: { minSeconds: number; maxSeconds: number } | null;
          error: { value: boolean } | null;
          inCriticalPath: { value: boolean } | null;
          isRootSpan: { value: boolean } | null;
          leafError: { value: boolean } | null;
          tags: Array<{
            key: string;
            value: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
            numericValue: { comparison: NumericFilterComparisonType; value: number } | null;
          }>;
          spanCount: {
            min: number;
            max: number;
            isMinExclusive: boolean | null;
            isMaxExclusive: boolean | null;
          } | null;
        }>;
      };
      time:
        | { minTimestamp: string; maxTimestamp: string }
        | { timestamp: string }
        | { startRelativeOffsetSeconds: number; endRelativeOffsetSeconds: number | null };
      aggregationOptions: {
        focus: { criticalPathOnly: boolean | null; terminalErrorsOnly: boolean | null } | null;
        shape: {
          groupBy: Array<{ key: string | null; type: TraceAggregationGroupByType; value: string | null }>;
        } | null;
      } | null;
    };
    comparison: {
      criteria: {
        scope: {
          spanScopes: Array<{
            matchType: SpanFilterMatchType;
            service: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
            operation: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
            parentService: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
            parentOperation: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
            duration: { minSeconds: number; maxSeconds: number } | null;
            error: { value: boolean } | null;
            inCriticalPath: { value: boolean } | null;
            isRootSpan: { value: boolean } | null;
            leafError: { value: boolean } | null;
            tags: Array<{
              key: string;
              value: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
              numericValue: { comparison: NumericFilterComparisonType; value: number } | null;
            }>;
            spanCount: {
              min: number;
              max: number;
              isMinExclusive: boolean | null;
              isMaxExclusive: boolean | null;
            } | null;
          }> | null;
        } | null;
        trace: { duration: { minSeconds: number; maxSeconds: number } | null; error: { value: boolean } | null } | null;
        span: Array<{
          matchType: SpanFilterMatchType;
          service: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
          operation: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
          parentService: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
          parentOperation: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
          duration: { minSeconds: number; maxSeconds: number } | null;
          error: { value: boolean } | null;
          inCriticalPath: { value: boolean } | null;
          isRootSpan: { value: boolean } | null;
          leafError: { value: boolean } | null;
          tags: Array<{
            key: string;
            value: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
            numericValue: { comparison: NumericFilterComparisonType; value: number } | null;
          }>;
          spanCount: {
            min: number;
            max: number;
            isMinExclusive: boolean | null;
            isMaxExclusive: boolean | null;
          } | null;
        }>;
      };
      time:
        | { minTimestamp: string; maxTimestamp: string }
        | { timestamp: string }
        | { startRelativeOffsetSeconds: number; endRelativeOffsetSeconds: number | null };
      aggregationOptions: {
        focus: { criticalPathOnly: boolean | null; terminalErrorsOnly: boolean | null } | null;
        shape: {
          groupBy: Array<{ key: string | null; type: TraceAggregationGroupByType; value: string | null }>;
        } | null;
      } | null;
    } | null;
  }>;
};

export type SavedTraceSearchesVariables = Exact<{ [key: string]: never }>;

export type SavedTraceSearches = {
  savedTraceSearches: {
    savedSearches: Array<{
      slug: string;
      createdAt: string;
      createdBy: string;
      updatedAt: string;
      name: string;
      search: {
        criteria: {
          scope: {
            spanScopes: Array<{
              matchType: SpanFilterMatchType;
              service: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
              operation: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
              parentService: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
              parentOperation: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
              duration: { minSeconds: number; maxSeconds: number } | null;
              error: { value: boolean } | null;
              inCriticalPath: { value: boolean } | null;
              isRootSpan: { value: boolean } | null;
              leafError: { value: boolean } | null;
              tags: Array<{
                key: string;
                value: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
                numericValue: { comparison: NumericFilterComparisonType; value: number } | null;
              }>;
              spanCount: {
                min: number;
                max: number;
                isMinExclusive: boolean | null;
                isMaxExclusive: boolean | null;
              } | null;
            }> | null;
          } | null;
          trace: {
            duration: { minSeconds: number; maxSeconds: number } | null;
            error: { value: boolean } | null;
          } | null;
          span: Array<{
            matchType: SpanFilterMatchType;
            service: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
            operation: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
            parentService: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
            parentOperation: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
            duration: { minSeconds: number; maxSeconds: number } | null;
            error: { value: boolean } | null;
            inCriticalPath: { value: boolean } | null;
            isRootSpan: { value: boolean } | null;
            leafError: { value: boolean } | null;
            tags: Array<{
              key: string;
              value: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
              numericValue: { comparison: NumericFilterComparisonType; value: number } | null;
            }>;
            spanCount: {
              min: number;
              max: number;
              isMinExclusive: boolean | null;
              isMaxExclusive: boolean | null;
            } | null;
          }>;
        };
        time:
          | { minTimestamp: string; maxTimestamp: string }
          | { timestamp: string }
          | { startRelativeOffsetSeconds: number; endRelativeOffsetSeconds: number | null };
        aggregationOptions: {
          focus: { criticalPathOnly: boolean | null; terminalErrorsOnly: boolean | null } | null;
          shape: {
            groupBy: Array<{ key: string | null; type: TraceAggregationGroupByType; value: string | null }>;
          } | null;
        } | null;
      };
      comparison: {
        criteria: {
          scope: {
            spanScopes: Array<{
              matchType: SpanFilterMatchType;
              service: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
              operation: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
              parentService: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
              parentOperation: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
              duration: { minSeconds: number; maxSeconds: number } | null;
              error: { value: boolean } | null;
              inCriticalPath: { value: boolean } | null;
              isRootSpan: { value: boolean } | null;
              leafError: { value: boolean } | null;
              tags: Array<{
                key: string;
                value: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
                numericValue: { comparison: NumericFilterComparisonType; value: number } | null;
              }>;
              spanCount: {
                min: number;
                max: number;
                isMinExclusive: boolean | null;
                isMaxExclusive: boolean | null;
              } | null;
            }> | null;
          } | null;
          trace: {
            duration: { minSeconds: number; maxSeconds: number } | null;
            error: { value: boolean } | null;
          } | null;
          span: Array<{
            matchType: SpanFilterMatchType;
            service: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
            operation: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
            parentService: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
            parentOperation: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
            duration: { minSeconds: number; maxSeconds: number } | null;
            error: { value: boolean } | null;
            inCriticalPath: { value: boolean } | null;
            isRootSpan: { value: boolean } | null;
            leafError: { value: boolean } | null;
            tags: Array<{
              key: string;
              value: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
              numericValue: { comparison: NumericFilterComparisonType; value: number } | null;
            }>;
            spanCount: {
              min: number;
              max: number;
              isMinExclusive: boolean | null;
              isMaxExclusive: boolean | null;
            } | null;
          }>;
        };
        time:
          | { minTimestamp: string; maxTimestamp: string }
          | { timestamp: string }
          | { startRelativeOffsetSeconds: number; endRelativeOffsetSeconds: number | null };
        aggregationOptions: {
          focus: { criticalPathOnly: boolean | null; terminalErrorsOnly: boolean | null } | null;
          shape: {
            groupBy: Array<{ key: string | null; type: TraceAggregationGroupByType; value: string | null }>;
          } | null;
        } | null;
      } | null;
    }>;
  };
};

export type FilteredSavedTraceSearchesVariables = Exact<{
  input: InputMaybe<Scalars['String']['input']>;
}>;

export type FilteredSavedTraceSearches = {
  savedTraceSearchesByName: {
    savedSearches: Array<{
      slug: string;
      createdAt: string;
      createdBy: string;
      updatedAt: string;
      name: string;
      search: {
        criteria: {
          scope: {
            spanScopes: Array<{
              matchType: SpanFilterMatchType;
              service: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
              operation: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
              parentService: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
              parentOperation: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
              duration: { minSeconds: number; maxSeconds: number } | null;
              error: { value: boolean } | null;
              inCriticalPath: { value: boolean } | null;
              isRootSpan: { value: boolean } | null;
              leafError: { value: boolean } | null;
              tags: Array<{
                key: string;
                value: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
                numericValue: { comparison: NumericFilterComparisonType; value: number } | null;
              }>;
              spanCount: {
                min: number;
                max: number;
                isMinExclusive: boolean | null;
                isMaxExclusive: boolean | null;
              } | null;
            }> | null;
          } | null;
          trace: {
            duration: { minSeconds: number; maxSeconds: number } | null;
            error: { value: boolean } | null;
          } | null;
          span: Array<{
            matchType: SpanFilterMatchType;
            service: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
            operation: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
            parentService: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
            parentOperation: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
            duration: { minSeconds: number; maxSeconds: number } | null;
            error: { value: boolean } | null;
            inCriticalPath: { value: boolean } | null;
            isRootSpan: { value: boolean } | null;
            leafError: { value: boolean } | null;
            tags: Array<{
              key: string;
              value: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
              numericValue: { comparison: NumericFilterComparisonType; value: number } | null;
            }>;
            spanCount: {
              min: number;
              max: number;
              isMinExclusive: boolean | null;
              isMaxExclusive: boolean | null;
            } | null;
          }>;
        };
        time:
          | { minTimestamp: string; maxTimestamp: string }
          | { timestamp: string }
          | { startRelativeOffsetSeconds: number; endRelativeOffsetSeconds: number | null };
        aggregationOptions: {
          focus: { criticalPathOnly: boolean | null; terminalErrorsOnly: boolean | null } | null;
          shape: {
            groupBy: Array<{ key: string | null; type: TraceAggregationGroupByType; value: string | null }>;
          } | null;
        } | null;
      };
      comparison: {
        criteria: {
          scope: {
            spanScopes: Array<{
              matchType: SpanFilterMatchType;
              service: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
              operation: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
              parentService: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
              parentOperation: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
              duration: { minSeconds: number; maxSeconds: number } | null;
              error: { value: boolean } | null;
              inCriticalPath: { value: boolean } | null;
              isRootSpan: { value: boolean } | null;
              leafError: { value: boolean } | null;
              tags: Array<{
                key: string;
                value: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
                numericValue: { comparison: NumericFilterComparisonType; value: number } | null;
              }>;
              spanCount: {
                min: number;
                max: number;
                isMinExclusive: boolean | null;
                isMaxExclusive: boolean | null;
              } | null;
            }> | null;
          } | null;
          trace: {
            duration: { minSeconds: number; maxSeconds: number } | null;
            error: { value: boolean } | null;
          } | null;
          span: Array<{
            matchType: SpanFilterMatchType;
            service: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
            operation: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
            parentService: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
            parentOperation: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
            duration: { minSeconds: number; maxSeconds: number } | null;
            error: { value: boolean } | null;
            inCriticalPath: { value: boolean } | null;
            isRootSpan: { value: boolean } | null;
            leafError: { value: boolean } | null;
            tags: Array<{
              key: string;
              value: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
              numericValue: { comparison: NumericFilterComparisonType; value: number } | null;
            }>;
            spanCount: {
              min: number;
              max: number;
              isMinExclusive: boolean | null;
              isMaxExclusive: boolean | null;
            } | null;
          }>;
        };
        time:
          | { minTimestamp: string; maxTimestamp: string }
          | { timestamp: string }
          | { startRelativeOffsetSeconds: number; endRelativeOffsetSeconds: number | null };
        aggregationOptions: {
          focus: { criticalPathOnly: boolean | null; terminalErrorsOnly: boolean | null } | null;
          shape: {
            groupBy: Array<{ key: string | null; type: TraceAggregationGroupByType; value: string | null }>;
          } | null;
        } | null;
      } | null;
    }>;
  };
  savedTraceSearchesByCreator: {
    savedSearches: Array<{
      slug: string;
      createdAt: string;
      createdBy: string;
      updatedAt: string;
      name: string;
      search: {
        criteria: {
          scope: {
            spanScopes: Array<{
              matchType: SpanFilterMatchType;
              service: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
              operation: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
              parentService: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
              parentOperation: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
              duration: { minSeconds: number; maxSeconds: number } | null;
              error: { value: boolean } | null;
              inCriticalPath: { value: boolean } | null;
              isRootSpan: { value: boolean } | null;
              leafError: { value: boolean } | null;
              tags: Array<{
                key: string;
                value: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
                numericValue: { comparison: NumericFilterComparisonType; value: number } | null;
              }>;
              spanCount: {
                min: number;
                max: number;
                isMinExclusive: boolean | null;
                isMaxExclusive: boolean | null;
              } | null;
            }> | null;
          } | null;
          trace: {
            duration: { minSeconds: number; maxSeconds: number } | null;
            error: { value: boolean } | null;
          } | null;
          span: Array<{
            matchType: SpanFilterMatchType;
            service: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
            operation: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
            parentService: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
            parentOperation: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
            duration: { minSeconds: number; maxSeconds: number } | null;
            error: { value: boolean } | null;
            inCriticalPath: { value: boolean } | null;
            isRootSpan: { value: boolean } | null;
            leafError: { value: boolean } | null;
            tags: Array<{
              key: string;
              value: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
              numericValue: { comparison: NumericFilterComparisonType; value: number } | null;
            }>;
            spanCount: {
              min: number;
              max: number;
              isMinExclusive: boolean | null;
              isMaxExclusive: boolean | null;
            } | null;
          }>;
        };
        time:
          | { minTimestamp: string; maxTimestamp: string }
          | { timestamp: string }
          | { startRelativeOffsetSeconds: number; endRelativeOffsetSeconds: number | null };
        aggregationOptions: {
          focus: { criticalPathOnly: boolean | null; terminalErrorsOnly: boolean | null } | null;
          shape: {
            groupBy: Array<{ key: string | null; type: TraceAggregationGroupByType; value: string | null }>;
          } | null;
        } | null;
      };
      comparison: {
        criteria: {
          scope: {
            spanScopes: Array<{
              matchType: SpanFilterMatchType;
              service: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
              operation: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
              parentService: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
              parentOperation: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
              duration: { minSeconds: number; maxSeconds: number } | null;
              error: { value: boolean } | null;
              inCriticalPath: { value: boolean } | null;
              isRootSpan: { value: boolean } | null;
              leafError: { value: boolean } | null;
              tags: Array<{
                key: string;
                value: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
                numericValue: { comparison: NumericFilterComparisonType; value: number } | null;
              }>;
              spanCount: {
                min: number;
                max: number;
                isMinExclusive: boolean | null;
                isMaxExclusive: boolean | null;
              } | null;
            }> | null;
          } | null;
          trace: {
            duration: { minSeconds: number; maxSeconds: number } | null;
            error: { value: boolean } | null;
          } | null;
          span: Array<{
            matchType: SpanFilterMatchType;
            service: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
            operation: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
            parentService: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
            parentOperation: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
            duration: { minSeconds: number; maxSeconds: number } | null;
            error: { value: boolean } | null;
            inCriticalPath: { value: boolean } | null;
            isRootSpan: { value: boolean } | null;
            leafError: { value: boolean } | null;
            tags: Array<{
              key: string;
              value: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
              numericValue: { comparison: NumericFilterComparisonType; value: number } | null;
            }>;
            spanCount: {
              min: number;
              max: number;
              isMinExclusive: boolean | null;
              isMaxExclusive: boolean | null;
            } | null;
          }>;
        };
        time:
          | { minTimestamp: string; maxTimestamp: string }
          | { timestamp: string }
          | { startRelativeOffsetSeconds: number; endRelativeOffsetSeconds: number | null };
        aggregationOptions: {
          focus: { criticalPathOnly: boolean | null; terminalErrorsOnly: boolean | null } | null;
          shape: {
            groupBy: Array<{ key: string | null; type: TraceAggregationGroupByType; value: string | null }>;
          } | null;
        } | null;
      } | null;
    }>;
  };
};

export type TraceSearchFields = {
  criteria: {
    scope: {
      spanScopes: Array<{
        matchType: SpanFilterMatchType;
        service: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
        operation: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
        parentService: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
        parentOperation: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
        duration: { minSeconds: number; maxSeconds: number } | null;
        error: { value: boolean } | null;
        inCriticalPath: { value: boolean } | null;
        isRootSpan: { value: boolean } | null;
        leafError: { value: boolean } | null;
        tags: Array<{
          key: string;
          value: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
          numericValue: { comparison: NumericFilterComparisonType; value: number } | null;
        }>;
        spanCount: { min: number; max: number; isMinExclusive: boolean | null; isMaxExclusive: boolean | null } | null;
      }> | null;
    } | null;
    trace: { duration: { minSeconds: number; maxSeconds: number } | null; error: { value: boolean } | null } | null;
    span: Array<{
      matchType: SpanFilterMatchType;
      service: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
      operation: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
      parentService: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
      parentOperation: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
      duration: { minSeconds: number; maxSeconds: number } | null;
      error: { value: boolean } | null;
      inCriticalPath: { value: boolean } | null;
      isRootSpan: { value: boolean } | null;
      leafError: { value: boolean } | null;
      tags: Array<{
        key: string;
        value: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
        numericValue: { comparison: NumericFilterComparisonType; value: number } | null;
      }>;
      spanCount: { min: number; max: number; isMinExclusive: boolean | null; isMaxExclusive: boolean | null } | null;
    }>;
  };
  time:
    | { minTimestamp: string; maxTimestamp: string }
    | { timestamp: string }
    | { startRelativeOffsetSeconds: number; endRelativeOffsetSeconds: number | null };
  aggregationOptions: {
    focus: { criticalPathOnly: boolean | null; terminalErrorsOnly: boolean | null } | null;
    shape: { groupBy: Array<{ key: string | null; type: TraceAggregationGroupByType; value: string | null }> } | null;
  } | null;
};

export type GetTraceTopTagsVariables = Exact<{ [key: string]: never }>;

export type GetTraceTopTags = {
  traceTopTags: { version: number; data: Array<{ name: string; description: string }> } | null;
};

export type UpdateTraceTopTagsVariables = Exact<{
  input: UpdateTraceTopTagsInput;
}>;

export type UpdateTraceTopTags = { updateTraceTopTags: { version: number; data: Array<{ name: string }> } };

export type UpdateTraceMetricsRuleVariables = Exact<{
  input: UpdateTraceMetricsRuleInput;
}>;

export type UpdateTraceMetricsRule = { updateTraceMetricsRule: { slug: string } };

export type TraceMetricsRulesVariables = Exact<{
  pageSize: InputMaybe<Scalars['Int']['input']>;
  after: InputMaybe<Scalars['String']['input']>;
}>;

export type TraceMetricsRules = {
  traceMetricsRules: {
    pageInfo: { cursor: string | null; hasNextPage: boolean };
    traceMetricsRules: Array<{
      name: string;
      slug: string;
      metricName: string;
      createdAt: string;
      createdBy: string;
      source: EditSource;
      metricLabels: Array<{ name: string; value: string }>;
      groupBys: Array<{ label: string; key: { namedKey: string | null; type: TraceMetricsGroupByKeyType } }> | null;
      traceFilter: {
        trace: { duration: { minSeconds: number; maxSeconds: number } | null; error: { value: boolean } | null } | null;
        span: Array<{
          matchType: SpanFilterMatchType;
          service: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
          operation: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
          parentService: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
          parentOperation: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
          duration: { minSeconds: number; maxSeconds: number } | null;
          error: { value: boolean } | null;
          tags: Array<{
            key: string;
            value: { match: StringFilterMatchType; value: string; inValues: Array<string> | null } | null;
            numericValue: { comparison: NumericFilterComparisonType; value: number } | null;
          }>;
          spanCount: {
            min: number;
            max: number;
            isMaxExclusive: boolean | null;
            isMinExclusive: boolean | null;
          } | null;
          inCriticalPath: { value: boolean } | null;
          isRootSpan: { value: boolean } | null;
          leafError: { value: boolean } | null;
        }>;
      };
    }>;
  };
};

export type ResendSignupInviteMutationVariables = Exact<{
  input: ResendSignupInviteInput;
}>;

export type ResendSignupInviteMutation = { resendSignupInvite: boolean };

export type CreateUserInviteMutationVariables = Exact<{
  input: CreateSignupInviteInput;
}>;

export type CreateUserInviteMutation = {
  createSignupInvite: { token: string; email: string; expiresAt: string; inviteURL: string; tenant: { name: string } };
};

export type LoadUserMembershipDialogVariables = Exact<{ [key: string]: never }>;

export type LoadUserMembershipDialog = {
  teams: Array<{ id: string; name: string; createdAt: string; source: EditSource; roles: Array<{ name: string }> }>;
};

export type BulkUpdateTeamsForUserMutationVariables = Exact<{
  input: BulkUpdateTeamsForUser;
}>;

export type BulkUpdateTeamsForUserMutation = {
  bulkUpdateTeamsForUser: { addedIDs: Array<string>; removedIDs: Array<string> };
};

export const SchemaTypenames = {
  AlertEvent: 'AlertEvent',
  AlertEventConnection: 'AlertEventConnection',
  AlertReceiver: 'AlertReceiver',
  AlertReceiverEmailConfig: 'AlertReceiverEmailConfig',
  AlertReceiverHTTPConfig: 'AlertReceiverHTTPConfig',
  AlertReceiverHTTPConfigBasicAuth: 'AlertReceiverHTTPConfigBasicAuth',
  AlertReceiverHTTPConfigTLSConfig: 'AlertReceiverHTTPConfigTLSConfig',
  AlertReceiverNotifierBaseConfig: 'AlertReceiverNotifierBaseConfig',
  AlertReceiverOpsGenieConfig: 'AlertReceiverOpsGenieConfig',
  AlertReceiverOpsGenieConfigResponder: 'AlertReceiverOpsGenieConfigResponder',
  AlertReceiverPagerdutyConfig: 'AlertReceiverPagerdutyConfig',
  AlertReceiverPagerdutyConfigImage: 'AlertReceiverPagerdutyConfigImage',
  AlertReceiverPagerdutyConfigLink: 'AlertReceiverPagerdutyConfigLink',
  AlertReceiverSlackConfig: 'AlertReceiverSlackConfig',
  AlertReceiverSlackConfigAction: 'AlertReceiverSlackConfigAction',
  AlertReceiverSlackConfigConfirmField: 'AlertReceiverSlackConfigConfirmField',
  AlertReceiverSlackConfigField: 'AlertReceiverSlackConfigField',
  AlertReceiverVictorOpsConfig: 'AlertReceiverVictorOpsConfig',
  AlertReceiverWebhookConfig: 'AlertReceiverWebhookConfig',
  AlertReceivers: 'AlertReceivers',
  AlertRouteMatcher: 'AlertRouteMatcher',
  AlertRuleLabel: 'AlertRuleLabel',
  AlertStatus: 'AlertStatus',
  AllDashboardsConnection: 'AllDashboardsConnection',
  Annotation: 'Annotation',
  Attributes: 'Attributes',
  BetweenTimeFilter: 'BetweenTimeFilter',
  BoolFilter: 'BoolFilter',
  BoolValue: 'BoolValue',
  CloseToTimeFilter: 'CloseToTimeFilter',
  Collection: 'Collection',
  CollectionDashboardConnection: 'CollectionDashboardConnection',
  CollectionDashboardLink: 'CollectionDashboardLink',
  CollectionHomepage: 'CollectionHomepage',
  CollectionLink: 'CollectionLink',
  CollectionLinksConnection: 'CollectionLinksConnection',
  CollectionMonitorLink: 'CollectionMonitorLink',
  CollectionsConnection: 'CollectionsConnection',
  Component: 'Component',
  ComponentDashboardPresentationConfig: 'ComponentDashboardPresentationConfig',
  ComponentDiscovery: 'ComponentDiscovery',
  ComponentDiscoveryConfig: 'ComponentDiscoveryConfig',
  ComponentDiscoveryOverride: 'ComponentDiscoveryOverride',
  ComponentEventPresentationConfig: 'ComponentEventPresentationConfig',
  ComponentPresentationConfig: 'ComponentPresentationConfig',
  ComponentPresentationOverride: 'ComponentPresentationOverride',
  ConditionOverride: 'ConditionOverride',
  CountFilter: 'CountFilter',
  CreatePersonalAccessTokenResponse: 'CreatePersonalAccessTokenResponse',
  CreateServiceAccountResponse: 'CreateServiceAccountResponse',
  CustomComponentOutput: 'CustomComponentOutput',
  Dashboard: 'Dashboard',
  DashboardLabel: 'DashboardLabel',
  DashboardsConnection: 'DashboardsConnection',
  Dataset: 'Dataset',
  DatasetConnection: 'DatasetConnection',
  DebugLog: 'DebugLog',
  DebugLogEntry: 'DebugLogEntry',
  DefaultResourcePool: 'DefaultResourcePool',
  DefaultSampleRate: 'DefaultSampleRate',
  DerivedLabelFilter: 'DerivedLabelFilter',
  DiscoveredComponent: 'DiscoveredComponent',
  DiscoveredService: 'DiscoveredService',
  Downtime: 'Downtime',
  DropRule: 'DropRule',
  DropRuleConditionalRateBasedDrop: 'DropRuleConditionalRateBasedDrop',
  DropRuleValueBasedDrop: 'DropRuleValueBasedDrop',
  DropValue: 'DropValue',
  DurationFilter: 'DurationFilter',
  EligibleServicesConnection: 'EligibleServicesConnection',
  EntityVersion: 'EntityVersion',
  EntityVersionsConnection: 'EntityVersionsConnection',
  EventDiscovery: 'EventDiscovery',
  EventLog: 'EventLog',
  EventLogListItem: 'EventLogListItem',
  EventLogs: 'EventLogs',
  ExportConfigQuery: 'ExportConfigQuery',
  ExportedConfig: 'ExportedConfig',
  FeatureFlag: 'FeatureFlag',
  GatewayConfigs: 'GatewayConfigs',
  GrafanaDashboard: 'GrafanaDashboard',
  GrafanaDashboardsConnection: 'GrafanaDashboardsConnection',
  GraphiteLabelPolicy: 'GraphiteLabelPolicy',
  GraphiteLabelPolicyReplace: 'GraphiteLabelPolicyReplace',
  IngestionPolicies: 'IngestionPolicies',
  IngestionPolicy: 'IngestionPolicy',
  IntValue: 'IntValue',
  JaegerRemotePerOperationSamplingStrategies: 'JaegerRemotePerOperationSamplingStrategies',
  JaegerRemotePerOperationSamplingStrategy: 'JaegerRemotePerOperationSamplingStrategy',
  JaegerRemoteSamplingProbabilisticStrategy: 'JaegerRemoteSamplingProbabilisticStrategy',
  JaegerRemoteSamplingRateLimitingSamplingStrategy: 'JaegerRemoteSamplingRateLimitingSamplingStrategy',
  Label: 'Label',
  LabelConfig: 'LabelConfig',
  LabelMapping: 'LabelMapping',
  LabelMatcher: 'LabelMatcher',
  LabelNames: 'LabelNames',
  LabelPolicy: 'LabelPolicy',
  LabelValues: 'LabelValues',
  Link: 'Link',
  LinkTemplate: 'LinkTemplate',
  ManagedBy: 'ManagedBy',
  MappingRule: 'MappingRule',
  MappingRuleAggregationPolicy: 'MappingRuleAggregationPolicy',
  MatchingMonitorRoutesAndConditions: 'MatchingMonitorRoutesAndConditions',
  MetricDDXResult: 'MetricDDXResult',
  MetricDiscovery: 'MetricDiscovery',
  MetricDiscoveryOverride: 'MetricDiscoveryOverride',
  MetricDiscoveryTarget: 'MetricDiscoveryTarget',
  MetricSettings: 'MetricSettings',
  Monitor: 'Monitor',
  MonitorCondition: 'MonitorCondition',
  MonitorMuted: 'MonitorMuted',
  MonitorNotificationPolicy: 'MonitorNotificationPolicy',
  MonitorQuery: 'MonitorQuery',
  MonitorResolvedAlertEvent: 'MonitorResolvedAlertEvent',
  MonitorResolvedNotificationFailedToSend: 'MonitorResolvedNotificationFailedToSend',
  MonitorResolvedNotificationSent: 'MonitorResolvedNotificationSent',
  MonitorSeries: 'MonitorSeries',
  MonitorSignal: 'MonitorSignal',
  MonitorSignalConfig: 'MonitorSignalConfig',
  MonitorSpec: 'MonitorSpec',
  MonitorState: 'MonitorState',
  MonitorStats: 'MonitorStats',
  MonitorSummary: 'MonitorSummary',
  MonitorTriggeredAlertEvent: 'MonitorTriggeredAlertEvent',
  MonitorTriggeredNotificationFailedToSend: 'MonitorTriggeredNotificationFailedToSend',
  MonitorTriggeredNotificationSent: 'MonitorTriggeredNotificationSent',
  MonitorUnmuted: 'MonitorUnmuted',
  MonitorsConnection: 'MonitorsConnection',
  Mutation: 'Mutation',
  MutingRule: 'MutingRule',
  MutingRuleConnection: 'MutingRuleConnection',
  MutingRulesForMonitor: 'MutingRulesForMonitor',
  NotificationPolicy: 'NotificationPolicy',
  NotificationPolicyConnection: 'NotificationPolicyConnection',
  NotificationRoutes: 'NotificationRoutes',
  NotificationRoutesOverride: 'NotificationRoutesOverride',
  NotificationRule: 'NotificationRule',
  Notifier: 'Notifier',
  NotifiersBySeverity: 'NotifiersBySeverity',
  NumericFilter: 'NumericFilter',
  ObjectDiscoveryMetricRule: 'ObjectDiscoveryMetricRule',
  ObjectDiscoveryRule: 'ObjectDiscoveryRule',
  ObjectMergeRule: 'ObjectMergeRule',
  OemLoggingConfig: 'OemLoggingConfig',
  PageInfo: 'PageInfo',
  Panel: 'Panel',
  PanelQuery: 'PanelQuery',
  PersonalAccessToken: 'PersonalAccessToken',
  PresentationComponent: 'PresentationComponent',
  PresentationEligibility: 'PresentationEligibility',
  PromQL: 'PromQL',
  Query: 'Query',
  RateLimit: 'RateLimit',
  RecordingRule: 'RecordingRule',
  RecordingRuleLabel: 'RecordingRuleLabel',
  RecordingRules: 'RecordingRules',
  RelativeTimeFilter: 'RelativeTimeFilter',
  ResourcePool: 'ResourcePool',
  ResourcePoolAllocation: 'ResourcePoolAllocation',
  ResourcePoolAllocationFixedValue: 'ResourcePoolAllocationFixedValue',
  ResourcePoolHighLowPriorities: 'ResourcePoolHighLowPriorities',
  ResourcePools: 'ResourcePools',
  Result: 'Result',
  RetentionPolicy: 'RetentionPolicy',
  Role: 'Role',
  RollupRule: 'RollupRule',
  RollupRuleLabelReplace: 'RollupRuleLabelReplace',
  RuleEvaluation: 'RuleEvaluation',
  RuleEvaluations: 'RuleEvaluations',
  SLI: 'SLI',
  SLIEndpointAvailability: 'SLIEndpointAvailability',
  SLIEndpointLatency: 'SLIEndpointLatency',
  SLIMetadata: 'SLIMetadata',
  SLIQueries: 'SLIQueries',
  SLITemplateData: 'SLITemplateData',
  SLO: 'SLO',
  SLOBurnRateDefinition: 'SLOBurnRateDefinition',
  SLOConnection: 'SLOConnection',
  SLODefinition: 'SLODefinition',
  SLOErrorCode: 'SLOErrorCode',
  SLOMetadataEndpointAvailability: 'SLOMetadataEndpointAvailability',
  SLOMetadataLatency: 'SLOMetadataLatency',
  SLORelatedMetricsQueries: 'SLORelatedMetricsQueries',
  SLOReportingWindow: 'SLOReportingWindow',
  SavedTraceSearch: 'SavedTraceSearch',
  SavedTraceSearchConnection: 'SavedTraceSearchConnection',
  Schedule: 'Schedule',
  ScheduleDay: 'ScheduleDay',
  ScopeFilter: 'ScopeFilter',
  SearchConnection: 'SearchConnection',
  SearchHistoryConnection: 'SearchHistoryConnection',
  SearchHistoryQuery: 'SearchHistoryQuery',
  SeriesConditions: 'SeriesConditions',
  Service: 'Service',
  ServiceAccount: 'ServiceAccount',
  ServiceAccountLabel: 'ServiceAccountLabel',
  ServiceComponentViewDetails: 'ServiceComponentViewDetails',
  ServiceDiscoveryOutput: 'ServiceDiscoveryOutput',
  ServiceToTracingServiceAttributeMap: 'ServiceToTracingServiceAttributeMap',
  ServiceView: 'ServiceView',
  ServicesConnection: 'ServicesConnection',
  Settings: 'Settings',
  SeverityCondition: 'SeverityCondition',
  ShortenedMessage: 'ShortenedMessage',
  SignalGrouping: 'SignalGrouping',
  SignupInvite: 'SignupInvite',
  SpanFilter: 'SpanFilter',
  StaticList: 'StaticList',
  StoragePolicy: 'StoragePolicy',
  StringFilter: 'StringFilter',
  StringValue: 'StringValue',
  SuppressionMergeRule: 'SuppressionMergeRule',
  TagFilter: 'TagFilter',
  Team: 'Team',
  TeamBulkUpdate: 'TeamBulkUpdate',
  TeamHomepage: 'TeamHomepage',
  TeamsForUserBulkUpdate: 'TeamsForUserBulkUpdate',
  Template: 'Template',
  TemplateValue: 'TemplateValue',
  Tenant: 'Tenant',
  TenantConfiguration: 'TenantConfiguration',
  TenantSettings: 'TenantSettings',
  TimeRange: 'TimeRange',
  TimelineCategory: 'TimelineCategory',
  TraceActiveBehaviorsConnection: 'TraceActiveBehaviorsConnection',
  TraceAggregationFocus: 'TraceAggregationFocus',
  TraceAggregationGroupByKey: 'TraceAggregationGroupByKey',
  TraceAggregationOptions: 'TraceAggregationOptions',
  TraceAggregationShape: 'TraceAggregationShape',
  TraceBehavior: 'TraceBehavior',
  TraceBehaviorActiveAssignment: 'TraceBehaviorActiveAssignment',
  TraceBehaviorBaselineOptions: 'TraceBehaviorBaselineOptions',
  TraceBehaviorConfig: 'TraceBehaviorConfig',
  TraceBehaviorErrorSampleOptions: 'TraceBehaviorErrorSampleOptions',
  TraceBehaviorFastSampleOptions: 'TraceBehaviorFastSampleOptions',
  TraceBehaviorLargeTraceSampleOptions: 'TraceBehaviorLargeTraceSampleOptions',
  TraceBehaviorMainAssignment: 'TraceBehaviorMainAssignment',
  TraceBehaviorOverrideAssignment: 'TraceBehaviorOverrideAssignment',
  TraceBehaviorSlowSampleOptions: 'TraceBehaviorSlowSampleOptions',
  TraceBehaviorSmallTraceSampleOptions: 'TraceBehaviorSmallTraceSampleOptions',
  TraceBehaviorsConnection: 'TraceBehaviorsConnection',
  TraceDatasetConfiguration: 'TraceDatasetConfiguration',
  TraceDatasetEnhancement: 'TraceDatasetEnhancement',
  TraceDiscovery: 'TraceDiscovery',
  TraceFilter: 'TraceFilter',
  TraceJaegerRemoteSamplingStrategy: 'TraceJaegerRemoteSamplingStrategy',
  TraceJaegerRemoteSamplingStrategyConnection: 'TraceJaegerRemoteSamplingStrategyConnection',
  TraceMetricsGroupBy: 'TraceMetricsGroupBy',
  TraceMetricsGroupByKey: 'TraceMetricsGroupByKey',
  TraceMetricsRule: 'TraceMetricsRule',
  TraceMetricsRulesConnection: 'TraceMetricsRulesConnection',
  TraceSearch: 'TraceSearch',
  TraceSearchFilter: 'TraceSearchFilter',
  TraceSearchHistory: 'TraceSearchHistory',
  TraceSearchHistoryConnection: 'TraceSearchHistoryConnection',
  TraceTailSamplingRule: 'TraceTailSamplingRule',
  TraceTailSamplingRules: 'TraceTailSamplingRules',
  TraceTopTag: 'TraceTopTag',
  TraceTopTags: 'TraceTopTags',
  TracingLicenseLimits: 'TracingLicenseLimits',
  TracingServiceAttributeToServiceMap: 'TracingServiceAttributeToServiceMap',
  TracingServiceMapping: 'TracingServiceMapping',
  Transform: 'Transform',
  UsageStatsConfig: 'UsageStatsConfig',
  User: 'User',
  UserSearchHistory: 'UserSearchHistory',
  UserSettings: 'UserSettings',
  ValidationResult: 'ValidationResult',
  ValueMapping: 'ValueMapping',
  eventFilter: 'eventFilter',
  eventFilters: 'eventFilters',
};

export type TypenamesContext = Required<Pick<OperationContext, 'additionalTypenames'>>;

export const GetDashboardJSONContext: TypenamesContext = {
  additionalTypenames: [],
};

export const LoadDashboardTagsContext: TypenamesContext = {
  additionalTypenames: ['GrafanaDashboard', 'Label'],
};

export const GetMonitorVersionsContext: TypenamesContext = {
  additionalTypenames: ['EntityVersion'],
};

export const GetDashboardVersionsContext: TypenamesContext = {
  additionalTypenames: ['EntityVersion'],
};

export const ExportMonitorConfigContext: TypenamesContext = {
  additionalTypenames: [],
};

export const ExportDropRuleConfigContext: TypenamesContext = {
  additionalTypenames: [],
};

export const ExportRollupRuleConfigContext: TypenamesContext = {
  additionalTypenames: [],
};

export const ExportCollectionConfigContext: TypenamesContext = {
  additionalTypenames: [],
};

export const ExportMappingRuleConfigBySlugContext: TypenamesContext = {
  additionalTypenames: [],
};

export const ExportRollupRuleConfigBySlugContext: TypenamesContext = {
  additionalTypenames: [],
};

export const GetUpdatedPoolConfigurationContext: TypenamesContext = {
  additionalTypenames: [],
};

export const ExportDashboardConfigContext: TypenamesContext = {
  additionalTypenames: [],
};

export const LoadUserSettingsMenuContext: TypenamesContext = {
  additionalTypenames: [],
};

export const LoadNotifiersAutocompleteContext: TypenamesContext = {
  additionalTypenames: [
    'AlertReceiver',
    'AlertReceiverWebhookConfig',
    'AlertReceiverEmailConfig',
    'AlertReceiverSlackConfig',
    'AlertReceiverPagerdutyConfig',
    'AlertReceiverOpsGenieConfig',
    'AlertReceiverVictorOpsConfig',
  ],
};

export const LoadAnalyticsContext: TypenamesContext = {
  additionalTypenames: [],
};

export const DarkModeSettingQueryContext: TypenamesContext = {
  additionalTypenames: [],
};

export const LoadDashboardOptionsContext: TypenamesContext = {
  additionalTypenames: [],
};

export const LoadMonitorOptionsContext: TypenamesContext = {
  additionalTypenames: ['Monitor'],
};

export const LoadCollectionOptionsContext: TypenamesContext = {
  additionalTypenames: ['Collection'],
};

export const GetResourcePoolsContext: TypenamesContext = {
  additionalTypenames: ['Label', 'ResourcePool', 'ResourcePoolAllocationFixedValue'],
};

export const GetSearchHistoryContext: TypenamesContext = {
  additionalTypenames: ['UserSearchHistory'],
};

export const ListTeamsWithCollectionsContext: TypenamesContext = {
  additionalTypenames: ['Team', 'Role', 'Collection'],
};

export const GetUsageStatsConfigContext: TypenamesContext = {
  additionalTypenames: [],
};

export const GetPublicCollectionContext: TypenamesContext = {
  additionalTypenames: [],
};

export const GetAllTestScopesContext: TypenamesContext = {
  additionalTypenames: ['Template'],
};

export const GetAllSettingsQueryContext: TypenamesContext = {
  additionalTypenames: ['RetentionPolicy'],
};

export const CollectionMonitorLinksQueryContext: TypenamesContext = {
  additionalTypenames: ['CollectionLink', 'MonitorSignal', 'MonitorSeries', 'Label'],
};

export const CollectionDashboardLinksQueryContext: TypenamesContext = {
  additionalTypenames: ['CollectionLink', 'Label'],
};

export const ListTeamCollectionsContext: TypenamesContext = {
  additionalTypenames: ['Collection'],
};

export const ListCollectionsContext: TypenamesContext = {
  additionalTypenames: ['Collection'],
};

export const ListCollectionHomepageContext: TypenamesContext = {
  additionalTypenames: ['Link'],
};

export const ListAllDashboardsContext: TypenamesContext = {
  additionalTypenames: ['Label'],
};

export const DashboardBySlugContext: TypenamesContext = {
  additionalTypenames: ['CollectionLink'],
};

export const ListAllDashboardsForSelectContext: TypenamesContext = {
  additionalTypenames: [],
};

export const GetDashboardConfigContext: TypenamesContext = {
  additionalTypenames: [],
};

export const GetMonitorOwnershipContext: TypenamesContext = {
  additionalTypenames: [],
};

export const ListMonitorsQueryContext: TypenamesContext = {
  additionalTypenames: [
    'Monitor',
    'Annotation',
    'Label',
    'ScheduleDay',
    'TimeRange',
    'SeverityCondition',
    'MonitorCondition',
    'ConditionOverride',
    'AlertRouteMatcher',
    'CollectionLink',
    'MonitorSignal',
    'MonitorSeries',
  ],
};

export const BasicListMonitorsQueryContext: TypenamesContext = {
  additionalTypenames: ['Monitor'],
};

export const ListNotificationPoliciesQueryContext: TypenamesContext = {
  additionalTypenames: [
    'NotificationPolicy',
    'NotifiersBySeverity',
    'Notifier',
    'NotificationRoutesOverride',
    'AlertRouteMatcher',
  ],
};

export const GetNotificationPolicyQueryContext: TypenamesContext = {
  additionalTypenames: [
    'NotifiersBySeverity',
    'Notifier',
    'AlertReceiverWebhookConfig',
    'AlertReceiverEmailConfig',
    'AlertReceiverSlackConfig',
    'AlertReceiverPagerdutyConfig',
    'AlertReceiverOpsGenieConfig',
    'AlertReceiverVictorOpsConfig',
    'NotificationRoutesOverride',
    'AlertRouteMatcher',
  ],
};

export const AvailableScopesQueryContext: TypenamesContext = {
  additionalTypenames: ['Template'],
};

export const SearchContext: TypenamesContext = {
  additionalTypenames: ['Result'],
};

export const ObjectMergeRulesContext: TypenamesContext = {
  additionalTypenames: ['ObjectMergeRule'],
};

export const GetComponentDiscoveryConfigsContext: TypenamesContext = {
  additionalTypenames: ['ComponentDiscoveryConfig', 'DerivedLabelFilter'],
};

export const ListComponentPresentationConfigsContext: TypenamesContext = {
  additionalTypenames: ['ComponentPresentationConfig'],
};

export const ComponentPresentationDetailContext: TypenamesContext = {
  additionalTypenames: ['Template'],
};

export const ObjectDiscoveryRulesContext: TypenamesContext = {
  additionalTypenames: ['ObjectDiscoveryRule'],
};

export const GetServiceComponentPresentationContext: TypenamesContext = {
  additionalTypenames: [],
};

export const ListPaginatedServicesContext: TypenamesContext = {
  additionalTypenames: ['Service', 'Component', 'Panel', 'ComponentDiscovery'],
};

export const ListPaginatedServicesAlertContext: TypenamesContext = {
  additionalTypenames: ['Service'],
};

export const ServiceOverviewContext: TypenamesContext = {
  additionalTypenames: ['LabelConfig', 'ValueMapping', 'Component', 'Template', 'Panel', 'PanelQuery'],
};

export const ServiceComponentDetailsViewContext: TypenamesContext = {
  additionalTypenames: [],
};

export const ListPaginatedServiceSlugsContext: TypenamesContext = {
  additionalTypenames: ['Service'],
};

export const GetSloListContext: TypenamesContext = {
  additionalTypenames: ['SLO'],
};

export const GetSloDetailsContext: TypenamesContext = {
  additionalTypenames: [
    'Annotation',
    'LabelConfig',
    'ValueMapping',
    'SLOReportingWindow',
    'Monitor',
    'Component',
    'Panel',
    'PanelQuery',
    'Template',
    'LabelMatcher',
  ],
};

export const ServiceDiscoveryMetaContext: TypenamesContext = {
  additionalTypenames: ['ComponentDiscovery', 'SLOErrorCode'],
};

export const ListTeamsContext: TypenamesContext = {
  additionalTypenames: ['Team', 'Role'],
};

export const ListTeamHomepageContext: TypenamesContext = {
  additionalTypenames: ['Role', 'User', 'Link', 'NotificationPolicy'],
};

export const UserSettingsQueryContext: TypenamesContext = {
  additionalTypenames: [],
};

export const LoadUsersContext: TypenamesContext = {
  additionalTypenames: ['User', 'Role', 'ServiceAccount'],
};

export const LoadUsersSidebarContext: TypenamesContext = {
  additionalTypenames: ['User', 'ServiceAccount'],
};

export const LoadUserDetailsContext: TypenamesContext = {
  additionalTypenames: ['Role', 'Team', 'EventLogListItem'],
};

export const LoadMyAccountContext: TypenamesContext = {
  additionalTypenames: [],
};

export const PersonalAccessTokensListContext: TypenamesContext = {
  additionalTypenames: ['PersonalAccessToken'],
};

export const GetRollupRulesContext: TypenamesContext = {
  additionalTypenames: ['RollupRule', 'Label', 'GraphiteLabelPolicyReplace', 'RollupRuleLabelReplace'],
};

export const GetMappingRulesContext: TypenamesContext = {
  additionalTypenames: ['MappingRule', 'Label'],
};

export const GetPaginatedAlertEventsListContext: TypenamesContext = {
  additionalTypenames: ['AlertEvent', 'Label', 'MutingRule'],
};

export const GetCollectionNotificationPolicyContext: TypenamesContext = {
  additionalTypenames: [
    'NotificationRule',
    'AlertReceiver',
    'AlertReceiverWebhookConfig',
    'AlertReceiverEmailConfig',
    'AlertReceiverSlackConfig',
    'AlertReceiverPagerdutyConfig',
    'AlertReceiverOpsGenieConfig',
    'AlertReceiverVictorOpsConfig',
    'NotifiersBySeverity',
    'Notifier',
    'NotificationRoutesOverride',
    'AlertRouteMatcher',
  ],
};

export const MonitorSignalQueryContext: TypenamesContext = {
  additionalTypenames: [],
};

export const GetTenantDataSourceSettingsContext: TypenamesContext = {
  additionalTypenames: [],
};

export const LoadMonitorEditorContext: TypenamesContext = {
  additionalTypenames: [
    'CollectionLink',
    'Label',
    'Annotation',
    'NotifiersBySeverity',
    'Notifier',
    'NotificationRoutesOverride',
    'AlertRouteMatcher',
    'SeverityCondition',
    'MonitorCondition',
    'ConditionOverride',
    'ScheduleDay',
    'TimeRange',
  ],
};

export const GetFullMonitorContext: TypenamesContext = {
  additionalTypenames: [
    'CollectionLink',
    'AlertRuleLabel',
    'SeverityCondition',
    'MonitorCondition',
    'ConditionOverride',
    'AlertRouteMatcher',
    'NotifiersBySeverity',
    'Notifier',
    'NotificationRoutesOverride',
    'Annotation',
    'Label',
  ],
};

export const GetMutingRulesForMonitorContext: TypenamesContext = {
  additionalTypenames: ['MutingRule', 'LabelMatcher'],
};

export const GetMonitorRoutesAndConditionsContext: TypenamesContext = {
  additionalTypenames: [
    'MatchingMonitorRoutesAndConditions',
    'AlertRuleLabel',
    'SeverityCondition',
    'MonitorCondition',
    'ConditionOverride',
    'AlertRouteMatcher',
    'NotifiersBySeverity',
    'Notifier',
    'NotificationRoutesOverride',
  ],
};

export const GetMonitorEventFiltersContext: TypenamesContext = {
  additionalTypenames: ['eventFilter'],
};

export const GetMonitorGroupingsContext: TypenamesContext = {
  additionalTypenames: ['MonitorSignal', 'Label', 'MonitorSeries'],
};

export const GetMonitorSummariesContext: TypenamesContext = {
  additionalTypenames: ['MonitorSummary'],
};

export const GetMutingRuleDetailsContext: TypenamesContext = {
  additionalTypenames: ['LabelMatcher'],
};

export const GetMutingRulesQueryContext: TypenamesContext = {
  additionalTypenames: ['MutingRule', 'LabelMatcher'],
};

export const GetEventLogByIdContext: TypenamesContext = {
  additionalTypenames: [],
};

export const GetEventLogsContext: TypenamesContext = {
  additionalTypenames: ['EventLogListItem'],
};

export const ListChangeEventCategoriesContext: TypenamesContext = {
  additionalTypenames: ['TimelineCategory'],
};

export const LoadDashboardSettingsContext: TypenamesContext = {
  additionalTypenames: ['Label'],
};

export const LoadDuplicateDashboardSettingsContext: TypenamesContext = {
  additionalTypenames: ['Label'],
};

export const GetDashboardForMigrationContext: TypenamesContext = {
  additionalTypenames: [],
};

export const GetDashboardContext: TypenamesContext = {
  additionalTypenames: ['Label', 'CollectionLink'],
};

export const GetDashboardTimestampsContext: TypenamesContext = {
  additionalTypenames: [],
};

export const ListRecentDashboardsContext: TypenamesContext = {
  additionalTypenames: [],
};

export const GetSloServiceDashboardsContext: TypenamesContext = {
  additionalTypenames: [],
};

export const GetDropRulesContext: TypenamesContext = {
  additionalTypenames: ['DropRule', 'Label'],
};

export const GetLoggedInTestUserIdContext: TypenamesContext = {
  additionalTypenames: [],
};

export const MetricDDXContext: TypenamesContext = {
  additionalTypenames: [],
};

export const GetNotifiersQueryContext: TypenamesContext = {
  additionalTypenames: [
    'AlertReceiver',
    'AlertReceiverWebhookConfig',
    'AlertReceiverSlackConfig',
    'AlertReceiverEmailConfig',
    'AlertReceiverPagerdutyConfig',
    'AlertReceiverOpsGenieConfig',
    'AlertReceiverVictorOpsConfig',
  ],
};

export const GetRecordingRulesQueryContext: TypenamesContext = {
  additionalTypenames: ['RecordingRule', 'AlertRuleLabel'],
};

export const GetRuleEvaluationsQueryContext: TypenamesContext = {
  additionalTypenames: ['RuleEvaluation'],
};

export const GetServiceAccountsContext: TypenamesContext = {
  additionalTypenames: ['ServiceAccount', 'ServiceAccountLabel'],
};

export const GetDiscoveryConfigExpressionContext: TypenamesContext = {
  additionalTypenames: [],
};

export const PreviewServiceDiscoveryContext: TypenamesContext = {
  additionalTypenames: ['DiscoveredService', 'DiscoveredComponent'],
};

export const ServiceDiscoveryPreviewContext: TypenamesContext = {
  additionalTypenames: ['DiscoveredService', 'DiscoveredComponent', 'PresentationComponent'],
};

export const PresentationDiscoveryEligibilityContext: TypenamesContext = {
  additionalTypenames: ['Service'],
};

export const LookupShortUrlContext: TypenamesContext = {
  additionalTypenames: [],
};

export const GetTraceActiveBehaviorsContext: TypenamesContext = {
  additionalTypenames: ['TraceBehaviorActiveAssignment'],
};

export const GetTraceBehaviorConfigContext: TypenamesContext = {
  additionalTypenames: ['TraceBehaviorMainAssignment', 'TraceBehaviorOverrideAssignment'],
};

export const GetTraceBehaviorsContext: TypenamesContext = {
  additionalTypenames: ['TraceBehavior'],
};

export const GetDatasetContext: TypenamesContext = {
  additionalTypenames: ['SpanFilter', 'TagFilter'],
};

export const GetDatasetsContext: TypenamesContext = {
  additionalTypenames: ['Dataset', 'SpanFilter', 'TagFilter'],
};

export const GetUnassignedDatasetContext: TypenamesContext = {
  additionalTypenames: ['SpanFilter', 'TagFilter'],
};

export const GetTraceLimitsContext: TypenamesContext = {
  additionalTypenames: [],
};

export const GetTraceHeadSamplingRulesContext: TypenamesContext = {
  additionalTypenames: ['TraceJaegerRemoteSamplingStrategy', 'JaegerRemotePerOperationSamplingStrategy'],
};

export const GetTraceTailSamplingRulesContext: TypenamesContext = {
  additionalTypenames: ['TraceTailSamplingRule', 'SpanFilter', 'TagFilter'],
};

export const GetLinkTemplateContext: TypenamesContext = {
  additionalTypenames: [],
};

export const GetLinkTemplatesContext: TypenamesContext = {
  additionalTypenames: ['LinkTemplate'],
};

export const TracingRecentSearchesContext: TypenamesContext = {
  additionalTypenames: ['TraceSearchHistory', 'SpanFilter', 'TagFilter', 'TraceAggregationGroupByKey'],
};

export const SavedTraceSearchesContext: TypenamesContext = {
  additionalTypenames: ['SavedTraceSearch', 'SpanFilter', 'TagFilter', 'TraceAggregationGroupByKey'],
};

export const FilteredSavedTraceSearchesContext: TypenamesContext = {
  additionalTypenames: ['SavedTraceSearch', 'SpanFilter', 'TagFilter', 'TraceAggregationGroupByKey'],
};

export const GetTraceTopTagsContext: TypenamesContext = {
  additionalTypenames: ['TraceTopTag'],
};

export const TraceMetricsRulesContext: TypenamesContext = {
  additionalTypenames: ['TraceMetricsRule', 'Label', 'TraceMetricsGroupBy', 'SpanFilter', 'TagFilter'],
};

export const LoadUserMembershipDialogContext: TypenamesContext = {
  additionalTypenames: ['Team', 'Role'],
};
