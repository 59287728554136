import { formatWithTimeZone } from '@chronosphereio/core';
import constate from 'constate';
import { useState, useCallback, useEffect } from 'react';

function useUserTimeZoneStore({ defaultTimeZone }: { defaultTimeZone?: string }) {
  const [timeZone, setTimeZoneState] = useState<string>();

  /**
   * Initialize timeZone to the one stored in Grafana preferences
   *
   * We use Grafana as the source of truth for user timeZone preference
   * until we build Chronosphere storage and API for timeZone selection
   */
  useEffect(() => {
    if (defaultTimeZone) {
      // provide defaultTimeZone when testing the app, in which case we will not load timeZone from grafana
      setTimeZoneState(defaultTimeZone);
    }
  }, [defaultTimeZone]);

  const setTimeZone = useCallback(
    async (newTimeZone: string) => {
      const prevTimeZone = timeZone;
      try {
        setTimeZoneState(newTimeZone);
      } catch (e) {
        setTimeZoneState(prevTimeZone);
        throw e;
      }
    },
    [timeZone]
  );

  return {
    timeZone,
    setTimeZone,
    formatInUserTimeZone: (date: Date, formatString: string) => {
      return formatWithTimeZone(date, formatString, timeZone);
    },
  };
}

export const [UserTimeZoneProvider, useUserTimeZone] = constate(useUserTimeZoneStore);
